@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/klm/klm-buttons";

.payment-header {
  font-weight: normal;
  line-height: 48px;
  margin-top: 36px;

  @include responsive(XXS) {
    font-size: 24px;
  }
  @include responsive(SM) {
    margin-bottom: 16px;
    font-size: 48px;
  }
}

.payment-form-component {
  background-color: white;
  margin: 24px 0;
  position: relative;

  & .disable-payment-form {
    opacity: 0.5;
    background-color: white;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
  }

  & .payment-attempt-component {
    display: flex;
    background-color: white;
    padding: 24px 24px 0 24px;

    & .payment-attempt-container {
      padding: 16px;
      display: flex;
      z-index: 2;
      line-height: 28px;

      & .warning-icon {
        padding: 2px 18px 10px 2px;
      }

      & .information-icon {
        padding: 0 18px 0 0;
      }

      & .payment-attempt-description {
        & p {
          margin: 0;
        }

        & .payment-attempt-button {
          top: 0;
          left: 0;
        }
      }
    }
  }

  & .payment-form-container {
    display: flex;

    @include responsive(XXS) {
      flex-direction: column;
      padding: 16px 16px 0 16px;
    }

    @include responsive(XL) {
      flex-direction: row;
      padding: 16px;
    }

    & .payment-form-options {
      display: flex;
      flex-direction: column;

      @include responsive(XXS) {
        width: 100%;
        padding: 0;
      }

      @media (min-width: 620px) {
        width: 534px;
      }

      @include responsive(MD) {
        width: 534px;
        padding: 0 8px 0 8px;
      }

      & .select-bank {
        line-height: 24px;
        margin-top: 12px;
        margin-left: 32px;
      }

      & .ideal-payment {
        & .free {
          line-height: 24px;
        }
      }

      & .credit-card-payment {
        margin: 24px 0 12px 0;

        & .credit-card-description {
          line-height: 24px;
          font-weight: 400 !important;
        }
      }

      & label {
        display: flex;
        justify-content: space-between;

        & .ideal-info {
          flex-direction: row;
          display: flex;

          & .ideal-description {
            display: flex;
            line-height: 26px;
            font-weight: 400 !important;
            margin-right: 8px;
          }
          & .ideal-logo {
            object-fit: fill;
          }
          & .logo-container-icon {
            display: flex;
          }
        }

        & .logo-container-icon {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          height: 26px;
          width: 40px;
          margin: 0 4px 0 4px;
          border: none;

        }
      }
    }

    & .checkbox-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin: 3px 8px 3px 3px;

      & > .checkbox {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }

    & .inactive {
      & > .checkbox {
        background-color: transparent;
      }
    }

    & .adyen-info-container {
      flex-shrink: 0;
      flex-grow: 1;
      align-self: flex-end;

      @include responsive(XXS) {
        padding: 24px 0;
      }

      @include responsive(XL) {
        padding-bottom: 4px;
      }

      & .adyen-info-wrapper {
        display: flex;
        justify-content: right;
        position: relative;
        align-self: flex-end;

        & img {
          margin: auto 0;
        }

        & .lock-icon {
          height: 14px;
        }

        & .adyen-info-description {
          margin: auto 8px;
          line-height: 12px;
          font-size: 10px;
        }

        & .adyen-icon {
          height: 20px;
          width: 62px;
          display: inline-flex;
        }
      }
    }

    & .adyen-checkout__label__text {
      margin-left: 32px;
      font-size: 16px;
      line-height: 24px;
      padding: 0;
    }

    & .adyen-checkout__field {
      margin-bottom: 8px;
    }

    & .adyen-checkout__input-wrapper {
      margin-left: 32px;
    }

    & .adyen-checkout__dropdown__button {
      width: auto;
      line-height: 24px;
    }

    & .adyen-checkout-form-instruction {
      display: none;
    }
  }
  & .d-none {
    display: none;
  }

  & .d-block {
    display: block;
  }

  & .d-flex {
    display: flex;
  }
}

.loader-payment-form-ideal {
  & svg {
    transform: none;
  }
}
