@import "../../assets/scss/common/breakpoints.scss";

.holiday-package-flight-component {
  letter-spacing: 0.46px;
  color: #003145;
  background-color: #ffffff;

  & * {
    box-sizing: border-box;
  }

  & > .content {
    margin: 0 auto;
    padding: 40px 0;
    color: #003145;

    & > .content-header {
      margin-bottom: 30px;

      @include responsive(XXS) {
        font-size: 24px;
        line-height: 32px;
      }

      @include responsive(SM) {
        font-size: 48px;
        line-height: 48px;
      }
    }

    @include responsive(XXS) {
      width: calc(100% - 32px);
    }

    @include responsive(MD) {
      width: 768px;
    }

    @include responsive(LG) {
      width: 944px;
    }

    @include responsive(XL) {
      width: 1152px;
    }

    @include responsive(XXL) {
      width: 1224px;
    }

    @include responsive(XXXL) {
      width: 1224px;
    }
  }

  @include responsive(XXS) {
    .section-flight-in {
      .holiday-flight {
        padding-bottom: 16px;
      }
    }

    & .loading-wrapper {
      margin-top: 60px;
    }
  }

  @include responsive(SM) {
    .section-flight-in {
      margin-bottom: 35px;
    }

    & .loading-wrapper {
      margin-top: 100px;
    }
  }
}

@include responsive(XXS) {
  .holiday-package-flight-component {
    width: 100%;

    & .flight-options-header {
      display: flex;
      flex-direction: row;
      height: 40px;
      margin-bottom: 16px;
      font-size: 18px;
      display: none;

      & .info {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;

        & .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          background-color: #f5fafc;

          & .icon {
            width: 25px;
            height: 20px;
            background-color: #00a1de;
          }
        }

        &:nth-child(1) .icon {
          -webkit-mask: url("../../assets/icons/klm/origin-S.svg") no-repeat
            left;
          mask-size: contain;
        }

        &:nth-child(2) .icon {
          -webkit-mask: url("../../assets/icons/klm/destination-S-flipped.svg")
            no-repeat left;
          mask-size: contain;
        }

        & .text {
          margin-left: 14px;
        }
      }
    }

    & .flight-options-content {
      & .flight-option {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        margin-bottom: 16px;
        padding: 16px;
        font-size: 14px;
        line-height: 20px;
        background-color: #ffffff;
        border: 2px solid #cde4ee;
        border-radius: 2px;
        box-sizing: border-box;
        box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.08),
          0 3px 6px 0 rgba(124, 127, 125, 0.08);

        &.selected {
          background-color: #d9eaf2;
          border: 2px solid #003f72;
        }

        & .flight-option-status {
          position: absolute;
          top: 0;
          left: 0;
          width: 150px;
          height: 40px;
          line-height: 40px;
          transform: translate(16px, 20%);
          display: flex;
          flex-direction: row;
          font-size: 18px;
          justify-content: flex-start;

          & span {
            font-size: 24px;
            margin-right: 6px;
          }

          & span:nth-child(4) {
            margin-right: 0;
            vertical-align: bottom;
            font-size: 12px;
          }
        }

        & .flight-option-selector {
          position: absolute;
          top: 0;
          right: 0;
          height: 40px;
          transform: translate(-16px, 20%);
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 18px;

          & .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            background-color: #58a618;
            border-radius: 50%;
            margin-right: 6px;
            margin-bottom: 3px;

            & .icon {
              width: 12px;
              height: 12px;
              background-color: #ffffff;
              -webkit-mask: url("../../assets/icons/klm/ic_check.svg") no-repeat
                center;
              mask-size: contain;
            }
          }

          & .button-select-flight-option {
            height: 40px;
            padding: 0 16px;
            font-size: 16px;
          }
        }

        & .flight-option-details {
          height: auto;

          & .section-leg {
            display: flex;
            flex-direction: row;
            height: 50%;
            padding: 20px 0;
            box-sizing: border-box;

            &.outbound-leg {
              padding-top: 46px;
            }

            & .vertical {
              width: 100%;
              .flight-details-section {
                display: none;
              }

              & .row:nth-child(1) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                & .column {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 115px;
                }

                & .column:nth-child(1) {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  width: 10px;

                  & .flight-type-graphics {
                    & .direct-flight {
                      background-image: url("../../assets/icons/klm/klm-flight-connector-direct-vertical.svg");
                      background-repeat: no-repeat;
                      width: 10px;
                      height: 110px;
                    }

                    & .indirect-flight {
                      background-image: url("../../assets/icons/klm/klm-flight-connector-indirect-vertical.svg");
                      background-repeat: no-repeat;
                      width: 10px;
                      height: 110px;
                    }
                  }
                }

                & .column:nth-child(2) {
                  margin-left: 8px;
                }

                & .column:nth-child(3) {
                  margin-left: 10px;
                  flex: 1 0 auto;

                  & .flight-stops-number {
                    margin-right: 3px;
                  }
                }

                & .column:nth-child(4) {
                  margin-left: 8px;
                  min-width: 30px;

                  & .airline-logo {
                    background-repeat: no-repeat;
                    background-position: top right;
                    width: 24px;
                  }
                }
              }
            }

            & .horizontal {
              display: none;
              .flight-details-section {
                display: none;
              }
            }

            & .luggage-baggage-information {
              display: flex;
              flex-direction: row;
              margin-top: 20px;
              font-size: 16px;

              & * {
                display: flex;
                flex-direction: row;
                align-items: center;
              }

              & .hand-luggage {
                & .hand-luggage-included {
                  width: 9px;
                  height: 20px;
                  margin-right: 6px;
                  background-color: #00a1de;
                  -webkit-mask: url("../../assets/icons/klm/icon-handluggage.svg")
                    no-repeat left;
                  mask-size: contain;
                }

                & .hand-luggage-excluded {
                  width: 9px;
                  height: 20px;
                  margin-right: 6px;
                  background-color: #989b99;
                  -webkit-mask: url("../../assets/icons/klm/icon-handluggage.svg")
                    no-repeat left;
                  mask-size: contain;
                }
              }

              & .baggage {
                & .baggage-included {
                  width: 14px;
                  height: 14px;
                  margin: 0 6px 3px 12px;
                  background-color: #00a1de;
                  -webkit-mask: url("../../assets/icons/klm/icon-briefcase-included.svg")
                    no-repeat left;
                  mask-size: contain;
                }

                & .baggage-excluded {
                  width: 14px;
                  height: 14px;
                  margin: 0 6px 3px 12px;
                  background-color: #989b99;
                  -webkit-mask: url("../../assets/icons/klm/icon-briefcase-excluded.svg")
                    no-repeat left;
                  mask-size: contain;
                }
              }

              & .text-extended {
                margin-left: 3px;
                display: none;
              }
            }
          }

          & .section-borderline {
            border-bottom: 1px solid #85bdd5;
          }
        }

        & .flight-option-detail-view-switch {
          position: relative;
          left: 50%;
          width: fit-content;
          transform: translate(-50%, -20%);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 4px 0px;

          &:hover {
            cursor: pointer;
          }

          & .icon {
            width: 11px;
            height: 7px;
            margin-left: 8px;

            & .icon-drop-down {
              width: 100%;
              height: 100%;
              background-color: #00a1de;
              -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_more.svg")
                no-repeat center;
              mask-size: contain;
            }

            & .icon-drop-up {
              width: 100%;
              height: 100%;
              background-color: #00a1de;
              -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_less.svg")
                no-repeat center;
              mask-size: contain;
            }
          }
        }
        @include responsive(SM) {
          .flight-option-detail-view-switch {
            position: absolute;
            bottom: 0;
            left: 50%;
            height: 40px;
            width: fit-content;
            transform: translate(-50%, -20%);
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              cursor: pointer;
            }

            & .icon {
              width: 11px;
              height: 7px;
              margin-left: 8px;

              & .icon-drop-down {
                width: 100%;
                height: 100%;
                background-color: #00a1de;
                -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_more.svg")
                  no-repeat center;
                mask-size: contain;
              }

              & .icon-drop-up {
                width: 100%;
                height: 100%;
                background-color: #00a1de;
                -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_less.svg")
                  no-repeat center;
                mask-size: contain;
              }
            }
          }
        }
      }
    }

    & .flight-options-list-view-switch {
      display: flex;
      flex-direction: row;
      justify-content: center;

      & .view-switch-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 20px;
        font-size: 16px;

        &:hover {
          background-color: #e8f4fb;
          cursor: pointer;
        }

        & .icon {
          width: 10px;
          height: 5px;
          margin-left: 10px;

          & .icon-drop-down {
            width: 100%;
            height: 100%;
            background-color: #00a1de;
            -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_more.svg")
              no-repeat center;
            mask-size: contain;
          }

          & .icon-drop-up {
            width: 100%;
            height: 100%;
            background-color: #00a1de;
            -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_less.svg")
              no-repeat center;
            mask-size: contain;
          }
        }
      }
    }
  }
}

@include responsive(SM) {
  .holiday-package-flight-component {
    & .flight-options-content {
      & .flight-option {
        height: 360px;
        &.wide {
          height: auto;
        }

        & .flight-option-details {
          & .section-leg {
            &.outbound-leg {
              padding-top: 48px;
              height: auto;
            }

            &.inbound-leg {
              height: auto;
            }

            & .vertical {
              display: none;
              .flight-details-section {
                display: none;
              }
            }

            & .horizontal {
              display: flex;
              flex-direction: column;
              width: 100%;
              font-size: 16px;
              .flight-details-section {
                display: none;
              }

              & .row:nth-child(1) {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;

                & .column {
                  width: 33%;
                  line-height: 24px;
                }

                & .column:nth-child(1) {
                  text-align: right;
                }

                & .column:nth-child(2) {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-between;
                  width: 130px;
                  font-size: 14px;
                  line-height: 14px;

                  & .airline-logo {
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 24px;
                  }

                  & .flight-type-graphics {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    & .direct-flight {
                      background-image: url("../../assets/icons/klm/klm-flight-connector-direct-horizontal.svg");
                      background-repeat: no-repeat;
                      background-position: center;
                      width: 82px;
                      height: 24px;
                    }

                    & .indirect-flight {
                      background-image: url("../../assets/icons/klm/klm-flight-connector-indirect-horizontal.svg");
                      background-repeat: no-repeat;
                      background-position: center;
                      width: 82px;
                      height: 24px;
                    }
                  }

                  & .flight-stops,
                  & .flight-duration {
                    padding-top: 6px;
                  }

                  & .flight-duration {
                    padding-top: 4px;
                  }

                  & .flight-stops-number {
                    margin-right: 3px;
                  }
                }
              }
            }

            & .luggage-baggage-information {
              margin-top: 10px;
            }
          }
        }

        & .flight-option-detail-view-switch {
          transform: translate(-50%, -15%);
        }
      }
    }
  }
}

@include responsive(MD) {
  .holiday-package-flight-component {
    & .flight-options-header {
      display: flex;
    }

    & .flight-options-content {
      & .flight-option {
        flex-direction: row;
        height: 266px;

        &.wide {
          min-height: 225px;
          height: auto;
        }

        & .flight-option-status {
          top: -3px;
          left: 100%;
          transform: translate(calc(-100% - 16px), 3px);
          justify-content: flex-end;
        }

        & .flight-option-selector {
          top: 155px;
        }

        & .flight-option-details {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;

          & .section-leg {
            &.outbound-leg {
              padding-top: 28px;
              padding-right: 16px;
              width: 50%;
              height: auto;
            }

            &.inbound-leg {
              padding-top: 28px;
              padding-left: 16px;
              width: 50%;
              height: auto;
            }

            & .vertical {
              display: block;
              .flight-details-section {
                display: block;
              }
              .section-flight-out,
              .section-flight-in {
                display: none !important;
              }

              & .row:nth-child(1) {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
              }
            }

            & .horizontal {
              display: none;
              .flight-details-section {
                display: block;
              }
            }

            & .luggage-baggage-information {
              margin-top: 30px;
              margin-bottom: 20px;
            }
          }

          & .section-borderline {
            margin-top: 10px;
            min-height: 140px;
            border-right: 1px solid #85bdd5;
            border-bottom: none;
            margin-bottom: 40px;
          }
        }

        & .flight-option-detail-view-switch {
          transform: translate(-50%, -10%);
        }
      }
    }
  }
}

@include responsive(LG) {
  .holiday-package-flight-component {
    .section-flight-out,
    .section-flight-in {
      display: none;
    }
    .flight-destination {
      margin-top: 80px;
    }
    .multiple-expanded-info {
      .expanded-info {
        & .expanded-info {
          margin-bottom: 0;
        }

        & .expanded-info:first-child {
          margin-bottom: 40px;
        }
      }
    }
    .flight-details-section {
      display: block;
    }
    & .flight-options-content {
      & .flight-option {
        min-height: 235px;

        & .flight-option-details {
          & .section-leg {
            & .vertical {
              display: none;
            }

            & .horizontal {
              display: block;
              .flight-details-section {
                display: block;
              }
            }

            & .luggage-baggage-information {
              position: absolute;
              bottom: 0;
              margin: 0;
              padding: 0;
              width: calc((100% - 32px) / 2);
              transform: translate(0, -14px);

              & .text-extended {
                display: block;
              }
            }

            &:nth-child(3) .luggage-baggage-information {
              justify-content: flex-end;
              padding-right: 16px;
            }
          }
        }

        & .flight-option-detail-view-switch {
          transform: translate(-50%, -9%);
          font-size: 16px;
        }
      }
    }
  }
}

.no-flights-info,
.btn-clear-all-filters {
  text-align: center;
}

.opacity-50 {
  opacity: 0.5;
}

.button-flight-wrapper {
  .loading-flight-container {
    display: flex;
    gap: 5px;
  }
  .button-loading-spinner-flight {
    margin-top: 2px;
    margin-right: 16px;
    animation: none;

    .MuiCircularProgress-svg {
      height: 18px;
      width: 18px;
      transform: none;
    }
  }
}
