@import "src/assets/scss/common/breakpoints.scss";

.city-label-component {
  & .city-label-input {
    @include responsive(XXS) {
      display: block;
      width: calc(100% - 12px);
    }
    @include responsive(SM) {
      width: 392px;
    }
  }
}
