@import "src/assets/scss/klm/klm-colors.scss";

.date-of-born {
  display: block;
  font-size: 12px;
  padding-top: 8px;

  & label {
    padding: 0;
  }

  & .date-of-birth-label {
    font-size: 16px;
    padding-top: 8px;
  }

  & .birthday-day {
    display: inline-flex;
    width: 56px;

    & input {
      text-align: center;
      padding: 0;
    }
  }

  & .birthday-month {
    display: inline-flex;
    width: 56px;
    margin: 0 8px;
    & input {
      text-align: center;
      padding: 0;
    }
  }

  & .birthday-year {
    display: inline-flex;
    width: 72px;
    & input {
      text-align: center;
      padding: 0;
    }
  }
}
