@import "../../common/colours.scss";
@import "src/assets/scss/common/breakpoints.scss";

.map-component-placeholder {
  width: 288px;
  margin: 44px 20px 15px 0;

  & img {
    width: 288px;
  }
}

.filters-component-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 288px;
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }

  @include responsive(XXS) {
    margin: 0;
    width: 100%;
  }

  @include responsive(SM) {
    margin-right: 20px;
    width: 100%;
  }

  @include responsive(XL) {
    width: 288px;
    box-shadow: 0 10px 30px 0 rgba(124,127,125,0.25);
  }

  .filters-component {
    width: 100%;
  }

  & > .filters-component-elements {
    width: 288px;
    min-height: 100vh;
  }

  .filters-header {
    height: 32px;
    width: 72px;
    color: $klm-black;
    font-family: UniversalSans-Display-Medium;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: 12px;
    padding: 0 16px 16px 16px;
  }

  & .filters-content {
    padding: 0 16px;
  }

  & > .skeleton {
    width: 288px;
  }
}

.range-labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.price-container {
  display: flex;
  flex-direction: column;

  .price-inputs {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
}

.checkbox-wrapper {
  display: flex;
  justify-content: space-between;
}

.line-space {
  margin-bottom: 26px;
  height: 1px;
  width: 100%;
}
