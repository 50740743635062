@import "./common/colours.scss";
@import "./assets/scss/klm/klm-fonts.scss";
@import "./assets/scss/transavia/transavia-fonts.scss";
@import "./assets/scss/common/breakpoints.scss";

body {
  font-family: "UniversalSans-Text-Light";
  // font-family: "UniversalSans-Text-Regular";
  // font-family: "UniversalSans-Display-Regular";
  // font-family: "UniversalSans-Display-Light";
  // font-family: "KLM-Noa-Regular";
  // font-family: "Nexa-XBold-Regular";
  // font-family: "Nexa-Book-Regular";
}

.App {
  margin: 0;
  padding: 0;
}

.holiday-overflow {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: $primary-blue;
}

.extra-large-heading {
  font-size: 48px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 500;
}

.disabled {
  background-color: $disabled-grey !important;
  color: $disabled-text !important;
  cursor: not-allowed !important;
}

.large-heading {
  font-size: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 400;
}

.medium-heading {
  font-size: 18px;
  padding-bottom: 12px;
  font-weight: 400;
}

.larger-text {
  font-size: 16px;
  color: $light-text-black;
}

.larger-text-bold {
  font-size: 16px;
  color: $light-text-black;
  font-weight: 600;
}

.medium-text {
  font-size: 14px;
  color: $light-text-black;
}

.medium-text-aligned-end {
  font-size: 14px;
  color: $light-text-black;
  align-self: end;
}

.medium-bold-text {
  font-size: 14px;
  color: $light-text-black;
  font-weight: 600;
}

.smaller-text {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 12px;
}

.aligned-icon {
  align-self: center;
  color: $primary-blue;
}

.flex-box {
  justify-content: space-between;
}

.icon-box {
  padding-right: 8px;
  align-self: center;
}

.icon-outline {
  display: flex;
  padding: 6px;
  width: 18px;
  height: 18px;
  width: 22px;
  height: 22px;
  border-radius: 30px;
  outline: 1px solid $background-grey;
  margin-right: 8px;
  margin-top: 10px;
  & {
    @include responsive(MD) {
      width: 18px;
      height: 18px;
    }
  }
}

.price-text {
  box-sizing: border-box;
  font-weight: 600;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  padding-left: 3px;
  padding-right: 3px;
  color: $klm-black;
}

.spacer-block {
  flex: 1;
}

.small-space {
  margin-bottom: 4px;
}

.code-block {
  background-color: black;
  color: white;
  display: block;
  flex: 1;
  padding: 20px;
  margin: 20px;
}

.MuiRating-iconEmpty {
  display: none !important;
}

.css-ktslay-MuiButtonBase-root-MuiIconButton-root:hover {
  opacity: 1 !important;
}

.css-8je8zh-MuiTouchRipple-root:hover {
  opacity: 1 !important;
}

.hidden {
  display: none;
}

// Adyen component styling needs to be global
.adyen-checkout__card__cvc__input {
  & {
    @include responsive(ADYENMOBILE) {
      padding-right: 8px !important;
      margin-left: -8px !important;
      flex: 1;
    }
  }
}

.adyen-checkout__field-wrapper {
  & {
    @include responsive(ADYENMOBILE) {
      display: flex;
      flex-direction: column;
    }
  }
}

.adyen-checkout__field--50 {
  & {
    @include responsive(ADYENMOBILE) {
      width: 100% !important;
    }
  }
}

.adyen-checkout__card__cvc__hint__wrapper {
  & {
    @include responsive(ADYENMOBILE) {
      margin-right: 19px !important;
    }
  }
}

.american-express-logo {
  border-radius: 2px;
  padding: 0px;
}

.adyen-checkout__issuer-list {
  margin-top: 4px;
}

.adyen-checkout__dropdown__element {
  color: black;
}

.adyen-checkout__input {
  min-height: 46px;
  padding-left: 16px !important;
}

.adyen-checkout__dropdown__button {
  max-height: 34px !important;
}

.dev-helper-button {
  padding: 40px;
  margin: 50px;
  background-color: lightgray;
  border: 1px solid royalblue;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}