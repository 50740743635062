@import "../../assets/scss/common/breakpoints.scss";

.expanded-connector {
  display: flex;
  width: 96px;

  .connector-time {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 24px;
    margin-left: 8px;

    & .full-date {
      display: block;
      text-wrap: nowrap;
    }
    .departure-time {
      margin-top: -3px;
      & .time-data {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        @include responsive(SM) {
          font-size: 16px;
        }
      }
    }
    .arrival-time {
      margin-bottom: -3px;
      & .time-data {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        @include responsive(SM) {
          font-size: 16px;
        }
      }
    }
    p {
      margin: 0;
    }
  }
}

.expanded-info {
  position: relative;
  display: flex;
  height: 130px;
}

.expanded-flight {
  display: block;
  align-items: center;
  justify-content: space-between;
  @include responsive(XXS) {
    width: 175px;
    display: flex;
    flex-grow: 1;
  }

  @include responsive(XS) {
    width: 190px;
  }

  @include responsive(XS400) {
    width: 230px;
  }

  @include responsive(MD) {
    width: 242px;
  }

  @include responsive(LG) {
    width: 320px;
  }

  @include responsive(XL) {
    width: 417px;
    margin-right: 6px;
  }

  @include responsive(XXL) {
    width: 453px;
  }

  .expanded-icons {
    display: flex;
    flex-direction: column;

    .airport {
      display: flex;
      margin: 0;
      & .fl-number {
        margin-left: 0px;
      }
    }
    span {
      margin-left: 9px;
    }
    img {
      width: 10px;
      height: 10px;
    }
    .ar-type-box,
    .fl-number-box,
    .distance-box {
      display: flex;
      align-items: center;
    }

    .operator-box {
      display: flex;
      align-items: center;
      box-sizing: border-box;

      & .operator {
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    @include responsive(XXS) {
      .ar-type,
      .fl-number,
      .distance-box,
      .distance {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }

    @include responsive(LG) {
      .distance-box,
      .distance
       {
        width: 12ch;
       }
    }
    @include responsive(XL) {
      .data-container {
        flex-direction: row;
      }
    }
  }

  .expanded-data {
    display: flex;
    flex-direction: column;
    gap: 15px;

    p {
      margin: 0;
    }
  }
}
.segments-container {
  display: flex;
}

.multi-segments {
  & .expanded-info {
    margin-bottom: 0;
  }

  & .expanded-info:first-child {
    margin-bottom: 65px;
  }
}

.container-stop {
  position: relative;
  .transfer-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: 145px;
    left: 15px;
    width: max-content;
    padding-right: 20px;
    .information-container {
      display: flex;
      gap: 5px;
    }
    .icon-container {
      margin-left: 8px;
    }
  }
}

.multiple-expanded-info {
  width: 100%;
}

@include responsive(MD) {
  .section-flight-in,
  .section-flight-out {
    display: none;
  }
}
