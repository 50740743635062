@import "src/assets/scss/common/breakpoints.scss";

.company-label-component {
  & .company-label-input {
    @include responsive(XXS) {
      display: block;
      width: calc(100% - 12px);
    }
    @include responsive(SM) {
      width: 392px;
    }
  }
}
