@import "../../common/colours.scss";

.tripadvisor-rating-component {
  display: flex;
  align-self: center;
  position: relative;
}

.dot {
  box-sizing: border-box;
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: $tripadvisor-green;
}

.filled {
  background-color: $tripadvisor-green;
}

.fractional-border {
  display: inline-block;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  border: 1px solid $tripadvisor-green;
  top: 0;
}
.fractional-rating-container {
  display: flex;
  position: relative;
}

.fractional-filled {
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  position: absolute;
  clip-path: inset(0px calc(100% - var(--fractional-fill) * 100%) 0px 0px);
}

.empty {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: $white;
  border: 1px solid $tripadvisor-green;
}
