@import "../../common/colours.scss";

.dropdown-component {
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  color: $light-text;
  position: relative;
  width: 100%;
}

.dropdown-container {
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  color: $light-text;
  position: relative;
  width: 100%;

  .custom-dropdown-items {
    font-size: 100px;
  }

  .dropdown-title {
    width: 100%;
    border: none;
    border-radius: 5px;
  }

  .dropdown-text {
    box-sizing: border-box;
    height: 100%;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .react-datepicker-wrapper {
      display: flex;

      input {
        width: 100%;

        &:focus {
          outline: none;
        }

        border: none;

        div {
          width: 100%;
        }
      }
    }
  }

  .dropdown-text-form-styling {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .dropdown-options {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    right: 0;
    padding: 16px 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    z-index: 3;
    justify-content: space-between;

    .dropdown-option {
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }

  .drop-down-icon-down {
    background-color: lightskyblue;
    width: 100%;
    height: 100%;
    -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_more.svg") no-repeat center;
    mask-size: cover;
}

.drop-down-icon-up {
    background-color: lightskyblue;
    width: 100%;
    height: 100%;
    -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_less.svg") no-repeat center;
    mask-size: cover;
}
}

