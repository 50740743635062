.summary-bar {
  height: 48px;
  background-color: #fff;
  padding: 0px 8px;
  .summary-bar-body-icon {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .summary-bar-body {
    display: flex;
    justify-content: space-between;
  }
}
.sticky-shopping-cart {
  position: sticky;
  top: 0;
  z-index: 11;
}