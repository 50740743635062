@import "../../common/colours.scss";
@import "../../assets/scss/common/breakpoints.scss";

.passenger-details-page-component{
    justify-content: center;
    margin: 0 auto;
}

.passenger-details-container {
    justify-content: center;
    margin: 0 auto;
    display: flex;
    @include responsive(XXS) {
        flex-direction: column;
    }
    @include responsive(MD) {
        flex-direction: row;
        gap: 24px;
    }
}

.passenger-details-parent {
    display: flex;
    width: 80%;
    margin: 0 auto;
    flex-direction: row;
    flex:1;
    padding-top: 10px;
}

.passenger-details-card-container {
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 16px;
}

.passenger-details-trip-summary {
    display: flex;
    width: 45%;
    height: 1000px;
    background-color: white;
    border-radius: 6px;
    margin-top: 20px;
    margin-right: 10%;
}

.shopping-cart-container {
    height: fit-content;
    background-color: #fff;
    margin-top: 110px;
    @include responsive(XXS) {
        display: none;
    }

    @include responsive(MD) {
        display: flex;
    }
}
