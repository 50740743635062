@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-buttons.scss";

.extras-page-component {
  @include page-container-responsive();
  margin: 0 auto;

 & .extra-page-container {
  display: flex;
  position: relative;
  margin: 0 auto;

  @include responsive(XXS) {
    flex-direction: column;
  }
  @include responsive(MD) {
    flex-direction: row;
    gap: 24px;
  }

  & .extra-page-left-column {
    width: 100%;

    @include responsive(XXS) {
      display: flex;
      flex-direction: column;
    }

    @include responsive(MD) {
      display: block;
    }
    & .extras-page-title {
      @include responsive(SM) {
        margin: 104px 0 34px 0;
      }
    }

    & .section-heading-text {
      font-weight: normal;
      line-height: 48px;

      @include responsive(XXS) {
        font-size: 24px;
      }
      @include responsive(SM) {
        margin-bottom: 16px;
        font-size: 48px;
      }
    }
    & .action-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      margin-top: 16px;
      
  
      & .back-checkout {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        gap: 10px;
        padding-right: 12px;
        cursor: pointer;
        & svg {
          height: 11px;
          width: 5px;
          margin-left: 12px;
        }
      }
  
      & .button-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 148px;
        font-size: 16px;
      }
  
      & .back-checkout {
        position: static;
        font-size: 16px;
        height: 40px;
        top: 0;
        padding-left: 0;
      }
    }
  }
  
  & .shopping-cart-container {
    @include responsive(XXS) {
      margin: 24px 0;
      margin-top: 24px;
    }
    @include responsive(MD) {
      margin-top: 110px;
    }
  }
}
}

.mb-2 {
  margin-bottom: 2rem;
}
