@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/common/breakpoints.scss";

$font-size-32: 32px;
$font-size-primary-header: 18px;
$font-size-secondary-header: 16px;
$font-size-regular-text: 16px;

.shopping-cart-component {
  box-sizing: border-box;
  line-height: 1.5;
  width: 18rem;

  & .shopping-cart-box {
    padding: 25px 16px 21px 16px;

    .fees-container {
      display: flex;
      justify-content: space-between;

      & .holiday-card-hotel-name {
        font-size: $font-size-primary-header;
        height: 24px;
        display: flex;
        align-items: center;
        max-width: 100% !important;
      }
    }
  }

  & .summary-icon {
    text-align: center;
  }

  & .section-header,
  & .flight-component-header {
    display: flex;
    gap: 12px;
  }

  & .accomodation-component {
    .section-header {
      display: flex;
      gap: 17px;
    }
  }

  & .payment-cost {
    display: flex;
    justify-content: space-between;
  }

  & .sustainability-component {
    .section-header {
      display: flex;
      align-items: center;
    }
  }

  & .shopping-cart-box {
    border-bottom: none;
  }

  & .button-accept {
    width: 100%;
    height: 3rem;
  }

  & .klm-green {
    color: $klm-green !important;
    font-weight: bold;
  }

  & .section {
    font-size: $font-size-regular-text;
  }

  & .section-details {
    color: $klm-blue-500;
  }

  & .content-item {
    position: relative;
    display: flex;
    min-width: 260px;
    justify-content: space-between;

    & .downpayment-pay-later {
      width: 75%;
    }

    span:nth-child(1) {
      flex-grow: 3;
    }

    span:nth-child(2) {
      flex-shrink: 2;
      text-align: right;
    }

    & .info-icon {
      display: inline-flex;
      vertical-align: text-bottom;
      font-size: $font-size-regular-text;
      color: $klm-blue-500;
    }

    & .content-item-price {
      display: flex;
      justify-content: end;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  & .total {
    align-items: baseline;
    font-family: UniversalSans-Display-Regular;

    div:nth-child(1) {
      font-size: $font-size-secondary-header;
    }

    div:nth-child(2) {
      font-size: $font-size-32;
    }
  }
}

.holiday-card-hotel-name {
  font-size: $font-size-primary-header;
  height: 24px;
  display: flex;
  align-items: center;
}

.content-item-icon {
  position: relative;
  display: inline-block;
  top: 2px;
  margin-left: 2px;
}

.icon-inform {
  cursor: pointer;
}

.shopping-cart {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: white;
  z-index: 9999;

  @include responsive(XXS) {
    width: 100%;
    display: block;
  }

  @include responsive(SM) {
    width: 360px;
    display: block;
  }
}
