@import "src/common/colours";
.input-component {
  display: inline-block;
  width: 100%;
  margin: 8px 0;

  & label {
    display: block;
    flex-wrap: nowrap;
    flex-direction: column;
    line-height: 24px;

    & input {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      vertical-align: top;
      cursor: pointer;
      background-color: white;
      position: relative;
      height: 48px;
      line-height: 48px;
      padding: 0 16px;
      border-radius: 4px;
      font-size: 16px;
      flex: 1;
      box-sizing: border-box;

      &:hover {
      }

      &:focus-visible {
        outline: none;
      }
    }
  }
}

