@import "src/assets/scss/common/breakpoints.scss";

.flex-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  &.single {
    flex-direction: row-reverse;
  }
}

.traveller-container,
.mainbooker,
.homestayer {
  padding-top: 16px;
  padding-left: 23px;
  background-color: #fff;
  box-sizing: border-box;
  display: inline-block;
}

.information-container {
  &.single-traveller {
    @include responsive(XXS) {
      display: block;
    }
    @include responsive(XL) {
      display: flex;
    }
  }
}

.traveller-container {
  margin-top: 16px;
  margin-bottom: 16px;

  &.full-width {
    width: 100%;
    + .flex-container {
      @include responsive(XXS) {
        flex-direction: column-reverse;
      }
      @include responsive(XL) {
        width: fit-content;
      }
    }
  }

  &.two-travellers {
    min-height: 188px;
    vertical-align: top;
    @include responsive(XXS) {
      width: 100%;
    }
    @include responsive(XL) {
      width: 412px;
    }
    @include responsive(XXL) {
      width: 448px;
    }

    .traveller-info-container {
      display: flex;
      @include responsive(XXS) {
        flex-direction: column;
      }
      @include responsive(SM) {
        flex-direction: row;
        .traveller.second-traveller {
          margin-left: 100px;
        }
      }
    }
    .name {
      max-width: 20ch;
      word-break: break-all;
    }

    .traveller.second-traveller {
      max-width: 15ch;
      word-break: break-all;
    }

    .traveller-container.full-width {
      .name {
        max-width: 25ch;
        word-break: break-all;
      }
    }
  }

  &.full-width .traveller-info-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0;
    .traveller {
      padding-right: 0;
      margin-bottom: 16px;
    }
  }
}

.traveller {
  &.horizontal {
    display: inline-block;
    @include responsive(XXS) {
      width: 50%;
    }
    @include responsive(SM) {
      width: 33.3%;
    }
  }
}

.mainbooker {
  height: fit-content;
  margin-top: 16px;
  @include responsive(XXS) {
    .data-info-container {
      display: flex;
    }
  }
}

.mainbooker-inline {
  padding-right: 24px;
  @include responsive(XXS) {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 0;
  }
  @include responsive(SM) {
    .mainbooker-container {
      display: flex;
      .data-mainbooker-info {
        margin-right: 50px;
        .name {
          display: flex;
          gap: 8px;
        }
      }
    }
  }

  @include responsive(XL) {
    width: 412px;
    position: absolute;
    display: inline-block;
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 0;
    .mainbooker-container {
      display: inline;
      .data-mainbooker-info {
        margin-right: 0px;
      }
    }
  }
  @include responsive(XXL) {
    width: 448px;
  }
}

.moved-down {
  position: static;
  margin-top: 0;
  @include responsive(XXS) {
    width: 100%;
    .mainbooker-container {
      display: inline;
    }
  }
  @include responsive(SM) {
    .mainbooker-container {
      display: flex;
    }
  }
  @include responsive(XL) {
    .mainbooker-container {
      display: inline;
      .mobile-info {
        margin-left: 0;
      }
    }
    width: 448px;
  }
}

.inline {
  display: block;
  margin-bottom: 40px;
}

.traveller-layout {
  display: flex;
  justify-content: space-between;
  @include responsive(XXS) {
    gap: 0;
  }
  @include responsive(XL) {
    gap: 20px;
  }

  .first-traveller-container {
    flex: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 16px;
    @include responsive(XXS) {
      margin-bottom: 0px;
    }
    @include responsive(XL) {
      margin-bottom: 16px;
    }
  }

  .traveller-grid {
    display: flex;
    margin-top: 16px;
    @include responsive(XXS) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
    @include responsive(SM) {
      flex-wrap: wrap;
      flex-direction: row;
    }

    @include responsive(XL) {
      margin-top: 16px;
    }

    .traveller {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      @include responsive(XXS) {
        width: auto;
      }
      @include responsive(SM) {
        width: 208px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .traveller-layout {
      flex-direction: column;

      .first-traveller-container,
      .remaining-travellers-container {
        flex: 1 0 100%;
      }

      .remaining-travellers-container .traveller-grid {
        .traveller {
          flex: 1 0 100%;
        }
      }
    }
  }
}
