@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-buttons.scss";
@import "src/assets/scss/klm/klm-colors.scss";

.hotel-information-component {
  @include responsive(LG) {
    width: 70%;
    min-height: 200px;
  }

  & .hotel-description-markup-text {
    white-space: pre-wrap;
  }

  & .hotel-amenities-markup-text {
    @media (max-width: 768px) {
      & ul {
        margin-bottom: 0;
        margin-top: 0;
  
        & li {
          margin-right: 16px;
        }
      }
    }
    @include responsive(MD) {
      display: flex;
    }
  }

  & .hotel-description-markup-text.no-amenities {
    display: block; 
  }

  & .hotel-information-subtitle {
    margin: 32px 0 15px 0;
  }

  & .hotel-information-facilities {
    & ul {
      @include responsive(XXS) {
        column-count: 1;
        margin-bottom: 8px;
      }
      @include responsive(SM) {
        column-count: 3;
      }
    }

    & li {
      box-sizing: border-box;
      display: flex;
      position: relative;
      font-size: 14px;
      letter-spacing: 0.2px;
      margin-bottom: 6px;
      line-height: 20px;
      height: 20px;

      & span {
        margin-left: 24px;
      }

      & img {
        position: absolute;
        padding: 5px 0;
        height: 12px;
        margin-right: 10px;
      }
    }

    & .hotel-information-facilities-list {
      margin: 0;
      padding: 0;

      & .hotel-information-facilities-list-element {
        list-style-type: none;
      }
    }
  }

  & .hotel-information-button {
    left: 0 !important;
    margin-top: 13px;
    margin-bottom: 12px;

    &:hover {
      cursor: pointer;
    }

    & .button-container {
      display: flex;

      & .dropdown-caret {
        margin-right: 10px;
        margin-left: 10px;
        width: 10px;

        &.rotate {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.hotel-information-overview-description {
  position: relative;
  max-height: 168px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.hotel-information-overview-description.show-full-text {
  max-height: none;
}

.hotel-information-overview-description.is-content-tall:not(.show-full-text)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
}

.read-more-less-button {
  cursor: pointer;
}

.read-more-less-button {
  text-align: center;
  margin-top: 5px;
  &:hover {
    text-decoration: underline;
  }
}

