@import "../../assets/scss/klm/klm-colors.scss";
@import "../../assets/scss/common/breakpoints.scss";

.calamity-component {
  position: relative;
  z-index: 2;

  & .calamity-header {
    display: flex;
    margin: 0 auto;
    padding: 0;

    @include responsive(MD) {
      width: 768px;
    }
    @include responsive(LG) {
      width: 944px;
    }
    @include responsive(XL) {
      width: 1152px;
    }
    @include responsive(XXL) {
      width: 1224px;
    }

    & .calamity-container {
      margin: 0 auto;
      width: 100%;
      padding: 8px;
      flex-direction: row;
      display: flex;
      justify-content: center;

      & .alert-data {
        display: flex;
         position: relative;

        & .MuiAlert-root {
          padding: 0;
        }

        & .MuiSvgIcon-root {
          font-size: 20px;
          padding: 0;
          margin: auto;
        }

        & .MuiAlert-icon {
          padding: 0 8px;
          position: absolute;
          top: 0;
        }

        & .MuiAlert-message {
          padding: 0;
          margin-left: 32px;
          overflow: hidden;
          p {
            margin: 0;
            font-size: 16px;
          }
        }
      }
    }

    & .calamity-close-button {
      width: 40px;
      border: none;
      cursor: pointer;
    }
  }
}
