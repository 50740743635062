@import "src/assets/scss/klm/klm-colors";
@import "src/assets/scss/common/breakpoints";

.selected-filters-component {
  width: 100%;
  display: inline-block;
  margin-top: 4px;

  @media (max-width: 769px) {
    margin-bottom: 10px;
  }

  @include responsive(XL) {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .selected-filters-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: 576px) and (max-width: 1200px){
    margin-bottom: 12px;
    }
  }

  .selected-filters-header {
    height: 24px;
    font-size: 16px;
    margin-top: 2px;
  }

  .delete-all-filter-chips {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
    line-height: 24px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

