@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/common/breakpoints.scss";


.hotel-map-component {
    display: flex;
    position: relative;
    width: 342px;
    height: 228px;
    margin: 0;
    padding: 0;

    #map {
        width: 342px;
        height: 228px;
        border-radius: 4px;
    }

  @include responsive(XXS) {
    margin-top: 32px;
  }

  @include responsive(MD) {
    margin-top: 16px;
  }
}
