@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors.scss";

.terms-container {
  display: flex;
  justify-content: space-between;
  background-color: $klm-white-50;
  border-radius: 2px;
  padding: 24px;
  
  @include responsive(XXS) {
    & {
      flex-direction: column;
    }
  }
  @include responsive(LG) {
    & {
      flex-direction: row;
    }
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .rules-box {
    p {
      margin: 0;
      padding-top: 10px;
      &:first-child {
        padding-top: 0;
      }
    }
  }
  .links-box,
  .rules-box {
    flex: 1;
    width: 50%;
    box-sizing: border-box;
    @include responsive(XXS) {
      & {
        width: 100%;
        margin-top: 20px;
      }
    }
    @include responsive(LG) {
      & {
        margin-top: 0px;
      }
    }
    .rights-paragraph p > a:hover {
      text-decoration: none;
    }
    .checkbox-section,
    .pdf-section {
      margin-bottom: 1rem;
      a:hover {
        text-decoration: none;
      }
    }
    .checkbox-section {
      @include responsive(XXS) {
        & {
          align-items: start;

        }
      }
      @include responsive(SM) {
        & {
          align-items: center;
        }
      }
      @include responsive(LG) {
        & {
          align-items: start;
        }
      }
      @include responsive(XL) {
        & {
          align-items: start;
        }
      }
      @include responsive(XXL) {
        & {
          align-items: center;
        }
      }
    }
  }
}

.faded {
  opacity: 0.5;
  pointer-events: none;
}
