@import "src/assets/scss/common/breakpoints.scss";

.summary-price-overview-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 32px 32px 0;

  & .summary-price-overview-block {
    @include responsive(XXS) {
      padding-bottom: 16px;
    }
    @media (min-width: 642px) {
      padding-right: 12px;
    }
  }

  @include responsive(XXS) {
    margin: 24px 12px;
    padding: 32px 0;
  }
  @include responsive(XS) {
    margin: 32px;
  }
  @media (min-width: 642px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @include responsive(LG) {
    margin: 0 16px;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-top: none;
  }

  & .price-overview-title {
    font-size: 18px;
    margin-bottom: 20px;

    @include responsive(SM) {
      border-top: none;
      margin-top: 0;
    }
  }

  & .price-overview-row {
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
    line-height: 24px;
    .item-tooltip {
      cursor: pointer;
      .content-holiday-tooltip {
        position: relative;
      }
      .tooltip-summary {
        top: 25px;
        left: 16px;
      }
    }

    & .price-overview-row-description {
      box-sizing: border-box;
      @include responsive(XXS) {
        flex-wrap: wrap;
        width: 215px;
      }

      @include responsive(MD) {
        flex-wrap: nowrap;
        max-width: 100%;
      }
      display: inline-block;
      & img {
        height: 14px;
        position: relative;
        padding-left: 10px;
        top: 2px;
      }
    }

    & .price-overview-row-price {
      flex-shrink: 0;
      flex-grow: 1;
      display: flex;
      justify-content: right;
    }
  }
}
