@import "src/assets/scss/common/breakpoints.scss";

.footer-component {
    background-color: #F7F9F9;
    @include responsive(XXS) {
        margin-top: 32px;
    }
    @include responsive(XL) {
        margin-top: 80px;
    }


    &>.footer-component-content {
        margin: 0 auto;
        height: 100%;
        color: #001526;

        @include responsive(XXS) {
            width: calc(100% - 32px);
            margin-top: 32px;
        }

        @include responsive(MD) {
            width: 768px;
        }

        @include responsive(LG) {
            width: 944px;
        }

        @include responsive(XL) {
            width: 1152px;
        }

        @include responsive(XXL) {
            width: 1224px;
        }

        @include responsive(XXXL) {
            width: 1224px;
        }

        &>.unique-selling-points {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            gap: 12px;
            padding-top: 30px;
            border-bottom: 1px solid #D1D2D1;
            font-size: 16px;
            color: #001526;
            letter-spacing: 0.46px;

            @include responsive(XXS) {
                padding-bottom: 46px;
            }

            @include responsive(LG) {
                padding-bottom: 70px;
            }

            &>.unique-selling-point {
                display: flex;
                flex-direction: row;

                @include responsive(XXS) {
                    width: 100%;
                }

                @include responsive(SM) {
                    width: 45%;
                }

                @include responsive(XL) {
                    width: calc(100% / 5);
                }

                &>.usp-icon-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 33.36px;
                    height: 32px;
                    border-radius: 50%;
                    box-sizing: border-box;
                    background-color: #00A1DE;

                    & img {
                        max-height: 70%;
                    }
                }

                &>.usp-text-container {
                    margin-right: 0 16px;
                    width: calc(100% - 32px);
                    padding-top: 5px;
                    padding-left: 16px;
                    line-height: 24px;
                }
            }
        }

        &>.contact-and-conditions-links {
            display: flex;
            padding-top: 50px;
            color: #003145;

            @include responsive(XXS) {
                flex-direction: column;
            }

            @include responsive(SM) {
                flex-direction: row;
            }

            & a {
                color: inherit;
                text-decoration: none;
                letter-spacing: 0.46px;
            }

            &>.contact-links {
                width: 300px;

                &>.contact-links-header {
                    padding-bottom: 20px;
                    font-size: 24px;
                }

                &>.link-container {
                    line-height: 24px;
                }

                &>.logo-link-container {
                    padding-top: 46px;

                    @include responsive(XXS) {
                        display: none;
                    }

                    @include responsive(SM) {
                        display: block;
                    }
                    
                    img {
                        max-width: 280px;
                    }
                }
            }

            &>.conditions-links {
                display: flex;
                flex-direction: column;

                @include responsive(XXS) {
                    padding-top: 46px;
                    margin-left: 0;
                }

                @include responsive(SM) {
                    padding-top: 0;
                    margin-left: 36px;
                }

                @include responsive(MD) {
                    margin-left: 46px;
                }

                @include responsive(LG) {
                    margin-left: 180px;
                }

                @include responsive(XL) {
                    margin-left: 260px;
                }

                &>.conditions-links-header {
                    padding-bottom: 20px;
                    font-size: 24px;
                }

                &>.link-container {
                    line-height: 24px;

                    @include responsive(XXS) {
                        column-count: 1;
                    }

                    @include responsive(MD) {
                        column-count: 2;
                        column-gap: 90px;
                    }
                }

                &>.logo-link-container {
                    padding-top: 46px;

                    @include responsive(XXS) {
                        display: block;
                    }

                    @include responsive(SM) {
                        display: none;
                    }

                    img {
                        max-width: 280px;
                    }
                }
            }
        }

        &>.logo-links {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 20px;
            padding: 46px 0;

            &>.img-container {
                height: 35px;

                & img {
                    max-height: 100%;
                }
            }
        }
    }
}