@import "../../../assets/scss/klm/klm-fonts.scss";
@import "../../../assets/scss/klm/klm-headings.scss";
@import "../../../assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/common/colors";

button {
    box-sizing: border-box;
}

// ##### KLM Primary Buttons
.button-primary {
    color: $klm-white-50;
    background-color: $klm-orange-500;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid transparent;

    &:hover {
        background-color: $klm-orange-400;
        cursor: pointer;
    }
    
    &:focus {
        background-color: $klm-orange-400;
        border: 2px solid $klm-blue-500;
    }
    
    &:active {
        background-color: $klm-orange-600;
    }
}

@mixin button-primary-disabled {
    color: $klm-grey-500;
    background-color: $klm-grey-300;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid transparent;
    opacity: 0.5;
}

@mixin button-primary {
    color: $klm-white-50;
    background-color: $klm-orange-500;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid transparent;

    &:hover { 
        background-color: $klm-orange-400;
        cursor: pointer;
    }
    &:focus { 
        background-color: $klm-orange-400;
        border: 2px solid $klm-blue-500;
    }
    &:active { background-color: $klm-orange-600; }
}

.button-primary-small {
    @include klm-desktop-heading-6-UniversalSans-Display-Medium;
    height: 32px;
    min-width: 72px;
    padding: 0 14px;
}

.button-primary-medium {
    @include klm-desktop-heading-5-UniversalSans-Display-Medium;
    height: 40px;
    min-width: 86px;
    padding: 0 18px;
}

.button-primary-large {
    @include klm-desktop-heading-5-UniversalSans-Display-Medium;
    height: 48px;
    min-width: 102px;
    padding: 0 26px;
}

// ##### KLM Secondary Buttons
.button-secondary {
    color: $klm-white-50;
    background-color: $klm-dark-blue-500;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid transparent;

    &:hover {
        background-color: $klm-dark-blue-400;
        cursor: pointer;
    }
    
    &:focus {
        background-color: $klm-dark-blue-400;
        border: 2px solid $klm-blue-500;
    }
    
    &:active {
        background-color: $klm-dark-blue-600;
    }
}

@mixin button-secondary {
    color: $klm-white-50;
    background-color: $klm-dark-blue-500;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid transparent;

    &:hover {
        background-color: $klm-dark-blue-400;
        cursor: pointer;
    }
    
    &:focus {
        background-color: $klm-dark-blue-400;
        border: 2px solid $klm-blue-500;
    }
    
    &:active {
        background-color: $klm-dark-blue-600;
    }
}

.button-secondary-small {
    @include klm-desktop-heading-6-UniversalSans-Display-Medium;
    height: 32px;
    min-width: 72px;
    padding: 0 14px;
}

.button-secondary-medium {
    @include klm-desktop-heading-5-UniversalSans-Display-Medium;
    height: 40px;
    min-width: 86px;
    padding: 0 18px;
}

.button-secondary-large {
    @include klm-desktop-heading-5-UniversalSans-Display-Medium;
    height: 48px;
    min-width: 102px;
    padding: 0 26px;
}


// ##### KLM Tertiary Buttons
.button-tertiary {
    color: $klm-blue-900;
    background-color: $klm-white-50;
    border: 1px solid $klm-light-blue-2-500;
    border-radius: 4px;
    box-sizing: border-box;

    &:hover {
        background-color: $klm-light-blue-50;
        cursor: pointer;
    }
    
    &:focus {
        background-color: $klm-light-blue-50;
        border: 2px solid $klm-blue-500;
    }
    
    &:active {
        background-color: $klm-light-blue-100;
    }
}

@mixin button-tertiary {
    color: $klm-blue-900;
    background-color: $klm-white-50;
    border: 1px solid $klm-light-blue-2-500;
    border-radius: 4px;
    box-sizing: border-box;

    &:hover { 
        background-color: $klm-light-blue-50;
        cursor: pointer;
    }
    &:focus { 
        background-color: $klm-light-blue-50;
        border: 1px solid $klm-light-blue-200;
        box-shadow: 0px 0px 0px 3px $klm-blue-500;
    }
    &:active { background-color: $klm-light-blue-100; }
}

.button-tertiary-small {
    @include klm-desktop-heading-6-UniversalSans-Display-Regular;
    height: 32px;
    min-width: 72px;
    padding: 0 14px;
}

.button-tertiary-medium {
    @include klm-desktop-heading-6-UniversalSans-Display-Regular;
    height: 40px;
    min-width: 86px;
    padding: 0 18px;
}

.button-tertiary-large {
    @include klm-desktop-heading-6-UniversalSans-Display-Regular;
    height: 48px;
    min-width: 102px;
    padding: 0 26px;
}

.button-cancel {
    font-family: UniversalSans-Display-Regular;
    display: inline-block;
    border: 1px solid $klm-light-blue-500;
    border-radius: 4px;
    background-color: $klm-white-50;
    width: 112px;
    height: 40px;
    font-size: 16px;

    @include responsive(SM) {
        display: none;
    }
}

.edit-search-mobile-button {
    font-family: UniversalSans-Display-Light;
    border: 1px solid $klm-light-blue-500;
}

.button-accept {
    position: relative;
    top: 10px;
    color: $klm-white-50;
    background-color: $klm-orange-500;
    border-radius: 4px;
    &:hover {
        background-color: $klm-orange-400;
      }

      &:active {
        background-color: $klm-orange-600;
      }

      &:focus-visible {
        outline: none;
        background-color: $klm-orange-400;

        &:after {
          position: absolute;
          top: -6px;
          left: -6px;
          content: "";
          width: calc(100% + 8px);
          height: 55px;
          border: 3px solid $klm-blue-500;
          border-radius: 10px
        }
      }
      &:disabled {
        color: $klm-grey-500;
        background-color: $klm-grey-300;
    }
}

.button-select {
    color: $white;
    background-color: $klm-blue-500;
    font-weight: 300;
    position: relative;
    top: 8px;
}
.button-proceed {
    width: 100%;
    border-radius: 4px;
    color: white;
    background-color: $klm-dark-blue-500;
    font-family: UniversalSans-Display-Regular;
    font-size: 16px;
}

.button-details {
    display: inline-block;
    background-color: transparent;
    color: black !important;
}

.button-text {
    background-color: transparent;
    color: white;
    font-size: 1.25rem;
    margin: auto 0;
}

.edit-search-mobile-button {
    background-color: $klm-white-50;
    box-sizing: border-box;
    padding: 0;
    height: 40px;
    width: 160px;
    font-family: UniversalSans-Display-Light;
    display: inline-block;
    border: 1px solid $klm-light-blue-500;
    border-radius: 4px;

    img {
        height: 16px;
        width: 16px;
        margin-right: 10px;
        position: relative;
        top: 3px;
    }
}

.close-edit-search-mobile-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 16px;
    font-family: UniversalSans-Display-Light;
    background-color: $klm-white-50;
    border: 1px solid $klm-light-blue-2-600;
    color: $klm-dark-blue-2;
    border-radius: 4px;
    letter-spacing: 0;
    line-height: 140%;
    cursor: pointer;
  }

.button-select {
    color: $white;
    background-color: $klm-blue-500;
    font-weight: 300;
    position: relative;
    top: 0.5rem
}

@mixin button-search-bar {
    font-family: UniversalSans-Display-Medium;
    display: flex;
    flex-direction: row-reverse;
    color: $klm-white-50;
    background-color: $klm-dark-blue-500;
    height: 40px;
    line-height: 40px;
    width: 110px;
    border-radius: 4px;
    justify-content: center;
    font-size: 16px;
    letter-spacing: 0;

    img {
        height: 16px;
        width: 16px;
        margin-right: 9px;
    }
}

.button-details {
    display: inline-block;
    color: black !important;
}

.button-text {
    color: white;
    font-size: 1.25rem;
    margin: auto 0;
}

@mixin button-tertiary-klm {
    position: relative;
    display: flex;
    padding-left: 16px;
    align-items: center;
    font-size: 16px;
    z-index: 5;
    top: 16px;
    left: 16px;
    color:$primary-colour;
    font-family: UniversalSans-Display-Regular;
    background-color: white;
    box-sizing: border-box;
    height: 40px;
    border: 1px solid $klm-light-blue-500;
    border-radius: 4px;

    &:hover {
        border: 1px solid $klm-light-blue-500;
        background-color: $klm-light-blue-50;
    }

    &:active {
        border: 1px solid $klm-light-blue-500;
        background-color: $klm-light-blue-500;
    }

    &:focus-visible {
        background-color: white;
        border: 2px solid $klm-blue-light;
        border-radius: 9px;
        outline: none;
    }
}

// ##### KLM Book Buttons
@mixin book-button {
    color: $klm-white-50;
    background-color: $klm-green;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid transparent;

    &:hover { 
        background-color: $klm-green-hover;
        cursor: pointer;
    }
    &:focus { 
        background-color: $klm-green;
        border: 2px solid $klm-blue-500;
    }
    &:active { background-color: $klm-green-dark; }
    &:disabled {
        color: $klm-grey-500;
        background-color: $klm-grey-300;
    }
}
