@import "../../assets/scss/common/breakpoints.scss";

.holiday-package-trip-component {
  position: relative;

  @include responsive(XXS) {
    width: 100%;
  }

  & * {
    box-sizing: border-box;
  }

  & > .content {
    margin: 42px auto;
    color: #003145;

    & > .content-header {
      @include responsive(XXS) {
        font-size: 24px;
        line-height: 32px;
      }
      @include responsive(SM) {
        font-size: 48px;
        line-height: 48px;
      }
    }

    @include responsive(XXS) {
      width: calc(100% - 32px);
    }

    @include responsive(MD) {
      width: 768px;
    }

    @include responsive(LG) {
      width: 944px;
    }

    @include responsive(XL) {
      width: 1152px;
    }

    @include responsive(XXL) {
      width: 1224px;
    }

    @include responsive(XXXL) {
      width: 1224px;
    }

    & > .content-price-matrix-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;
    }

    & > .content-bar-chart-container {
      font-size: 14px;

      @include responsive(XXS) {
        margin-top: 0;
      }

      @include responsive(XL) {
        margin-top: -20px;
      }

      & > .holiday-package-bar-chart {
        height: 290px;
        display: flex;
        flex-direction: row;
        margin: 0 auto;

        @media only screen and (min-width: 1px) and (max-width: 510px) {
          flex-direction: column;
          height: 340px;
          width: 350px;

          & > div:nth-child(1) {
            order: 2;
            width: 50%;
            height: 65px;
            padding: 0;
          }

          & > div:nth-child(2) {
            order: 1;
          }

          & > div:nth-child(3) {
            order: 3;
            width: 50%;
            height: 65px;
            padding: 0;
            margin-top: -65px;
            margin-left: auto;
          }

          & > .navigation > .label-mobile {
            font-size: 12px;
            display: block;

            &:nth-child(1) {
              padding-right: 6px;
            }

            &:nth-child(2) {
              padding-left: 6px;
            }
          }
        }

        @media only screen and (min-width: 511px) and (max-width: 750px) {
          width: calc(350px + (2 * 56px));
          height: 290px;
        }

        @media only screen and (min-width: 751px) and (max-width: 1100px) {
          width: calc(586px + (2 * 56px));
        }

        @media only screen and (min-width: 1101px) {
          width: calc(820px + (2 * 56px));
        }

        & > .navigation {
          display: flex;
          width: 56px;
          align-items: center;
          padding-bottom: 40px;

          @media only screen and (min-width: 511px) {
            & > .label-mobile {
              display: none;
            }
          }

          &:last-child {
            justify-content: flex-end;
          }
        }

        & > .content-price-matrix-container {
          display: flex;
          flex: 1;
        }

        & > .content-bar-chart-container {
          font-size: 14px;
          & .previous,
          & .next {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #ffffff;

            &:hover {
              cursor: pointer;
              background-color: #b3d6e5;
            }

            &:active {
              background-color: #d8edf9;
            }

            & > .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 12px;
              height: 12px;
              background-color: #00a1de;
              mask-size: contain;
            }
          }

          & .previous > .icon {
            -webkit-mask: url("../../assets/icons/klm/chevron-left-S.svg")
              no-repeat center;
          }

          & .next > .icon {
            -webkit-mask: url("../../assets/icons/klm/chevron-right-S.svg")
              no-repeat center;
          }

          & .disabled {
            background-color: #b5b6b5;

            &:hover {
              cursor: default;
              background-color: #b5b6b5;
            }

            & > .icon {
              background-color: #5e605f;
            }
          }
        }

        & > .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 8px;
          margin: 0 auto;
          overflow: hidden;

          @media only screen and (min-width: 1px) and (max-width: 510px) {
            width: 350px;
          }

          @media only screen and (min-width: 511px) and (max-width: 750px) {
            width: 350px;
          }

          @media only screen and (min-width: 751px) and (max-width: 1100px) {
            width: 586px;
          }

          @media only screen and (min-width: 1101px) {
            width: 820px;
          }

          & > .bar-chart-element {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 110px;
            text-align: center;
            white-space: nowrap;

            &:hover {
              cursor: pointer;

              & > .bar-section {
                background-color: #007cab;
              }

              & > .button-section {
                & > .checkbox-container {
                  border: 1px solid #c2deea;
                }
              }
            }

            & > .bar-section {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              width: 110px;
              padding-bottom: 16px;
              color: #ffffff;
              background-color: #00a1de;
              border-radius: 4px;

              & > div {
                margin: 2px auto;
              }

              & > .price {
                font-size: 24px;
              }

              & > .separator {
                margin-top: 10px;
                margin-bottom: 5px;
                padding: 0;
                width: 20px;
                height: 1px;
                background-color: #ffffff;
              }
            }

            & > .button-section {
              width: 110px;
              border: 1px solid transparent;
              border-radius: 4px;

              & > div {
                margin: 3px auto;
              }

              & > .checkbox-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                margin: 0 auto;
                border-radius: 50%;
                border: 1px solid transparent;

                & > .checkbox {
                  width: 16px;
                  height: 16px;
                  background-color: transparent;
                  border-radius: 50%;
                }
              }

              & > .active {
                border: 1px solid #00a1de;

                & > .checkbox {
                  background-color: #00a1de;
                }
              }

              & > .inactive {
                border: 1px solid #7c7f7d;

                & > .checkbox {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  .price-overview-mode-switch-button {
    margin-left: auto;
    margin-bottom: 24px;
    display: flex;
    padding: 8px 15px;
    gap: 8px;
    align-items: center;
    cursor: pointer;
  }
}
