.theme-icons-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 8px;
    position: relative;
    top: -6px;
}

.theme-icon-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
