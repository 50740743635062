@import "../../assets/scss/common/breakpoints.scss";

.price-matrix-wrapper {
  margin-top: 40px;

  .price-matrix-sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price-matrix-date-range-heading {
      text-align: center;

      @include responsive(SM) {
        text-align: left;
      }
    }

    .price-matrix-date-navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      .date-navigation__arrow-prev,
      .date-navigation__arrow-next {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        box-sizing: border-box;
        cursor: pointer;

        img {
          display: block;
          width: 6px;
          height: 100%;
          margin: auto;
          user-select: none;
          -webkit-user-drag: none;
        }
      }
    }
  }

  .price-matrix-table-container {
    max-height: 360px;
    width: 100%;
    position: relative;

    .price-matrix-table__duration-column {
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      height: 100%;
      filter: drop-shadow(8px 0px 8px rgba(0, 0, 0, 0.2));

      .duration-column__arrow-prev,
      .duration-column__arrow-next {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        box-sizing: border-box;

        img {
          display: block;
          width: 10px;
          height: 100%;
          margin: auto;
          cursor: pointer;
          user-select: none;
          -webkit-user-drag: none;
        }
      }

      .duration-column__arrow-prev {
        top: 42px;
      }

      .duration-column__arrow-next {
        bottom: -16px;
      }
    }

    .price-matrix-table__dates-row {
      display: flex;
      width: fit-content;
    }

    .price-matrix-table {
      overflow-x: scroll;
      margin-left: 120px;

      .price-matrix-table__row {
        display: flex;
        width: fit-content;
      }
    }

    .price-matrix-table__duration-cell {
      width: 120px;
    }

    .price-matrix-table__cell,
    .price-matrix-table__date-cell {
      width: 92px;
    }
    .price-matrix-table__cell,
    .price-matrix-table__duration-cell,
    .price-matrix-table__date-cell {
      height: 60px;
      align-content: center;
      text-align: center;

      &:not(.cell-disabled) {
        cursor: pointer;
      }
    }
  }

  .price-matrix-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  .price-matrix-color-specification {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    @include responsive(SM) {
      margin-bottom: 0;
    }

    .color-specification__color-sample {
      border-radius: 100%;
      width: 16px;
      height: 16px;
    }

    .color-specification__color-meaning {
      margin-left: 8px;
      font-size: 12px;

      @include responsive(SM) {
        font-size: 16px;
      }
    }
  }
}
