@import "src/assets/scss/common/breakpoints.scss";
.voucher-code-component {
  & p {
    margin-bottom: 12px;
  }
  & .input-component {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    line-height: 48px;
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
    padding: 0 16px;
    background-color: white;

    @include responsive(XXS) {
      width: 100%;
    }
    @include responsive(SM) {
      width: 390px;
    }

    input {
      border: none;
      outline: none;
      flex-grow: 1;
      font-size: 16px;
    }

    &:focus {
      outline: none !important;
    }
  }

  & .voucher-button {
    height: 40px;
    line-height: 20px;
    font-size: 16px;
    padding: 0 16px;

    @include responsive(XXS) {
      margin-top: 8px;
    }
    @include responsive(SM) {
      margin-top: 16px;
    }
  }

  & .voucher-code-result {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
  }

  & .voucher-code-result {
    @include responsive(XXS) {
      width: 100%;
      font-size: 14px;
    }
    @include responsive(SM) {
      width: 390px;
      font-size: 16px;
    }
    & .voucher-code-success {
      line-height: 40px;
      height: 40px;
      display: flex;
      justify-content: space-between;

      @include responsive(XXS) {
        margin-bottom: 8px;
      }
      @include responsive(SM) {
        margin-bottom: 0px;
      }

      & .voucher-code-description {
        display: flex;
        margin: auto 0;

        & .success-icon {
          & svg {
            margin: 6px 10px auto 10px;
          }
          & svg path {
            width: 18px;
            height: 16px;
          }
        }
        & .success-description {
          line-height: 40px;
        }
      }

      & .voucher-code-remove {
        line-height: 16px;
        margin: auto 0;
        & svg {
          height: 18px;
          margin: 0 5px;
        }
        & svg path {
          width: 10px;
          height: 10px;
        }
      }
    }
    & .voucher-code-error {
      line-height: 40px;
      height: 40px;
      display: flex;
      justify-content: space-between;

      @include responsive(XXS) {
        margin-bottom: 8px;
      }
      @include responsive(SM) {
        margin-bottom: 0px;
      }

      & .voucher-code-description {
        display: flex;
        margin: auto 0;

        & .error-icon {
          line-height: 16px;
          & svg {
            margin: 0 10px;
          }
          & svg path {
            height: 20px;
            width: 20px;
          }
        }
        & .error-description {
          line-height: 28px;
        }
      }

      & .voucher-code-remove {
        line-height: 16px;
        margin: auto 0;
        vertical-align: baseline;
        & svg {
          margin: 0 5px;
          height: 18px;
        }
        & svg path {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
