@import "../klm/klm-fonts.scss";

// KLM Desktop - Heading 1
@mixin klm-desktop-heading-1-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 60px;
    line-height: 64px;
    color: #003145;
}

@mixin klm-desktop-heading-1-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 60px;
    line-height: 64px;
    color: #003145;
}

@mixin klm-desktop-heading-1-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 60px;
    line-height: 64px;
    color: #003145;
}


// KLM Desktop - Heading 2
@mixin klm-desktop-heading-2-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 48px;
    line-height: 48px;
    color: #003145;
}

@mixin klm-desktop-heading-2-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 48px;
    line-height: 48px;
    color: #003145;
}

@mixin klm-desktop-heading-2-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 48px;
    line-height: 48px;
    color: #003145;
}


// KLM Desktop - Heading 3
@mixin klm-desktop-heading-3-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 24px;
    line-height: 32px;
    color: #003145;
}

@mixin klm-desktop-heading-3-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 24px;
    line-height: 32px;
    color: #003145;
}

@mixin klm-desktop-heading-3-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 24px;
    line-height: 32px;
    color: #003145;
}


// KLM Desktop - Heading 4
@mixin klm-desktop-heading-4-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 18px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-desktop-heading-4-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 18px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-desktop-heading-4-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 18px;
    line-height: 24px;
    color: #003145;
}


// KLM Desktop - Heading 5
@mixin klm-desktop-heading-5-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 16px;
    line-height: 24px;
}

@mixin klm-desktop-heading-5-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 16px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-desktop-heading-5-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 16px;
    line-height: 24px;
    color: #003145;
}


// KLM Desktop - Heading 6
@mixin klm-desktop-heading-6-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 14px;
    line-height: 16px;
    // color: #003145;
}

@mixin klm-desktop-heading-6-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 14px;
    line-height: 16px;
    // color: #003145;
}

@mixin klm-desktop-heading-6-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 14px;
    line-height: 16px;
    color: #003145;
}


// KLM Desktop - Body - Roboto 16px
@mixin klm-desktop-body-roboto-16 {
    font-family: "UniversalSans-Text-Regular";
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.46px;
    color: #003145;
}


// KLM Desktop - Body - Roboto 14px
@mixin klm-desktop-body-roboto-14 {
    font-family: "UniversalSans-Text-Regular";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #003145;
}


// KLM Mobile - Heading 1
@mixin klm-mobile-heading-1-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 24px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-mobile-heading-1-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 24px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-mobile-heading-1-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 24px;
    line-height: 24px;
    color: #003145;
}


// KLM Mobile - Heading 2
@mixin klm-mobile-heading-2-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 22px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-mobile-heading-2-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 22px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-mobile-heading-2-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 22px;
    line-height: 24px;
    color: #003145;
}


// KLM Mobile - Heading 3
@mixin klm-mobile-heading-3-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 18px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-mobile-heading-3-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 18px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-mobile-heading-3-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 18px;
    line-height: 24px;
    color: #003145;
}


// KLM Mobile - Heading 4
@mixin klm-mobile-heading-4-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 16px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-mobile-heading-4-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 16px;
    line-height: 24px;
    color: #003145;
}

@mixin klm-mobile-heading-4-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 16px;
    line-height: 24px;
    color: #003145;
}


// KLM Mobile - Heading 5
@mixin klm-mobile-heading-5-UniversalSans-Display-Medium {
    font-family: "UniversalSans-Display-Medium";
    font-size: 14px;
    line-height: 20px;
    color: #003145;
}

@mixin klm-mobile-heading-5-UniversalSans-Display-Regular {
    font-family: "UniversalSans-Display-Regular";
    font-size: 14px;
    line-height: 20px;
    color: #003145;
}

@mixin klm-mobile-heading-5-UniversalSans-Display-Light {
    font-family: "UniversalSans-Display-Light";
    font-size: 14px;
    line-height: 20px;
    color: #003145;
}


// KLM Mobile - Body
@mixin klm-mobile-body {
    font-family: "UniversalSans-Text-Regular";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.46px;
    color: #003145;
}