@import "../../assets/scss/common/breakpoints.scss";

.holiday-package-flight-filters-container {
    width: 100%;

    & .holiday-package-flight-filters {
        width: 100%;

        & .mobile-filters-visibility-trigger {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 40px;
            line-height: 40px;

            & .filters-selector {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 10px;
                background-color: white;
                border: 1px solid #C2DEEA;
                border-radius: 4px;

                @include responsive(XXS) {
                    width: 100%;
                }

                @include responsive(SM) {
                    width: 375px;
                }

                & .filters-icon {
                    width: 24px;
                    height: 24px;
                    margin-right: 3px;
                    background-color: #00A1DE;
                    -webkit-mask: url("../../assets/icons/klm/ic_filter.svg") no-repeat center;
                }

                & .filters-text {
                    font-size: 16px;
                    color: #003245;
                }

                & .filters-counter-container {
                    position: relative;
                    width: 16px;
                    height: 16px;
                    margin-left: auto;
                    background-color: #FFFFFF;
                    border: 1px solid #C2DEEA;
                    border-radius: 50%;

                    & .filters-counter {
                        position: absolute;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        font-size: 12px;
                        color: #00A1DE;
                    }

                    &.active {
                        background-color: #00A1DE;
                        border: 1px solid #00A1DE;
                        & .filters-counter {
                            color: #FFFFFF;
                        }
                    }
                }

                &:hover {
                    cursor: pointer;
                    background-color: #e8f4fb;
                }
            }
        }

        & .selected-filters-summary {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 14px 0;
            font-size: 16px;
            color: #000000;

            @include responsive(XXS) {
                top: 0;
                width: 100%;
            }

            @include responsive(SM) {
                width: max-content;
            }

            @include responsive(XL) {
                top: 0;
            }

            & .button-clear-all-filters {
                padding-left: 40px;
                color: #00A1DE;
                
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        & .desktop-filters-elements {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & .desktop-flight-filter {
                position: relative;
                width: 32%;

                & .header {
                    font-size: 16px;
                    line-height: 24px;
                    color: #202020;
                }

                & .dropdown-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 14px;
                    background-color: white;
                    border: 1px solid #C2DEEA;
                    border-radius: 4px;

                    &:hover {
                        cursor: pointer;
                        border: 2px solid #7C7F7D;
                    }
                    &:focus,
                    &:active {
                        border: 2px solid #00A1DE;
                    }
                    &.active {
                        border: 2px solid #00A1DE;
                    }

                    & .icon {
                        width: 16px;
                        height: 16px;
                        background-color: #00A1DE;
                    }

                    & .outbound-flight {
                        -webkit-mask: url("../../assets/icons/klm/origin-S.svg") no-repeat center;
                        mask-size: contain;
                    }
        
                    & .inbound-flight {
                        -webkit-mask: url("../../assets/icons/klm/destination-S.svg") no-repeat center;
                        mask-size: contain;
                    }
        
                    & .number-of-stops {
                        -webkit-mask: url("../../assets/icons/klm/ic_pin_drop.svg") no-repeat center;
                        mask-size: contain;
                    }

                    & .text {
                        flex-grow: 1;
                        padding: 0 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #003245;
                    }

                    & .placeholder {
                        color: #7C7F7D;
                    }

                    & .caret-down,
                    & .caret-up {
                        width: 12px;
                        height: 12px;
                        background-color: #00A1DE;
                    }

                    & .caret-down {
                        -webkit-mask: url("../../assets/icons/klm/ic_arrow_drop_down.svg") no-repeat center;
                    }

                    & .caret-up {
                        -webkit-mask: url("../../assets/icons/klm/ic_arrow_drop_up.svg") no-repeat center;
                    }
                }

                & .dropdown-content {
                    position: absolute;
                    top: 64px;
                    z-index: 1;
                    width: 100%;
                    padding: 6px 14px 20px 14px;
                    background-color: white;
                    box-shadow: 0px 12px 32px 0px #00000014;
                    cursor: auto;
                }
            }

            & .filter-option-checkbox-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: fit-content;
                margin-top: 14px;
                font-size: 16px;

                &:hover {
                    cursor: pointer;
                }

                &>div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 100%;
                }

                &>.checkbox-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                    border-radius: 2px;
                }

                &>.active {
                    &>.checkbox {
                        width: 12px;
                        height: 12px;
                        background-color: white;
                        -webkit-mask: url("../../assets/icons/klm/ic_check.svg") no-repeat center;
                        mask-size: contain;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:focus,
                    &:active {
                        border: 1px solid white;
                    }
                }

                &>.inactive {
                    &>.checkbox {
                        background-color: transparent;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }

                &>.text-container {
                    height: 100%;
                    line-height: 24px;

                    & span:nth-child(2) {
                        padding-left: 5px;
                    }

                    & span:nth-child(4) {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
            }

            & .filter-option-radiobutton-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: fit-content;
                margin-top: 14px;
                font-size: 16px;
        
                &:hover {
                    cursor: pointer;
                }
        
                &>.radiobutton-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                    border: 1px solid #7C7F7D;
                    border-radius: 50%;
        
                    &>.radiobutton {
                        width: 10px;
                        height: 10px;
                        background-color: white;
                        border-radius: 50%;
                    }
                }
        
                & > .active {
                    border: 1px solid #00A1DE;
        
                    &>.radiobutton {
                        background-color: #00A1DE;
                    }
                }
        
                & > .inactive {
                    border: 1px solid #7C7F7D;
        
                    &>.radiobutton {
                        background-color: white;
                    }
                }
        
                &>.text-container {
                    height: 100%;
                    line-height: 24px;
                }
            }
        }
    }
}

.mobile-filters-modal {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 10;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    backdrop-filter: blur(3px);
    background-color: #00070D1A;

    & * {
        box-sizing: border-box;
    } 

    & .content {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: calc(100% + 72px);
        background-color: #FFFFFF;

        @include responsive(XXS) {
            top: 0;
            width: 100%;
        }
    
        @include responsive(SM) {
            width: 375px;
        }
    
        @include responsive(XL) {
            top: 0;
        }
    }

    & .mobile-filters-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 72px;
        padding: 0 16px;
        background-color: white;

        @include responsive(XXS) {
            position: sticky;
            top: 0;
            width: 100%;
            z-index: 11;
        }

        @include responsive(SM) {
            width: 375px;
        }

        @include responsive(XL) {
            position: inherit;
            width: inherit;
            z-index: inherit;
        }

        & .button {
            height: 40px;
        }

        &.hidden {
            display: none;
        }

        &.visible {
            display: flex;
        }
    }

    & .mobile-filters-header {
        margin: 30px 0;
        padding: 0 14px;
        color: #000000;

        &>div:nth-child(1) {
            font-size: 24px;
            line-height: 34px;
        }

        & .selected-filters-summary {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 16px;
            line-height: 22px;
        }

        & .button-clear-all-filters {
            color: #00A1DE;
                
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    & .mobile-filters-selector {
        padding: 0 14px;
    }

    & .filter-option-checkbox-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: fit-content;
        margin-top: 8px;
        font-size: 16px;

        &:hover {
            cursor: pointer;
        }

        &>div {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
        }

        &>.checkbox-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            border-radius: 4px;
        }

        &>.active {
            &>.checkbox {
                background-color: white;
                width: 17px;
                height: 17px;
                mask-size: contain;
            }

            &:hover {
                cursor: pointer;
            }

            &:focus,
            &:active {
                border: 1px solid white;
            }
        }

        &>.inactive {
            &>.checkbox {
                background-color: transparent;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &>.text-container {
            height: 100%;
            line-height: 24px;

            & span:nth-child(2) {
                padding-left: 5px;
            }
            & span:nth-child(4) {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    & .filter-option-radiobutton-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: fit-content;
        margin-top: 8px;
        font-size: 16px;
        line-height: 24px;

        &:hover {
            cursor: pointer;
        }

        &>.radiobutton-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            border: 1px solid #7C7F7D;
            border-radius: 50%;

            &>.radiobutton {
                width: 16px;
                height: 16px;
                background-color: white;
                border-radius: 50%;
            }
        }

        & > .active {
            border: 1px solid #00A1DE;

            &>.radiobutton {
                background-color: #00A1DE;;
            }
        }

        & > .inactive {
            border: 1px solid #7C7F7D;

            &>.radiobutton {
                background-color: white;
            }
        }

        &>.text-container {
            height: 100%;
            line-height: 24px;
        }
    }

    & .flight-filter-type {
        padding: 25px 0;
        border-top: 1px solid #CDE4EE;

        & .flight-filter-header {
            display: flex;
            flex-direction: row;
            height: 16px;
            line-height: 16px;
            margin-bottom: 25px;
            font-size: 18px;

            & .filter-header-icon {
                width: 16px;
                height: 16px;
                margin-right: 8px;
                background-color: #00A1DE;
            }

            & .outbound-flight {
                -webkit-mask: url("../../assets/icons/klm/origin-S.svg") no-repeat center;
                mask-size: contain;
            }

            & .inbound-flight {
                -webkit-mask: url("../../assets/icons/klm/destination-S.svg") no-repeat center;
                mask-size: contain;
            }

            & .number-of-stops {
                -webkit-mask: url("../../assets/icons/klm/ic_pin_drop.svg") no-repeat center;
                mask-size: contain;
            }
        }
    }

    & .flight-filter-type:last-child {
        border-bottom: 1px solid #CDE4EE;
    }
}