@import "../../../assets/scss/common/breakpoints.scss";

.checkAvailabilityModalContent {
    max-width: 650px;
    color: #003145;

    &>.checkAvailabilityModalContent-header {
        margin-top: 15px;

        @include responsive(XXS) {
            margin-top: 15px;
            font-size: 18px;
        }

        @include responsive(XL) {
            margin-top: 2px;
            font-size: 24px;
        }
    }

    &>.checkAvailabilityModalContent-info {
        margin-top: 15px;

        @include responsive(XXS) {
            font-size: 14px;
            line-height: 20px;
        }

        @include responsive(XL) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &>.checkAvailabilityModalContent-price-change {
        &>div:nth-child(1) {
            margin-top: 17px;
            font-size: 12px;
            color: #001526;
        }

        &>div:nth-child(2) {
            font-size: 18px;
            color: #7C7F7D;
            text-decoration: line-through;
        }

        &>div:nth-child(3) {
            margin-top: 12px;
            font-size: 14px;
            color: #003145
        }

        &>div:nth-child(4) {
            font-size: 32px;
            color: #003245;
        }
    }

    &>.checkAvailabilityModalContent-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 30px;
        font-size: 16px;

        &>div {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 18px;
            height: 40px;
            box-sizing: border-box;
            border-radius: 4px;
            font-size: 16px;
            cursor: pointer;
        }

        &>.continue {
            color: #FFFFFF;
            background-color: #58A618;
        }

        &>.return {
            border: 1px solid grey;
        }

        @include responsive(XXS) {
            display: inline-block;

            &>div:nth-child(2) {
                margin-top: 14px;
            }
        }

        @include responsive(XL) {
            display: flex;
            flex-direction: row;
            gap: 30px;

            &>div:nth-child(2) {
                margin-top: 0;
            }
        }
    }
}