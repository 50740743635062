.flag-small {
  height: 16px;
  width: 22px;
}

.flag-medium {
  width: 2.5rem
}

.flag-large {
  width: 3rem;
}
