@import "src/assets/scss/common/breakpoints.scss";

.traveler-details-component {
  background-color: white;
  padding: 32px;
  margin-bottom: 32px;

  @include responsive(SM) {
    margin: 32px 0;
  }

  & .main-booker-heading-description {
    box-sizing: border-box;
    width: 100%;
  }

  & .traveller-details-component-header {
    margin-bottom: 12px;
    font-size: 18px;
  }

  & .main-booker-heading-description {
    font-size: 16px;

    & .main-booker-data-description {
      margin-bottom: 12px;
    }

    & .main-booker-information-description {
      margin-bottom: 12px;
      font-size: 14px;

      & p {
        margin: 0;
        padding: 0;
      }
    }
  }

  & .person-title {
    line-height: 24px;
  }

  & .MuiFormControlLabel-root {
    margin: 0 12px 0 0;
  }

  & .person-title-form-control {

    & .radio-box-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      border-radius: 50%;

      & > .checkbox {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }
  }

  & .frequent-flyer-checkbox, & .blue-biz-checkbox {
    & .frequent-flyer-label, & .blue-biz-label {
      display: flex;
      margin: 12px 0;

      & .checkbox-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      & .active {
        & .checkbox {
          background-color: white;
          width: 12px;
          height: 12px;
          mask-size: contain;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  & .frequent-flyer-inputs {
    justify-content: space-between;
    margin: 8px 0;
    flex-shrink: 0;
    flex-grow: 1;

    @include responsive(SM) {
      display: flex;
    }

    & .frequent-flyer-society {
      margin-right: 16px;
    }

    & .frequent-flyer-card-number {
      @include responsive(MD) {
        margin-top: 24px;
      }

      @include responsive(LG) {
        margin-top: 0;
      }
    }

    & .frequent-flyer-society, & .frequent-flyer-card-number {
      @include responsive(XXS) {
        display: block;
        width: calc(100% - 12px);
      }
      @include responsive(SM) {
        width: 392px;
      }
      @include responsive(MD) {
        width: calc(50% - 12px);
      }
    }
  }
}

