@import "src/assets/scss/common/breakpoints.scss";

.stepper-bar-component {
  box-sizing: border-box;
  background-color: white;
  position: relative;

  @include responsive(XXS) {
    height: 48px;
    line-height: 48px;
    font-size: 8px;
    margin-top: 12px;
    padding: 0 6px;
  }

  @include responsive(XS) {
    height: 65px;
    line-height: 65px;
    font-size: 10px;
  }
  @include responsive(SM) {
    height: 65px;
    line-height: 65px;
    font-size: 12px;
  }

  @include responsive(MD) {
    height: 80px;
    line-height: 80px;
    font-size: 16px;
  }

  & .MuiStepConnector-line {
    border-top-width: 1px;
    border-radius: 5px;

    @media (max-width: 768px) {
      position: relative;
    }
  }

  & .MuiStep-horizontal {
    box-sizing: border-box;
    flex-direction: column;
    padding: 0;

    @include responsive(XXS) {
      margin: 8px;
      &:first-child {
        margin: 0 8px 0 0;
      }
    }
    @include responsive(XS) {
      margin: 20px 12px;

      &:first-child {
        margin: 0 12px 0 0;
      }
    }
    @include responsive(SM) {
      margin: 12px;
    }

    @include responsive(MD) {
      margin: 0 12px;
      flex-direction: row;
      top: 12px;

      &:first-child {
        margin: 0 12px 0 0;
      }
    }

    & .step-description {
      box-sizing: border-box;

      @media (max-width: 768px) {
        position: absolute;
        top: 14px;
      }

      @include responsive(XS) {
        top: 18px;
      }

      @include responsive(SM) {
        top: 16px;
      }

      @include responsive(MD) {
        margin-left: 8px;
        top: 16px;
      }
    }
  }

  & .stepper {
    cursor: pointer;
    box-sizing: border-box;
    @include page-container-responsive();
  }

  & img {
    @include responsive(XXS) {
      height: 16px;
    }

    @include responsive(XS) {
      height: 18px;
    }

    @include responsive(MD) {
      height: 20px;
    }

    @include responsive(SM) {
      height: 24px;
    }
  }
}
