@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors";

.pagination-component {
  display: flex;
  list-style-type: none;
  margin: 12px auto 0 auto;

  & ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    & li {
      margin: 0 10px;
      line-height: 40px;
    }
  }

  .pagination-selected {
    font-weight: bold;
  }
}

.pagination-number {
  background-color: transparent;
  border: 2px solid transparent;
  font-size: 16px;
  line-height: 40px;
}

.pagination-component {
  box-sizing: border-box;

  @include responsive(XS) {
    display: flex;
    font-size: 16px;
  }

  .pagination-first-page-button {
    width: 40px;
    height: 40px;
    border: none;
    margin-right: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  .pagination-first-page-button-icon {
    box-sizing: border-box;
    padding: 0;
    margin: 0 auto;
    border: none;
    height: 12px;
  }

  .pagination-previous-button {
    border: none;
    width: 40px;
    height: 40px;

    &:hover {
      cursor: pointer;
    }
  }

  .pagination-previous-button-icon {
    box-sizing: border-box;
    height: 12px;
  }

  .pagination-next-button {
    border: none;
    width: 40px;
    height: 40px;

    &:hover {
      cursor: pointer;
    }
  }

  .pagination-next-button-icon {
    box-sizing: border-box;
    height: 12px;
  }

  .pagination-last-page-button {
    width: 40px;
    height: 40px;
    border: none;
    margin-left: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  .pagination-last-page-button-icon {
    box-sizing: border-box;
    padding: 0;
    margin: 0 auto;
    border: none;
    width: 12px;
    height: 12px;
  }

  .pagination-numbers {
      margin: auto 13px auto 12px;

    .pagination-number {
      &:hover {
        cursor: pointer;
      }

      &:nth-child(2) {
        @include responsive(XXS) {
          padding: 0 8px;
        }
        @include responsive(SM) {
          padding: 0 22px;
        }
      }
    }
  }
}
