@import "../../../assets/scss/common/breakpoints.scss";

.side-filter-bar-modal {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }

  @include responsive(XXS) {
    top: 0;
    width: 100%;
  }

  @include responsive(SM) {
    width: 375px;
  }

  @include responsive(XL) {
    top: 0;
  }

  .filters-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 72px;
    padding: 0 16px;
    background-color: white;

    &>.button:nth-child(1) {
      height: 40px;
    }

    &>.button:nth-child(2) {
      height: 40px;
    }

    &.hidden {
      display: none;
    }

    &.visible {
      display: flex;
    }

    @include responsive(XXS) {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 11;
    }

    @include responsive(SM) {
      width: 375px;
    }

    @include responsive(XL) {
      position: inherit;
      width: inherit;
      z-index: inherit;
    }
  }

  .filter-bar-modal-component {
    align-items: center;
    margin: auto 0;
  }
}

#overlay-root {
  background-color: white;
  z-index: 10;
  width: 100%;
}