@import "src/assets/scss/common/breakpoints.scss";

.meal-type-filter {
  @include page-container-responsive();
  margin: 40px auto 0;

  .section-header {
    margin-bottom: 28px;

    @include responsive(XXS) {
      font-size: 24px;
    }

    @include responsive(SM) {
      font-size: 48px;
    }
  }

  .meal-type-filter__meal-types {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .meal-type-filter-option {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px 24px;
      box-sizing: border-box;

      @include responsive(SM) {
        flex-direction: row;
      }

      .meal-type-filter-option__type-info {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include responsive(SM) {
          flex-grow: 1;
          width: auto;
        }

        .type-info__wrapper {
          display: flex;
          align-items: center;

          .type-info__type-icon {
            margin-right: 8px;
            width: 24px;
            height: 24px;

            @include responsive(SM) {
              margin-right: 16px;
            }
          }

          .type-info__name {
          }
        }

        .type-info__additional-price {
          margin-right: 16px;
          margin-right: 0;
          margin-left: auto;
        }
      }

      .select__button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        height: 40px;
        padding: 8px 15px;
        font-size: 16px;
        cursor: pointer;
        margin-top: 22px;
        box-sizing: border-box;
        width: 100%;

        @include responsive(SM) {
          margin-left: 16px;
          margin-top: 0;
          width: 188px;
        }
      }

      &.option-selected {
        .meal-type-filter-option__selected-indicator {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 22px;
          font-size: 20px;
          height: 40px;

          @include responsive(SM) {
            margin-top: 0;
          }

          .selected-indicator__icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
