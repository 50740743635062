@import "src/assets/scss/common/breakpoints.scss";

.car-item-opacity-50 {
  opacity: 0.5;
}

.car-item-component {
  margin-top: 16px;

  & .car-item-main-container {

    @include responsive(XL) {
      display: flex;
      flex-direction: row;
    }

    & .car-image-details-wrapper {
      display: flex;
      flex-grow: 1;

      @include responsive(XXS) {
        flex-direction: column;
      }

      @include responsive(SM) {
        flex-direction: row;
      }

      @include responsive(MD) {
        flex-direction: column;
      }

      @include responsive(LG) {
        flex-direction: row;
      }

      & .car-item-image {
        margin: auto;

        & img {
          display: flex;
          margin: 0 auto;
          width: 206px;
          height: 114px;
          margin: 26px 24px 0 26px;
        }

        & .car-general-information {
          display: flex;
          justify-content: center;

          @include responsive(XXS) {
            padding: 16px 0 0 0;
          }

          @include responsive(LG) {
            padding: 16px 0 24px;
          }

          & .number-of-baggage,
          & .number-of-passengers {
            margin-right: 24px;

            svg {
              height: 16px;
              width: 16px;
              margin-right: 8px;
            }
          }

          & .number-of-doors {
            height: 16px;

            svg {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }
        }
      }

      & .car-item-details {
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        @include responsive(XXS) {
          padding: 0 24px 24px 24px;
        }

        @include responsive(LG) {
          flex-direction: column;
          width: 206px;
          padding: 0;
        }

        & .car-item-title {
          line-height: 24px;
          font-size: 16px;
          padding: 18px 0;

          & .car-model {
            margin-right: 5px;
            display: inline-block;
          }
          & .car-model-description {
            display: inline-block;
          }
        }

        & .section-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-weight: bold;
          margin-bottom: 12px;
          gap: 12px;

          & > div {
            width: 50%;
          }
        }
        & .characteristics-section,
        & .services-section,
        & .pick-up-section,
        & .drop-off-section {
          & .characteristics-item,
          & .service-item,
          & .pick-up-item,
          & .drop-off-item {
            font-weight: normal;
          }
        }

        & .services-section, 
        & .characteristics-section,
        & .pick-up-item,
        & .drop-off-item
         {
          & svg {
            height: 20px;
            width: 20px;
            margin-right: 8px;
            position: relative;
            top: 4px;
          }
        }
      }
    }

    & .car-item-action {
      display: flex;
      justify-content: center;

      & .additional-cost-container {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        box-sizing: border-box;

        @include responsive(XXS) {
          flex-direction: column;
          width: 100%;
          padding: 16px;
        }

        @media (min-width: 520px) {
          flex-direction: row;
          padding: 16px 16px 16px 16px;
        }

        @include responsive(SM) {
          padding: 16px;
        }

        @include responsive(MD) {
          padding: 20px;
          gap: 16px;
        }

        @include responsive(XL) {
          flex-direction: column;
          width: 206px;
          padding: 16px;
        }

        & .card-container {
          font-family: UniversalSans-Text-Light;
          font-size: 12px;

          @media (min-width: 576px) and (max-width: 620px) {
            width: 240px;
          }

          @include responsive(XXS) {
            margin: auto 0;
          }
          @include responsive(LG) {
            margin: 0;
          }

          & .card-container-wrapper {
            letter-spacing: 0.17px;
            line-height: 20px;
            display: flex;
            flex-direction: row;

            & .disclaimers {
              display: flex;
              flex-direction: column;

              & .view-all-conditons {
                border: none;
                background-color: transparent;
                cursor: pointer;
              }
            }
          
            & svg {
              position: relative;
              height: 12px;
              top: 2px;
              margin-right: 8px;

              & path {
                width: 16px;
                height: 12px;
              }
            }
          }
        }

        & .select-car-container {

          @include responsive(SM) {
            display: flex;
            flex-direction: row;
            gap: 8px;
            margin: auto;
          }

          @media (min-width: 576px) and (max-width: 769px) {
            display: flex;
            flex-direction: row;
            gap: 16px;
            justify-content: space-around;
            margin-right: 32px;
          }

          @include responsive(LG) {
            display: flex;
            flex-direction: row;
            gap: 0;
            flex: 1;
            justify-content: space-between;
          }

          @include responsive(XL) {
            justify-content: end;
            flex-direction: column;
          }

          & .car-price-block {
            white-space: nowrap;

            @media (max-width: 576px) {
              margin-top: 16px;
            }

            & .price {
              font-size: 24px;
            }

            & .total-description {
              font-size: 12px;
              line-height: 16px;
            }
          }

          & .car-select-button {
            height: 40px;
            line-height: 20px;
            font-size: 16px;
            padding: 0 16px;
            min-width: 130px;

            @include responsive(XXS) {
              width: 100%;
            }

            @media (min-width: 576px) and (max-width: 769px) {
              max-width: 150px;
              justify-content: space-around;
            }

            @include responsive(LG) {
              width: 160px;
            }

            & .spinner-button-wrapper {
              width: 100%;
              justify-content: center;
              font-size: 16px;
              & .spinner-button {
                  display: flex;
                  justify-content: center;
    
                  & .button-loading-spinner {
                      position: relative;
                      top: 6px;
                  }
                  & .loading-text {
                      padding-left: 16px;
                  }
              }
            }
          }
        }

        & .car-container-selected {
          display: flex;
          flex-grow: 1;

          & svg {
            height: 20px;
            width: 20px;
            margin-right: 8px;
            position: relative;
          }

          @include responsive(XXS) {
            gap: 16px;
            flex-direction: column;
            justify-content: center;
            margin: 16px auto 0 auto;
          }

          @include responsive(SM) {
            margin: auto;
          }

          @media (min-width: 520px) and (max-width: 772px) {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
          }

          @media (min-width: 772px) and (max-width: 996px) {
            display: flex;
            flex-direction: row;
            gap: 24px;
            justify-content: right;
          }

          @include responsive(LG) {
            display: flex;
            gap: 24px;
            flex-direction: row;
            justify-content: space-around;
          }

          @include responsive(XL) {
            display: flex;
            gap: 0;
            justify-content: space-around;
            flex-direction: column;
            justify-content: flex-end;
          }

          & .car-price-block {
            & .selected {
              display: flex;
              justify-content: center;
              cursor: pointer;

              @media (min-width: 992px) and (max-width: 1200px) {
                margin: 12px;
              }
              &svg {
                position: relative;
                top: 2px;
                width: 16px;
                height: 16px;
                padding-right: 8px;
              }
              & .car-selected {
                font-size: 18px;
                line-height: 24px;
              }
            }
          }

          & .car-delete {
            display: flex;
            font-weight: bold;
            text-align: center;
            cursor: pointer;

            & .spinner-button-wrapper {
              width: 100%;
              justify-content: center;
              font-size: 16px;
              position: relative;
              width: 8px;
              height: 8px;
              margin-right: 12px;
              top: 2px;
      
              & .spinner-button {
                width: 8px;
                height: 8px;
                & svg {
                  width: 8px;
                  height: 8px;
                  margin-right: 0;
                }
                  justify-content: center;
                  & .button-loading-spinner {
                      position: relative;
                  }
              }
            }

            @include responsive(XXS) {
              margin: 0 auto;
            }

            @media (min-width: 520px) and (max-width: 992px) {
              margin: 0;
              line-height: 24px;
            }

            @include responsive(LG) {
              margin-top: 14px;
              margin-bottom: 14px;
            }
          }
        }
      }

      & .custom-details-button {
        display: inline-flex;
        vertical-align: text-bottom;
      }
    }
  }
}

.car-condition-modal-body {
  max-height: 65vh;
  overflow-y: auto;
  margin-top: 24px;
  text-align: left;

  & .condition-section {
    & .condition-section-title{
      font-size: 18px;
    }
  }

  & ul {
    margin-top: 16px;

    & li {
      letter-spacing: 0.46px;
      margin-right: 16px;
    }
    & li::marker {
      font-size: 20px;
    }
  }
}



