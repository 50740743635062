@import "../../common//colours.scss";

.waiting-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.waiting-modal-animation {
  width: 72%;
}

.container-logo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  .word-icon {
    height: 100px;
    width: 100px;
  }
  .modal-icon {
    height: 60px;
    width: auto;
  }
}

.waiting-logo {
  width: 180px;
}
