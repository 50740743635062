@import "src/assets/scss/common/breakpoints.scss";

.country-label-component {
  & .country-label-input {
    @include responsive(XXS) {
      margin: 8px 0;
      display: block;
      width: calc(100% - 12px);
    }
    @include responsive(SM) {
      width: 392px;
    }
  }
}
