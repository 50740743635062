@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/common/breakpoints.scss";

.empty-state-info-component {
  display: flex;
  flex-direction: column;

  @include responsive(XXS) {
    margin-bottom: 12px;
  }
  
  @include responsive(LG) {
    margin-top: 0;
    height: 182px;
  }

  & .empty-state-close-button {
    cursor: pointer;
    justify-content: right;
    text-align: right;
    border: none;
    background-color: transparent;
    justify-content: flex-start;

    @include responsive(XXS) {
      margin-top: 8px;
      margin-right: 8px;
    }

    @include responsive(MD) {
        margin-top: 12px;
        margin-right: 12px;
    }
  }

  & .empty-state-content {
    & .empty-state-title {
      letter-spacing: 0;
      text-align: center;
      margin: 0 32px;

      @include responsive(XXS) {
        font-size: 18px;
        line-height: 24px;
      }

      @include responsive(SM) {
        font-size: 24px;
        line-height: 32px;
      }

      @include responsive(LG) {
        font-size: 32px;
        line-height: 48px;
      }
    }

    & .empty-state-description {
      font-size: 16px;
      letter-spacing: 0.46px;
      line-height: 24px;
      text-align: center;
      padding: 0 8px;
    }
  }
}
