@import "src/assets/scss/common/breakpoints.scss";

.departure-input {
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & .input-placeholder {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

    &:hover {
      font-weight: bold;
    }
  }
}

.departure-dropdown-component {
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  -webkit-tap-highlight-color: transparent;
  top: 0;

  & .departure-dropdown-container {
    cursor: pointer;
    border: 1px solid transparent;
    width: 356px;
    background-color: white;
    position: absolute;
    top: 12px;
    left: -14px;
    padding: 16px 0;

    @media only screen and (min-width: 1px) and (max-width: 576px) {
      position: fixed;
      left: 0;
      width: 100vw;
      height: 100vh;
      padding-right: 8px;
      overflow: auto;
      background-color: white;

      & .departure-options-container {
        position: relative;
        top: 52px;
      }

      @include responsive(SM) {
        height: 0;
        display: none;
        opacity: 0;
      }
    }
  }

  & .departure-row-content {
    display: flex;
    height: 44px;

    @include responsive(XXS) {
      width: 100vw;
    }
    @include responsive(SM) {
      width: 100%;
    }

    & .selected-row {
      line-height: 44px;
      margin: 0 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.departure-button-container {
  @media (max-width: 576px) {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 62px;
    padding: 8px;
  }

  @include responsive(SM) {
    display: none;
  }

  & > .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 18px;
    font-size: 16px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}
