@import "../../assets/scss/common/colors.scss";

.tripadvisor-rating-component {
    display: flex;
    flex-direction: row;

    & * {
        box-sizing: border-box;
    }

    & > div {
        width: 14px;
        height: 14px;
        margin-right: 3px;
        border-radius: 50%;
    }

    & .tripadvisor-filled-icon {
        background-color: $tripadvisor-green;
        border: 1px solid $tripadvisor-green;
    }

    & .tripadvisor-half-filled-icon {
        background: linear-gradient(to right, $tripadvisor-green 50%, transparent 50%) no-repeat;
        border: 1px solid $tripadvisor-green;
    }

    & .tripadvisor-empty-icon {
        border: 1px solid $tripadvisor-green;
    }
}
