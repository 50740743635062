.price-filter-component-container {

  & .filter-options-container {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }

  & .MuiSlider-root {
    width: calc(100% - 35px);
  }
}

.price-box {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}