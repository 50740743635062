@import "src/assets/scss/common/breakpoints.scss";

.price-datepicker {
  border-color: transparent;
  padding: 16px;
  border-radius: 0;
  width: 100%;

  @media (max-width: 576px) {
    top: 48px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 48px);
  }

  @include responsive(SM) {
    width: 356px;
    height: 1000px;
  }

  @include responsive(MD) {
    top: 6px;
  }

  @include responsive(LG) {
    display: flex;
    top: 8px;
    width: 656px;
    height: 564px;
    position: relative;
  }
}

.price-shift-picker {
  @media (max-width: 576px) {
    margin-top: 100px;
    top: 128px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 236px);
  }
  @include responsive(SM) {
    margin-top: 130px;
  }
  @include responsive(LG) {
    margin-top: 154px;
  }
}
