@import "src/assets/scss/klm/klm-colors";
@import "src/assets/scss/common/breakpoints.scss";

.custom-modal-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 90px auto;
  padding: 30px;
  width: 800px;
  max-height: 75vh;
  background-color: #ffffff;
  border-radius: 12px;

  @include responsive(XXS) {
    width: 344px;
    padding: 18px;
  }

  @include responsive(XL) {
    width: 800px;
    padding: 30px;
  }

  & .custom-modal-close {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    & .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #8f8f8f;
      -webkit-mask: url("../../assets/icons/common/close.svg") no-repeat center;
      mask-size: contain;
      cursor: pointer;

      @include responsive(XXS) {
        width: 18px;
        height: 18px;
      }

      @include responsive(XL) {
        width: 25px;
        height: 25px;
      }
    }
  }

  & .modal-body {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    outline: none;
    border: none;

    & .car-condition-modal-body::-webkit-scrollbar {
      width: 12px;
    }

    & .car-condition-modal-body::-webkit-scrollbar-track {
      background: white;
      border-radius: 6px;
    }

    & .car-condition-modal-body::-webkit-scrollbar-thumb {
      background: $klm-grey-500;
      border-radius: 6px;
    }

    & .car-condition-modal-body::-webkit-scrollbar-button {
      display: none;
    }

    body::-webkit-scrollbar-thumb {
      background-color: blue;
      border-radius: 20px;
      border: 3px solid orange;
    }
  }

  & .custom-modal-header {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 32px;
    color: $klm-dark-blue;
    font-family: UniversalSans-Display-Medium;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
  }

  & .modal-warning-icon {
    color: $klm-orange-500;
    margin-right: 12px;
    font-size: 20px;
  }

  & .modal-content {
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 0;
  }

  & .modal-buttons {
    display: flex;
    justify-content: space-between;
  }

  & .modal-button {
    margin-left: 14px;
    margin-right: 14px;
  }

  & .modal-button-cancel {
    background-color: #ffffff;
  }

  & .modal-button-confirm {
    background-color: $klm-orange-500;
    border-radius: 3px;
    color: #ffffff;
  }
}
