.price-matrix-header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
}

.price-matrix-header-month-navigation-button {
  display: flex;
  height: 24px;
  align-self: center;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
}

.price-matrix-header-month-button {
  flex-grow: 1;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
  cursor: pointer;
  width: 110px;
}

@media (max-width: 500px) {
  .price-matrix-header-month-button {
    width: 90px;
  }
}

@media (max-width: 320px) {
  .price-matrix-header-month-button {
    width: 70px;
  }
}
