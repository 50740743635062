$primary-blue: #00A1DE;
$klm-blue: #23a1dc;
$klm-blue-light: #BAE0F3;
$klm-light-blue-500: #C2DEEA;
$klm-grey-100: #EDEEEE;
$klm-grey-200: #D1D2D1;
$klm-grey-400: #909090;
$banner-blue: #c4dee9;
$border-black: #003145;
$light-text-black: #395f6f;
$light-text: #003145;
$klm-black: #003145;
$background-grey: #e0e6e0;
$background-dark-grey: #BDBFBF;
$background-lavendar: #f7f9f9;
$action-orange: #db6e21;
$white: #ffffff;
$tripadvisor-green: #05AA6C;


:export {
  $primary-blue: #00A1DE;
  $klm-blue: #23a1dc;
  $klm-blue-light: #BAE0F3;
  $klm-light-blue-500: #C2DEEA;
  $klm-grey-100: #EDEEEE;
  $klm-grey-200: #D1D2D1;
  $klm-grey-400: #909090;
  $banner-blue: #c4dee9;
  $border-black: #003145;
  $light-text-black: #395f6f;
  $light-text: #003145;
  $klm-black: #003145;
  $background-grey: #e0e6e0;
  $background-dark-grey: #BDBFBF;
  $background-lavendar: #f7f9f9;
  $action-orange: #db6e21;
  $white: #ffffff;
  $tripadvisor-green: #00AF87; 
}
