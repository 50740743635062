@import "src/assets/scss/common/breakpoints.scss";

.phone-number-component {
  display: block;
  flex-direction: column;
  margin: 8px 0;
  @include responsive(XXS) {
    width: calc(100% - 12px);
  }
  @include responsive(SM) {
    width: 392px;
  }

  & .phone-number-wrapper {
    display: flex;
    width: 100%;

    & .country-code {
      width: 88px;
      margin-right: 16px;
    }

    & .phone-number {
      display: block;
      flex-grow: 1;
      @include responsive(XXS) {
        position: relative;
        margin-bottom: 0;
        line-height: 100%;
        align-self: end;
      }
      @include responsive(SM) {
      }
    }
  }
}
