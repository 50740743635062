@import "../../assets/scss/common/breakpoints.scss";

.room-details-modal-wrapper {
  width: calc(100% - 16px);
  border-radius: 12px;
  margin: 32px 8px;
  box-sizing: border-box;

  @include responsive(SM) {
    width: 640px;
    margin: 32px auto;
  }

  .room-details-modal {
    height: 100%;
    max-height: calc(100vh - 64px);

    .room-details-modal__header {
      display: flex;
      justify-content: start;
      padding-left: 16px;
      align-items: center;
      height: 66px;
      position: relative;

      @include responsive(SM) {
        padding-left: 0;
        justify-content: center;
      }

      .header__close-btn {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 24px;
        top: auto;
        bottom: auto;
        left: auto;
        cursor: pointer;
      }
    }

    .uncategorized-section {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 15px;
      .amenities-category-option {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .category-icon svg {
      height: 16px;
      width: 16px;
  }

    .categories-section {
      margin-top: 24px;
      margin-top: 24px;

      .categories-columns {
        display: grid;
        gap: 10px; 
        @include responsive(XXS) {
          grid-template-columns: repeat(1, 1fr); 
        }
        @include responsive(SM) {
          grid-template-columns: repeat(2, 1fr); 
        }
      }
    
      .category-item {
        display: flex;
        flex-direction: column;
      }
      .category-title {
        display: flex;
        gap: 6px;
        align-items: flex-start;
        & .category-name {
          margin-top: -3px;
        }
      }
      & ul.category-description {
        margin-top: 10px;
      }
    }

    .amenities-category-option svg {
      height: 16px;
      width: 16px;
  }

    .body__bottom-quote {
      & p {
        font-size: 14px;
      }
    }

    .room-details-modal__body {
      height: calc(100% - 82px);
      padding: 18px;
      box-sizing: border-box;
      overflow-y: scroll;

      .body__room-header {
        margin-top: 16px;
      }

      .body__bottom-quote {
        margin-top: 14px;
      }

      .hotel-flipper-image-parent {
        height: 200px;

        @include responsive(SM) {
          height: 360px;
        }
      }
    }
  }
}
