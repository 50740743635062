.filters-overview-component {
  display: block;
  width: 100%;

  & .filters-overview-component-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;

    & * {
      box-sizing: border-box;
    }

    & .filters-overview {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      max-width: 164px;
      height: 40px;
      line-height: 40px;
      background-color: white;

      & .filters-overview-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 0 10px;
        color: lightblue;
        background-color: white;

        & .filters-icon {
          width: 24px;
          height: 24px;
          margin-right: 3px;
          background-color: lightblue;
          -webkit-mask: url("../../../assets/icons/klm/ic_filter.svg") no-repeat center;
        }

        & .filters-counter-container {
          position: relative;
          width: 16px;
          height: 16px;
          margin-left: auto;
          background-color: lightblue;
          border-radius: 50%;

          & .filters-counter {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-top: 2px;
            width: 100%;
            height: 100%;
            font-size: 85%;
            color: white;
          }
        }
      }
    }

    & .filters-overview-map-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 50%;
      max-width: 164px;
      height: 40px;
      line-height: 40px;
      padding-right: 5px;
      color: lightblue;
      background-color: white;
      border: 1px solid lightblue;
      border-radius: 4px;

      .map-icon {
        display: flex;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        background-color: lightblue;
        -webkit-mask: url("../../../assets/icons/klm/ic_map.svg") no-repeat center;
      }
    }
  }
}