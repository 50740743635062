@import "src/assets/scss/common/breakpoints.scss";

.accommodation-option-group {
  width: 100%;
  padding-top: 16px;
  position: relative;

  &.option-group-loading {
    opacity: 0.5;
  }

  &:not(:first-of-type) {
    margin-top: 16px;
  }

  & > .accommodation-option-group__header {
    cursor: pointer;
    margin-bottom: 16px;

    & > .accommodation-option__selected-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;

      @include responsive(SM) {
        flex-direction: row;
      }

      & > .accommodation-option__room-info {
        display: flex;
        align-items: center;
        width: 100%;

        @include responsive(SM) {
          width: auto;
          flex-grow: 1;
        }

        & > .room-info__room-image {
          width: 87px;
          height: 64px;
          object-fit: cover;
        }

        & > .room-info__room-description {
          display: flex;
          flex-direction: column;
          justify-content: start;
          margin-left: 12px;

          & > .room-description__room-type-wrapper {
            display: flex;
            gap: 4px;
            max-width: 226px;

            @include responsive(SM) {
              max-width: max-content;
            }

            & > .room-description__room-type {
              text-transform: capitalize;
            }
          }

          & > .room-description__room-details {
            margin-top: 4px;
          }
        }
      }

      & > .accommodation-option-group__price-selected-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @include responsive(SM) {
          flex-direction: row;
          width: auto;
        }

        & > .accommodation-option-group__price {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          margin-top: 8px;

          @include responsive(SM) {
            width: auto;
          }

          @include responsive(MD) {
            margin-top: 0;
            flex-direction: column;
          }

          & > .additional-cost {
            font-size: 24px;

            @include responsive(XXS) {
              margin: 0;
            }

            @include responsive(MD) {
              margin: 0 0 0 auto;
            }

            span {
              font-size: 12px;
            }

            & > .price-sign {
              font-size: 24px;
              font-weight: 300;
            }

            & > .price-per-person {
              font-size: 12px;
            }
          }
        }

        & > .accommodation-option-group__selected-btn {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 40px;
          width: 100%;

          @include responsive(SM) {
            width: auto;
          }

          .status__accordion-icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            margin-top: 27px;

            @include responsive(SM) {
              position: inherit;
              width: 40px;
              height: 40px;
              margin-top: 0;
            }
          }

          .status__selected {
            margin: 24px auto 10px;
            font-size: 20px;
          }
        }
      }
    }
  }
}
