@import "../../assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors.scss";

.passenger-details-page-component {
  @include page-container-responsive();
  justify-content: center;
  margin: 0 auto;
}

.passenger-details-trip-summary {
  display: flex;
  width: 45%;
  background-color: white;
  border-radius: 6px;
  margin-top: 20px;
  margin-right: 10%;
}

.action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-arrow-back {
  display: flex;
  align-items: center;
}

.button.button-accept {
  margin: 0;
}
