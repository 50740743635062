@import "src/assets/scss/klm/klm-colors.scss";
@import "../../../assets/scss/klm/klm-fonts";
@import "src/assets/scss/common/breakpoints.scss";

.holiday-summary-flight-overview-component {
  padding: 0 0 32px 32px;
  @include responsive(XS) {
    padding: 0 32px 32px 32px;
  }
  @include responsive(LG) {
    padding: 0 0px 32px 32px;
  }
}

.flight-overview-container,
.flight-overview-container-box-header,
.flight-overview-container-box-content,
.flight-overview-container-box-baggage-info,
.flight-overview-container-box-baggage-info-handbagage,
.flight-overview-container-box-baggage-info-briefcase {
  display: flex;
  p {
    margin: 0;
  }
}

.holiday-summary-flight-overview-component-title {
  font-size: 18px;
  line-height: 24px;
  color: $klm-black;
  font-family: UniversalSans-Display-Medium;
}

.flight-overview-container-box-header {
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
  p {
    font-size: 18px;
    line-height: 24px;
    color: $klm-dark-blue-2;
    font-family: UniversalSans-Display-Medium;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $klm-light-blue-2-50;
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
}

.flight-overview-container-box-left {
  margin-right: 20px;
}

.flight-overview-container-box-content-hours {
  margin-top: -2px;
  .flight-overview-container-box-content-hours-start,
  .flight-overview-container-box-content-hours-end {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: UniversalSans-Text-Bold;
    line-height: 16px;
    color: $klm-dark-blue-2;
    @media (min-width: 575px) and (max-width: 640px) {
      font-size: 13px;
      line-height: 18px;
    }

    &>.flight-overview-container-box-content-date-start,
    .flight-overview-container-box-content-date-end {
      font-family: UniversalSans-Text-Light;
      font-size: 14px;
    }
  }
}

.flight-overview-container-box-content-hours {
  p {
    padding: 0 6px 14px 7px;
  }
}

.flight-overview-container-box-content-direction {
  p {
    padding: 0 22px 27px 7px;
  }
}

.flight-overview-container-box-content-image {
  height: 24px;
  width: 24px;
  margin-top: -7px;
}

.flight-overview-container-box-content-direct-hours {
  font-size: 16px;
  font-family: UniversalSans-Text-Light;
  line-height: 20px;
  color: $klm-dark-blue-2;
}

.flight-overview-container-box-baggage-info {
  flex-direction: column;
  gap: 10px;

    & .hand-luggage-included {
      width: 9px;
      height: 16px;
      mask-size: contain;
    }

    & .hand-luggage-excluded {
      display: flex;
      width: 119px;
      height: 16px;
      mask-size: contain;
    }


  &-briefcase {
    align-items: center;
      &.flight-overview-container-box-baggage-info-briefcase {
        align-items: flex-start;

        & .baggage {
          & .baggage-included {
            width: 14px;
            height: 14px;
            margin: 0 7px 3px 0;
            background-color: #00a1de;
            -webkit-mask: url("../../../assets/icons/klm/icon-briefcase-included.svg")
              no-repeat left;
            mask-size: contain;
          }
      
          & .baggage-excluded {
            width: 14px;
            height: 14px;
            margin: 0 7px 3px 0;
            background-color: #989b99;
            -webkit-mask: url("../../../assets/icons/klm/icon-briefcase-excluded.svg")
              no-repeat left;
            mask-size: contain;
          }
        }
    }
    p {
      font-size: 16px;
      font-family: UniversalSans-Text-Light;
      line-height: 17px;
      color: $klm-dark-blue-2;
    }
    & span {
      font-size: 16px;
      font-family: UniversalSans-Text-Regular;
      line-height: 17px;
      color: $klm-dark-blue-2;
    }
  }
}
