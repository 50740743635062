@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/klm/klm-buttons";
@import "src/assets/scss/common/breakpoints.scss";

.transfers-component {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  & .option-no-available {
    margin: 0 0 12px 0;
  }

  & .transfer-description {
    
    @include responsive(XXS) {
      margin-bottom: 10px;
    }

    @include responsive(MD) {
      margin-bottom: 18px;
    }
  }

  & .svg-icon {
    width: 16px;
    height: 16px;
  }

  & .transfers-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  
    & .icon-with-description {
      align-self: center;
      display: inline-block;
      font-size: 14px;
      
      & .icon {
        position: relative;
        top: 4px;
        margin: 0 8px;
      }

    }
  }

  & .transfer-select-button {
    font-size: 16px;
    @include button-secondary();
    height: 40px;
  }

  & .transfer-option-selected {
    display: flex;
    gap: 12px;

    @include responsive(XXS) {
      flex-direction: column;
      height: 100%;
    }
    @include responsive(SM) {
      flex-direction: row;
      height: 40px;
    }
    @include responsive(XL) {
      flex-direction: column;
      height: 100%;
    }
  }
}
