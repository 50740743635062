@import "../../../../assets/scss/common/breakpoints.scss";
@import "../../../../assets/scss/klm/klm-colors.scss";

.datepicker-dropdown-container {
  & .datepicker-input-dropdown-component {
    display: flex;
    font-size: 16px;
    position: relative;
    height: 64px;

    @media (max-width: 360px) {
      width: 250px;
    }
    @include responsive(XS) {
      width: 344px;
    }
    @include responsive(SM) {
      width: 314px;
    }
    @include responsive(LG) {
      width: 344px;
    }

    & .input-placeholder-text {
      position: absolute;
      box-sizing: border-box;
      width: calc(100% - 8px);
      outline: none;
      border: none;
      background-color: transparent;
      font-size: 16px;
      font-family: UniversalSans-Text-Light;
    }

    & label {
      display: block;
      flex-wrap: nowrap;
      flex-direction: column;
    }

    & .label-description {
      position: relative;
      cursor: pointer;

      & .datepicker-input-dropdown {
        position: relative;
        display: flex;
        box-sizing: border-box;
        line-height: 4px;
        height: 40px;
        border-radius: 4px;

        & .input-placeholder {
          margin: auto 20px;
          border: none;
          white-space: nowrap;
          overflow: hidden;
          line-height: 100%;
          outline: none;
          width: 100%;
        }

        & .icon-arrow {
          position: absolute;
          width: 10px;
          height: 5px;
          bottom: 18px;

          @include responsive(XXS) {
            right: 8px;
          }

          @media (min-width: 448px) {
            right: 20px;
          }

          & > .icon-drop-down {
            width: 100%;
            height: 100%;
            mask-size: auto;
          }

          & > .icon-drop-up {
            width: 100%;
            height: 100%;
            mask-size: auto;
          }
        }

        & ul {
          padding: 0;
        }

        & .dropdown {
          position: absolute;
          box-sizing: border-box;
          top: 24px;
          z-index: 10;
          min-width: 100%;
          max-height: 200px;
          overflow-y: auto;
          overflow-x: hidden;
          background-color: white;

          & .dropdown-item-wrapper {
            display: flex;

            & .dropdown-item-spacer {
              display: flex;
              height: 40px;
            }

            & .dropdown-item {
              display: flex;
              padding-left: 16px;
              padding-top: 16px;
              list-style-type: none;
              flex-shrink: 0;
              flex-grow: 1;
            }

            & .dropdown-item-selected {
              display: flex;
              padding-left: 16px;
              padding-top: 16px;
              list-style-type: none;
              flex-shrink: 0;
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}
