@import "src/assets/scss/klm/klm-colors.scss";
@import "../../assets/scss/klm/klm-fonts";
@import "src/assets/scss/common/breakpoints.scss";

.flights-arrivals-container {
  display: flex;
  gap: 16px;

  @include responsive(XXS) {
    flex-direction: column;
  }

  @include responsive(XL) {
    flex-direction: row;
  }
}

.flight-container {
  max-height: 243px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $klm-white-50;

  &:has(.expanded-info) {
    max-height: 800px;
  }

  &.mobile-line-container {
    margin-left: 18px;
  }
}

.info-container {
  display: flex;
  margin: 0 23px;

  @include responsive(XXS) {
    justify-content: space-between;
    align-items: center;
    margin-left: 23px;

    .middle-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: auto;
    }
    
    .mobile-info-right {
      margin-left: 20px;

      .payment-flight-info {
        margin: 30px 0px;
      }
    }

    .right-info {
      margin-bottom: auto;
      margin-left: auto;
    }
  }

  @include responsive(SM) {
    justify-content: center;
    align-items: normal;

    & .mobile-info-left {
      & p {
        padding: 4px;
      }
    }

    & .mobile-info-right {
      margin-left: 0px;
      padding: 0;
    }

    & .left-info,
    & .right-info {
      width: calc((100% - 130px) / 2);
    }

    & .middle-info {
      width: 100px;
      margin: 0 15px;
    }

    & .flight-date,
    & .flight-time,
    & .flight-airport {
      height: 24px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    & .airline-logo-container,
    & .flight-connector-container {
      width: 100%;
      height: 24px;
      line-height: 24px;
      text-align: center;
    }
  
    & .duration-info-container {
      min-height: 48px;
  
      & div {
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        line-height: 20px;
      }

      & div:nth-child(1) {
        margin-top: 2px;
      }
    }
  }

  .mobile-info {
    display: flex;
  }
}

.middle-info img {
  height: 24px;
  width: 24px;
  margin-top: 4px;
}

.more-info-container {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.expanded-connector {
  display: flex;

  .connector-time {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 26px;
    margin-left: 8px;

    p {
      margin: 0;
    }
  }
}

.expanded-info {
  display: flex;
}

.expanded-flight {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .expanded-flight-container {
    width: 100%;
  }

  & .expanded-icons {
    display: flex;
    flex-direction: column;

    .airport {
      margin: 0;
    }

    span {
      margin-left: 9px;
    }

    img {
      width: 10px;
      height: 10px;
    }
  }

  .expanded-data {
    display: flex;
    flex-direction: column;
    gap: 15px;

    p {
      margin: 0;
    }
  }
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

@include responsive(XXS) {
  .data-container {
    gap: 8px;
  }

  .logo-container {
    display: none;
  }
}

@include responsive(SM) {
  .data-container {
    display: flex;

    & .second-part-data {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .logo-container {
    position: absolute;
    right: 0;
    display: block;
    margin-left: auto;
    padding-right: 23px;

    img {
      width: 24px;
    }
  }
}

@include responsive(MD) {
  &.holiday-flight {
    .flight-destination {
      margin-top: 60px;
    }

    .expanded-info {
      justify-content: normal;
    }

    .logo-container {
      display: none;
    }
  }
}

@include responsive(LG) {
  .data-container {
    gap: 16px;
  }
}

@include responsive(XL) {
  .data-container {
    display: flex;
    flex-direction: column;
  }

  .expanded-icons {
    span.airport:first-of-type {
      margin-bottom: 10px;
    }

    span.airport:last-of-type {
      margin-top: 10px;
    }
  }

  .flight-details {
    .logo-container {
      display: none;
    }

    &.holiday-flight {
      margin-bottom: 40px;

      .logo-container {
        display: block;
        padding-right: 0px;

        img {
          height: 24px;
        }
      }

      .flight-destination {
        margin-top: 60px;
      }
    }
  }
}

.checkout-component {
  @include responsive(XXS) {
    .flight-details {
      margin-left: 16px;
    }
  }
}

.more-info-container .expanded-flight {
  @include responsive(XXS) {
    width: 100%;
  }
}

.company-logo {
  height: 24px;
  width: 24px;
}