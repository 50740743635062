$primary-blue: #00A1DE;
$klm-blue: #23a1dc;
$klm-blue-light: #BAE0F3;
$klm-blue-300: #3BC9FF;
$klm-light-blue-500: #C2DEEA;
$klm-light-blue-900: #176188;
$klm-dark-blue-500: #003F72;
$klm-grey-100: #EDEEEE;
$klm-grey-200: #D1D2D1;
$klm-grey-400: #909090;
$klm-grey-500: #7C7F7D;
$banner-blue: #c4dee9;
$border-black: #003145;
$light-text-black: #395f6f;
$light-text: #003145;
$klm-black: #003145;
$background-grey: #e0e6e0;
$background-dark-grey: #BDBFBF;
$background-lavendar: #f7f9f9;
$action-orange: #db6e21;
$white: #ffffff;
$tripadvisor-green: #00AF87;
$star-colour: #fccc30;
$transavia-black: #202020;
$disabled-grey: #e6e6e6;
$disabled-text: #8f8f8f;

:export {
  primary-blue: $primary-blue;
  action-orange: $action-orange;
  background-dark-grey: $background-dark-grey;
}
