@import "../../assets/scss/common/breakpoints.scss";

:root {
  --image-container-width: 0;
  --control-prev-position: 70px;
  --control-next-position: 20px;
}

.holiday-package-hotel-images-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 16px auto;

  @include responsive(XXS) {
    width: calc(100% - 32px);
  }

  @include responsive(MD) {
    width: 768px;
  }

  @include responsive(LG) {
    width: 944px;
  }

  @include responsive(XL) {
    width: 1152px;
  }

  @include responsive(XXL) {
    width: 1224px;
  }

  & * {
    box-sizing: border-box;
  }

  & > .hotel-info {
    border-radius: 4px 4px 0 0;
    background-color: #ffffff;

    @include responsive(XXS) {
      flex-direction: column;
    }

    @include responsive(SM) {
      height: 120px;
    }

    & > .header {
      @include responsive(XXS) {
        margin: 14px 0 0 14px;
        font-size: 18px;
      }

      @include responsive(SM) {
        height: 24px;
        line-height: 24px;
        margin-top: 24px;
        margin-left: 24px;
        font-size: 24px;
      }
    }

    & > .content {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      @include responsive(XXS) {
        flex-direction: column;
        margin: 24px 0 0 14px;
      }

      @include responsive(XS) {
        margin: 16px 0 0 14px;
      }
      @include responsive(SM) {
        flex-direction: row;
        align-items: center;
        margin: 34px 0 0 24px;
        height: 16px;
        line-height: 16px;
      }

      & > .rating {
        display: flex;
        flex-direction: row;
        height: 16px;

        & > .star-rating-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 11px;
          border-right: 1px solid #b3d6e5;

          & > .star-rating-component > div {
            margin: -1px 5px 0 0;
            width: 10px;
            height: 10px;
          }
        }

        & > .tripadvisor-rating-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 16px;

          & > .tripadvisor-logo-container {
            background-color: #000000;
            width: 24px;
            height: 12px;
            margin-right: 3px;
            -webkit-mask: url("../../assets/icons/common/tripadvisor-logo.svg")
              no-repeat center;
            mask-size: contain;
          }

          & > .tripadvisor-rating-component > div {
            width: 11px;
            height: 11px;
          }
        }
      }

      & > .location {
        display: flex;
        flex-direction: row;
        height: 16px;
        line-height: 16px;

        @include responsive(XXS) {
          margin: 24px 0;
        }
        @include responsive(XS) {
          margin: 16px 0;
        }

        @include responsive(SM) {
          margin: 0;
          padding-left: 16px;
          border-left: 1px solid #b3d6e5;
        }

        & > .flag-container {
          display: flex;
          align-items: center;

          & img {
            height: 14px;
          }
        }

        & > .text-container {
          padding: 0 6px;
          font-size: 16px;
          letter-spacing: 0.46px;
          color: #003145;
        }
      }
    }
  }

  & > .hotel-images {
    display: flex;
    gap: 6px;
    flex-direction: row;
    max-width: 100%;

    @include responsive(XXS) {
      aspect-ratio: auto;
      height: 180px;
    }
    @include responsive(MD) {
      aspect-ratio: 3 / 1;
      height: auto;
    }

    & div {
      border-radius: 2px;
    }

    & > .main {
      aspect-ratio: 16 / 9;
      overflow: hidden;
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.5);

      &:hover {
        cursor: pointer;

        img {
          transform: scale(1.1);
          transition: transform 0.3s;
        }
      }

      @include responsive(XXS) {
        width: 62%;
      }

      @include responsive(SM) {
        width: 56%;
      }
    }

    & > .tiles {
      display: flex;
      gap: 6px;

      @include responsive(XXS) {
        flex-direction: column;
        flex-wrap: nowrap;
        width: 40%;
      }

      @include responsive(SM) {
        flex-direction: row;
        flex-wrap: wrap;
        width: 46%;
      }

      & > .tile {
        width: auto;
        height: calc(50% - 6px);
        overflow: hidden;
        flex: 1 1 33%;

        &:hover {
          cursor: pointer;

          img {
            transform: scale(1.1);
            transition: transform 0.3s;
          }

          & > .text {
            text-decoration: underline;
          }
        }
      }

      & > .tile:nth-child(1),
      & > .tile:nth-child(2) {
        @include responsive(XXS) {
          display: none;
        }

        @include responsive(SM) {
          display: block;
        }
      }

      & > .tile:nth-child(3) {
        @include responsive(XXS) {
          padding-top: 0;
          height: calc(50% - 3px);
        }

        @include responsive(SM) {
          height: 50%;
        }
      }

      & > .tile:nth-child(4) {
        position: relative;

        @include responsive(XXS) {
          height: calc(50% - 3px);
        }

        @include responsive(SM) {
          margin-top: 0;
          height: 50%;
        }

        & > .overlay {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #000000;
          opacity: 50%;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
        }

        & > .text {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          width: calc(100% - 6px);
          text-align: center;
          color: #ffffff;
        }
      }
    }

    & img {
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;
      border-radius: 2px;
      object-fit: cover;
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.5);
    }
  }
}

.close-carousel-btn {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  cursor: pointer;

  @include responsive(XXS) {
    height: 48px;
    width: 48px;
  }

  @include responsive(LG) {
    height: 72px;
    width: 72px;
  }
}

.full-screen-carousel {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  text-decoration: none;
  transition: text-decoration 0.3s;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.carousel-root {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 7;
  height: calc(100vh - 40px);
  width: 100%;
  padding: 0;

  @include responsive(XXS) {
    top: 40px;
  }

  @include responsive(LG) {
    top: 50px;
  }

  .control-dots {
    display: none;
  }

  & .carousel:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 180px);

    & .slider-wrapper {
      height: 100%;

      & .slider {
        height: 100%;

        & .slide {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          & > div {
            position: relative;

            @include responsive(XXS) {
              max-width: 100%;
              max-height: 100%;
            }

            @include responsive(MD) {
              max-width: calc(100% - 160px);
              max-height: 100%;
            }

            & img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }

  .carousel-status {
    display: none;
  }

  .thumbs-wrapper {
    margin: 0 5px 0 0;

    ul.thumbs.animated {
      padding: 0;
    }

    .thumb {
      height: 100px;
      border: none;
      overflow: hidden;
      padding: 0;

      &:hover {
        border: none;

        img {
          transform: scale(1.1);
          transition: transform 0.3s;
        }
      }
    }

    .control-next.control-arrow,
    .control-prev.control-arrow {
      display: none;
      -webkit-appearance: none; /* Safari */
      appearance: none;
    }
  }

  .control-next.control-arrow,
  .control-prev.control-arrow {
    position: absolute;
    top: 50%;
    background-color: #fff;
    border-radius: 50%;
    opacity: 1 !important;
    z-index: 2;

    @include responsive(XXS) {
      height: 32px;
      width: 32px;
    }

    @include responsive(XS) {
      height: 40px;
      width: 40px;
    }

    @include responsive(SM) {
      height: 50px;
      width: 50px;
    }
  }

  .control-next.control-arrow {
    @include responsive(XXS) {
      transform: translate(-14px, -50%);
    }

    @include responsive(MD) {
      right: var(--control-next-position);
      transform: translate(16px, -50%);
    }
  }

  .control-prev.control-arrow {
    @include responsive(XXS) {
      transform: translate(14px, -50%);
    }

    @include responsive(MD) {
      left: var(--control-prev-position);
      transform: translate(calc(-100% - 16px), -50%);
    }
  }

  .carousel.carousel-slider .control-arrow:hover {
    opacity: 1;
  }

  .control-next.control-arrow:before,
  .control-prev.control-arrow:before {
    display: none;
  }
}
