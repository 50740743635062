@import "./../../assets/scss/klm/klm-colors.scss";
@import "./../../assets/scss//common//breakpoints.scss";

.downpayment-component {
  width: 100%;
  margin: 24px 0;

  & .downpayment-header {
    margin-bottom: 12px;
    width: 100%;
    box-sizing: border-box;
    font-weight: normal;
    line-height: 48px;
    @include responsive(XXS) {
      font-size: 24px;
    }
    @include responsive(SM) {
      margin-bottom: 16px;
      font-size: 48px;
    }
  }

  & .downpayment-container {
    padding: 24px;
    font-size: 16px;
    background-color: white;
    letter-spacing: 0.46px;

    & .downpayment-body {
      margin-bottom: 18px;

      & .content-item-icon {
        & svg {
          margin-left: 4px;
          width: 14px;
          height: 14px;
        }
      }
    }

    & .downpayment-option {
      display: flex;
      line-height: 24px;
      margin-top: 12px;

      & .downpayment-selected {
        & div {
          display: inline;
        }

        & .downpayment-price {
          margin: 0 4px;
          font-weight: bold;
          white-space: normal;
          overflow-wrap: break-word;
          word-break: break-word;
        }
      }

      & .checkbox-wrapper {
        height: 16px;
        margin-right: 8px;

        & > .checkbox-container {
          margin-top: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
          height: 16px;
          margin-right: 8px;
          border-radius: 50%;

          & > .checkbox {
            box-sizing: content-box;
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }
        }
        & .inactive {
          border: 1px solid lightgray;

          & > .checkbox {
            background-color: transparent;
          }
        }
      }
    }
  }
}