@import "src/assets/scss/common/breakpoints.scss";
.email-input-component {

  & .email-container {
    @include responsive(XXS) {
      display: block;
      width: calc(100% - 12px);
    }
    @include responsive(SM) {
      width: 392px;
    }
  }
}
