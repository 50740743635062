@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors.scss";

.address-details-input-component {
  position: relative;

  .error-label {
    color: $klm-error-label;
  }

  & .address-details-container {

    @include responsive(LG) {
      display: inline-flex;
    }

    & .street-name {
      @include responsive(XXS) {
        width: calc(100% - 6px);
      }
      @include responsive(SM) {
        width: 392px;
      }

      @include responsive(LG) {
        flex-direction: row;
        margin-right: 8px;
      }
    }

    & .extended-address-wrapper {
      display: inline-flex;

      @include responsive(XXS) {
        margin: 8px 8px 0 0;
      }
      @include responsive(LG) {
        margin: 0 8px;
      }
      & .house-number {
        display: flex;
        flex-direction: column;
        white-space: nowrap;

        & input {
          width: 88px;
        }
      }

      & .extension-address {
        display: flex;
        flex-direction: column;
        position: relative;
        white-space: nowrap;

        @include responsive(XXS) {
          margin-left: 12px;
        }
        @include responsive(SM) {
          padding-bottom: 0;
        }
        & input {
          width: 88px;
        }
      }

      & .error-message-placeholder {
        position: absolute;
        white-space: nowrap;
        bottom: 5px;
      }
      & .error-spacer {
        height: 24px;
      }
    }
  }
}
