@font-face {
    font-family: "UniversalSans-Display-Regular";
    src: url("../../fonts/UniversalSans-Display-Regular.ttf")
}

@font-face {
    font-family: "UniversalSans-Display-Light";
    src: url("../../fonts/UniversalSans-Display-Light.ttf")
}

@font-face {
    font-family: "UniversalSans-Display-Medium";
    src: url("../../fonts/UniversalSans-Display-Medium.ttf")
}

@font-face {
    font-family: "UniversalSans-Text-Light";
    src: url("../../fonts/UniversalSans-Text-Light.ttf")
}

@font-face {
    font-family: "UniversalSans-Text-Medium";
    src: url("../../fonts/UniversalSans-Text-Medium.ttf")
}

@font-face {
    font-family: "UniversalSans-Text-Regular";
    src: url("../../fonts/UniversalSans-Text-Regular.ttf")
}

@font-face {
    font-family: "UniversalSans-Text-Bold";
    src: url("../../fonts/UniversalSans-Text-Bold.ttf")
}