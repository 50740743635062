.hotel-flipper-image-parent {
    min-width: 300px;
    height: 300px;
    overflow: hidden;
}

.hotel-flipper-ribbon {
    position: absolute;
    top:0;
    left: 0;
    z-index: 2;
    border-top-left-radius: 4px
}
