@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/common/breakpoints.scss";
@import "./../../common/colours.scss";
@import "src/assets/scss/common/breakpoints";

.cluster-marker {
  border-radius: 50%;
  color: white;
  font-size: 16px;
  text-align: center;

  &:hover {
    opacity: 0.9;
  }
}

.hotel-list-map-component {
  position: relative;
  margin-top: 44px;

  & .gm-style-iw-d {
    overflow: hidden !important;
  }

  & .gm-style-iw-chr {
    position: absolute;
  }

  & .gm-style-iw {
    padding: 0 !important;
    top: -14px;
  }

  & .gm-style-iw-tc::after {
    top: -16px;
    height: 27px !important;
  }

  @media only screen and (min-width: 1px) and (max-width: 576px) {
    flex-direction: column;
    margin-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-right: 8px;
    overflow: auto;
    background-color: white;
    z-index: 100;

    @include responsive(SM) {
      height: 0;
      display: none;
      opacity: 0;
    }
  }
  & .map-container-full-screen-style {
    width: 100%;
    height: 100%;
  }

  & .map-container-mobile-style {
    width: 100%;
    height: calc(100vh - 62px);
    position: absolute;
    top: 62px;
  }

  & .map-container-style {
    width: 100%;
    height: 486px;
    margin-bottom: 16px;
  }

  & .maps-button-container {
    display: flex;
    @media (max-width: 576px) {
      position: absolute;
      z-index: 10;
      top: 0;
      height: 62px;
      width: 100%;
      background-color: white;
    }

    & > .button:nth-child(1) {
      margin: 12px 0 auto 16px;
      height: 40px;
    }
  }

  & .map-container-full-screen-style {
    width: 100%;
    height: 100%;
  }

  & .map-container-mobile-style {
    width: 100%;
    height: calc(100vh - 62px);
    position: absolute;
    top: 62px;
  }

  & .map-container-style {
    width: 100%;
    height: 486px;
    margin-bottom: 16px;
  }

  & .map-button-close {
    top: 10px !important;
  }
}

.infoBox-wrapper {
  overflow: hidden !important;
}

.infoBox {
  height: 266px;
  width: 240px;
  position: relative;
  overflow: hidden !important;
  align-self: center;

  & .arrow-indicator {
    &::before {
      content: "";
      position: absolute;
      -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      bottom: -4px;
      height: 30px;
      width: 25px;
      left: calc(50% - 12.5px);
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      bottom: 0px;
      height: 30px;
      width: 26px;
      left: calc(50% - 13px);
    }
  }
}

.info-marker-container {
  box-sizing: unset;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 240px;
  height: 240px;
  overflow: hidden;

  & img {
    height: 50%;
  }
}

.info-window-content-component {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  

  & .hotel-name-info {
    box-sizing: border-box;
    padding: 0.25rem 1rem;
    position: relative;
    right: 2px;
    text-align: left;
    width: 100%;
  }

  & .ratings-container {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    height: 1rem;
  }

  & .star-rating-container {
    position: relative;
    width: 100px;
    height: 10px;
    display: inline-block;
    margin-left: 0.5rem;

    & svg {
      width: 1rem;
      height: 1rem;
      padding-right: 6px;
    }
  }

  & .trip-advisor-rating-container {
    box-sizing: border-box;
    min-width: 45%;
    display: flex;
    position: relative;
    align-items: center;

    & svg {
      margin-right: 0.25rem;
    }
  }

  & .price-marker-container {
    display: flex;
  }

  & .price-container {
    margin-top: 10px;
  }

  & .holiday-price-wrapper {
    margin: auto;
  }
  & .button-wrapper {
    & > .button-accept {
      height: 40px;
      top: 0;
    }
  }

  & .vertical-spacer {
    width: 1px;
    height: 1rem;
  }

  & .button-wrapper {
    margin: 8px;
    height: 10px;
  }
}

.info-price-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  padding-left: 3px;
  padding-right: 3px;
}

.info-price-description {
  font-size: 12px;
}

.full-screen-map {
  top: 16px;
  right: 16px;
  font-size: 16px;
  z-index: 100;
  height: 32px;
  width: 32px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  position: absolute;

  & .full-screen-map-button {
    border: none;
    display: block;
    margin: 0 auto;
    height: 32px;
    width: 32px;
  }

  & .full-screen-map-button-close {
    border: none;
    display: block;
    margin: 0 auto;
    height: 32px;
    width: 32px;
  }
}

.map-container {
  height: 50vh;
}

.close-icon {
  width: 16px;
  height: 16px;
  margin: auto 8px auto 8px;
}

.map-container-style {
  width: 100%;
  height: 50vh;
  margin-bottom: 16px;
}

.map-container-full-screen-style {
  width: 100%;
  height: 100vh;
}

.map-container-mobile-style {
  width: 100%;
  height: calc(100vh - 62px);
  position: absolute;
  top: 62px;
}
