.star-rating-component {
    display: flex;
    flex-direction: row;

    & > div {
        margin-right: 2px;
    }

    & .star-filled-icon {
        background-color:orange;
        width: 14px;
        height: 14px;
        -webkit-mask: url("../../assets/icons/klm/ic_star_KLM.svg") no-repeat center;
        mask-size:cover;
    }

    & .star-half-filled-icon {
        background: linear-gradient(to right, orange 50%, transparent 50%) no-repeat;
        width: 14px;
        height: 14px;
        -webkit-mask: url("../../assets/icons/klm/ic_star_KLM.svg") no-repeat center;
        mask-size:cover;
    }

    & .star-empty-icon {
        background-color: orange;
        width: 14px;
        height: 14px;
        -webkit-mask: url("../../assets/icons/klm/ic_star_KLM.svg") no-repeat center;
        mask-size:cover;
    }
}