@import "src/assets/scss/transavia/transavia-colors.scss";
@import "src/assets/scss/common/breakpoints.scss";

.car-feature-description-component {

  & .car-features-section {
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    margin-bottom: 16px;
    border-radius: 12px;
    position: relative;

    & .car-company-header {
      display: flex;
      justify-content: space-between;
      font-size: 16px;

      @media (max-width: 993px) {
        width: calc(100% - 86px);
      }

      & p {
        margin-top: 0;
        margin-bottom: 12px;
        padding: 0;
      }

      & img {
        @include responsive(XS) {
          position: absolute;
          top: 16px;
          right: 16px;
        }
      }
    }

    & .usp-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & .usp-item {
        box-sizing: border-box;
        position: relative;
        display: flex;

        @include responsive(XXS) {
          width: 100%;
          margin-top: 8px;
        }

        @include responsive(SM) {
          width: calc(50% - 4px);
        }

        @include responsive(XL) {
          width: calc(33% - 12px);
        }

        & .check-img {
          padding-right: 8px;
          position: absolute;
          width: 16px;
        }

        & .usp-description {
          font-size: 14px;
          margin-left: 24px;
        }
      }
    }
  }

  & .car-feature-heading-description {
    font-size: 36px;
    margin-bottom: 12px;

    & .car-feature-information-description {
      font-size: 14px;

      & p {
        margin: 0;
        padding: 0;
      }
    }
  }
}