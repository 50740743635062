@import "../../assets/scss/common/breakpoints.scss";

.payment-page-component {
  @include page-container-responsive();
  margin: 0 auto;

  & .payment-page-container {
    display: flex;
    position: relative;
    margin: 0 auto;

    @include responsive(XXS) {
      flex-direction: column;
    }
    @include responsive(MD) {
      flex-direction: row;
      gap: 24px;
    }
  }

  & .left-side {
    width: 100%;
    @media (max-width: 576px) {
      margin-top: 92px;
    }
    @include responsive(XXS) {
      display: flex;
      flex-direction: column;
      margin-top: 132px;
    }

    @include responsive(SM) {
      margin-top: 63px;
    }

    @include responsive(MD) {
      display: block;
      margin-top: 105px;
    }

    & .checkout-component {
      margin-top: 16px;
    }
    & .buttons-container {
      display: flex;
      justify-content: space-between;
      margin: 16px 0 96px 0;
      font-size: 16px;

      & .back-button {
        top: 0;
        left: 0;
        padding-right: 16px;

        & svg {
          margin-right: 12px;
          height: 8px;
        }
      }

      & .make-payment-button {
        font-size: 16px;

        @include responsive(XXS) {
          padding: 0 21px;
        }

        @include responsive(LG) {
          padding: 0 48px;
        }
      }
    }
  }

  & .shopping-cart-container {
    @include responsive(XXS) {
      margin-top: 24px;
    }
    @include responsive(MD) {
      margin-top: 110px;
    }
  }

  .adyen-checkout__card__brands {
    height: 100%;

    .adyen-checkout__card__brands__brand-wrapper {
      height: 100%;
      width: 54px;

      & img {
        height:100%;
        width: 100%;
      }

      &::after {
        display: none;
        border: none;
      }
    }
  }
  .box-mainbooker {
    height: 307px;
    width: 488px;
  }
}
