@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/common/breakpoints.scss";

.card-component {
  box-sizing: border-box;
  background-color: white;
  border-radius: 2px;
  display: inline-block;
  justify-content: center;
  display: flex;
  align-content: center;
  justify-content: left;
  width: 100%;
  box-sizing: border-box;

  @include responsive(XXS) {
    flex-direction: column;
  }
  @include responsive(SM) {
    flex-direction: row;
    width: 100%;
  }

  @include responsive(MD) {
    flex-direction: column;
    max-height: 300px;
  }
  @include responsive(LG) {
    flex: 2 1 0;
    max-width: 49%;
  }

  @include responsive(XL) {
    flex-direction: column;
    flex: 3;
    height: 100%;
    max-height: 100%;
    max-width: 33%;
  }

  & .card-content-title {
    & .card-title {
        padding-bottom: 8px;
    }
  }

  & .card-image {
    flex-direction: row;
    object-fit: cover;
    box-sizing: border-box;
    background-color: white;

    & .transfer-image {
      display: flex;
      justify-content: center;
      margin: auto;

      & img {
        padding: 0;
        margin: 0;
      }

      @include responsive(XXS) {
        width: 204px;
        height: 178px;
      }

      @include responsive(XS) {
        height: 148px;
      }

      @include responsive(SM) {
        min-width: 204px;
      }

      @include responsive(MD) {
        min-width: 204px;
        width: 148px;
        height: 148px;
      }

      @include responsive(LG) {
        width: 148px;
        height: 100%;
      }

      @include responsive(XL) {
        width: 148px;
        height: 148px;
      }
    }
  }

  & .card-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: -2px;
    padding: 16px;
    width: calc(100% - 32px);
    gap: 12px;

    @include responsive(XXS) {
      height: 100%;
    }
    @include responsive(SM) {
      height: 132px;
    }

    @include responsive(XL) {
      height: 160px;
    }

    & .card-action {
      box-sizing: border-box;

      & .card-button-container {
        display: flex;
        position: relative;
        justify-content: space-between;
        box-sizing: border-box;
        line-height: 100%;

        @include responsive(XXS) {
          flex-direction: column;
        }
        @include responsive(SM) {
          flex-direction: row;
        }
        @include responsive(XL) {
          flex-direction: column;
        }

        & .custom-card-button {
          display: block;
        }
      }
      & .card-price-block {
        white-space: nowrap;
        width: 100%;
        line-height: 40px;

        & .card-price-block {
          font-size: 24px;
        }

        & .total-description {
          font-size: 16px;
          line-height: 16px;
        }
      }
      & .transfer-select-button {
        display: flex;
        justify-content: center;
        align-items: center;

        @include responsive(XXS) {
          width: 100%;
        }

        @media (min-width: 576px) and (max-width: 998px) {
          max-width: 248px;
        }

        @include responsive(XL) {
          width: 100%;
        }

        & .spinner-button-wrapper {
          & .MuiCircularProgress-root {
            position: relative;
            top: 7px;
          }
        }
      }
    }
  }
  & .transfer-option-selected {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    height: 40px;
    font-size: 24px;

    & > div:nth-child(1) {
      font-size: 18px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 6px;
        margin-bottom: 3px;
        position: relative;
        top: 2px;

        & .icon {
          width: 12px;
          height: 12px;
          margin-top: 0px;
        }
      }
      & .text {
        margin-left: 2px;
      }
    }

    & > div:nth-child(2) {
      font-size: 16px;
      line-height: 17px;
      letter-spacing: 0.23px;

      &:hover {
        cursor: pointer;
      }
    }
    & .icon {
      width: 12px;
      height: 12px;
      margin-top: 2px;
      background-color: #ffffff;
      mask-size: contain;
    }
    & .selected {
      font-size: 18px;
      font-family: "UniversalSans-Display-Regular";
      line-height: 22px;
    }

    & .deselect-transfer-option {
      line-height: 19px;
    }
  }
}

