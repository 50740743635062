@import "src/assets/scss/common/breakpoints.scss";

.hotel-name-filter-component-container {

    & * {
        box-sizing: border-box;
    }

    & > .accordion-content {

        & > .accordion-header-container {
            height: 24px;
            line-height: 24px;
            font-size: 18px;
        }

        & > .filter-input-container {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            margin-top: 12px;

            & > input {
                display: inline-block;
                width: 100%;
                height: 100%;
                padding: 12px 30px 12px 12px;
                font-size: 16px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                border: 1px solid lightblue;
                border-radius: 4px;

                &:hover, &:focus, &:active {
                    outline: none;
                }
            }

            & > .clear-filter {
                position: absolute;
                right: 8px;
                display: block;
                width: 20px;
                height: 20px;
                background-color: lightblue;
                -webkit-mask: url('../../../assets/icons/klm/close-S.svg') no-repeat center;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        & > .hotel-name-filter-component-content {
            position: absolute;
            top: 102px;
            min-width: 100%;
            width: 440px;
            background-color: white;
            z-index: 99;

            & > .hotel-name {
                position: relative;
                padding: 10px 20px;
                font-size: 16px;

                &:first-child {
                    margin-top: 12px;
                }
    
                &:last-child {
                    margin-bottom: 12px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: lightgray;
                }

                @include responsive(XXS) {
                    white-space: normal;
                }

                @include responsive(LG) {
                }
            }

            @include responsive(XXS) {
                width: 100%;
            }

            @include responsive(LG) {
                width: 440px;
            }
        }
    }   
}
