.theme-filter-component-container {
    &>.accordion-content {
        border: none;
    }
    & .filter-options-container {

        &>.filter-option {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 24px;
            margin-top: 8px;
            font-size: 16px;

            &:hover {
                cursor: pointer;
            }

            &>div {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
            }

            &>.checkbox-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border: 1px solid #7C7F7D;
                border-radius: 4px;
            }

            &>.active {
                border: 1px solid #00A1DE;
                background-color: #00A1DE;

                &>.checkbox {
                    background-color: white;
                    width: 17px;
                    height: 17px;
                    -webkit-mask: url("../../../assets/icons/klm/ic_check.svg") no-repeat center;
                    mask-size: contain;
                }

                &:hover {
                    cursor: pointer;
                    border: 1px solid #3BC9FF;
                    background-color: #3BC9FF;
                }

                &:focus, &:active {
                    border: 1px solid white;
                    background-color: #00A1DE;
                    outline: 1px solid #3BC9FF;
                }
            }

            &>.inactive {
                border: 1px solid #7C7F7D;

                &>.checkbox {
                    background-color: transparent;
                }

                &:hover {
                    cursor: pointer;
                    border: 1px solid #C2DEEA;
                }

                &:focus, &:active {
                    border: 1px solid #00A1DE;
                  }
            }

            &>.text-container {
                height: 100%;
            }

            &>.counter-container {
                margin-left: auto;
                height: 100%;
            }
        }
    }
}