@import "src/assets/scss/common/breakpoints.scss";

@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.payment-hotel-summary-overview-component {
  width: 100%;
  box-sizing: border-box;

  & .payment-hotel-summary-header {
    font-weight: normal;
    line-height: 48px;
    @include responsive(XXS) {
      font-size: 24px;
    }
    @include responsive(SM) {
      margin-bottom: 16px;
      font-size: 48px;
    }
  }

  & .payment-hotel-summary-container {
    background-color: white;

    @include responsive(XL) {
      display: flex;
    }

    & .image-container {
      @include responsive(XXS) {
        & img {
          object-fit: cover;
          width: 100%;
          height: 240px;
        }
      }
      @include responsive(XL) {
        & img {
          object-fit: cover;
          width: 296px;
          height: 100%;
        }
      }
    }

    & .description-container {
      box-sizing: border-box;
      position: relative;
      flex-shrink: 0;
      flex-grow: 1;
      
      @include responsive(XXS) {
        flex-direction: column;
        margin: 24px;
      }

      @include responsive(XS) {
        flex-direction: row;
      }

      @include responsive(XL) {
        margin: 32px;
      }

      & .hotel-info {
        background-color: white;
        display: flex;
        justify-content: space-between;

        @include responsive(XXS) {
          flex-direction: column;
        }
        @include responsive(SM) {
          flex-direction: row;
        }
        @media (min-width: 576px) and (max-width: 610px) {
          flex-direction: column;
        }
        @include responsive(MD) {
          flex-direction: column;
        }
        @include responsive(XL) {
          flex-direction: row;
        }

        & .header {
          font-size: 18px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 450px;
          flex-shrink: 1;

          @include responsive(XXS) {
            margin-bottom: 12px;
            max-width: 320px;
          }

          @media (min-width: 450px) and (max-width: 500px) {
            flex-direction: column;
            max-width: 380px;
          }
          @media (min-width: 500px) and (max-width: 576px) {
            flex-direction: column;
            max-width: 420px;
          }

          @include responsive(SM) {
            flex-direction: row;
            max-width: 480px;
          }

          @include responsive(MD) {
            max-width: 450px;
          }

          @include responsive(XL) {
            max-width: 280px;
          }
          @media (min-width: 1450px) {
            max-width: 330px;
          }
        }

        & .content {
          display: flex;

          @include responsive(XXS) {
            margin-bottom: 18px;
          }

          @include responsive(SM) {
            flex-direction: row;
            align-items: center;
            height: 24px;
            line-height: 24px;
          }

          & .rating {
            display: flex;
            flex-direction: row;
            height: 16px;

            & .star-rating-container {
              @include flex-row-center;
              padding-right: 12px;

              & .star-rating-component > div {
                margin: -1px 5px 0 0;
                width: 10px;
                height: 10px;
              }
            }

            & .tripadvisor-rating-container {
              @include flex-row-center;
              padding-left: 16px;

              & .tripadvisor-logo-container {
                background-color: black;
                width: 24px;
                height: 12px;
                margin-right: 3px;
                -webkit-mask: url("../../assets/icons/common/tripadvisor-logo.svg")
                  no-repeat center;
                mask-size: contain;
              }

              & .tripadvisor-rating-component > div {
                width: 11px;
                height: 11px;
              }
            }
          }

          & .location {
            display: flex;
            flex-direction: row;
            height: 16px;
            line-height: 16px;

            @include responsive(XXS) {
              margin: 14px 0;
            }

            @include responsive(SM) {
              margin: 0;
              padding-left: 16px;
            }

            & > .flag-container {
              display: flex;
              align-items: center;

              & img {
                height: 14px;
              }
            }

            & > .text-container {
              padding: 0 6px;
              font-size: 16px;
              letter-spacing: 0.46px;
            }
          }
        }
      }

      & .description-items {
        display: flex;
        flex-direction: column;
        line-height: 24px;

        & .row {
          box-sizing: border-box;

          & .row-description {
            margin: 8px 0 14px 0;
            @include responsive(XXS) {
              padding-left: 32px;
            }
            @include responsive(SM) {
              padding-left: 40px;
            }

            & .header-description {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 500px;
              flex-shrink: 1;

              & .sub-description {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 500px;
              }
          }
        }

          & .row-container {
            display: flex;

            & .payment-icon {
              height: 24px;
              width: 24px;
              justify-content: center;

              @include responsive(XXS) {
                margin-right: 8px;
              }
              @include responsive(SM) {
                margin-right: 16px;
              }
            }

            & .column-container {
              display: flex;
              justify-content: space-between;

              @include responsive(SM) {
                margin-bottom: 24px;
              }
              @include responsive(XL) {
                margin-bottom: 0;
              }

              & .column {
                @include responsive(XXS) {
                  &:last-child {
                    margin: 16px 0 24px 0;
                  }
                }
                @include responsive(SM) {
                  &:last-child {
                    margin: 0;
                  }
                }
              }

              @include responsive(XXS) {
                margin-left: 4px;
                flex-direction: column;
              }

              @include responsive(XS) {
                margin-left: 0;
              }

              @include responsive(SM) {
                flex-direction: row;
                gap: 56px;
              }

              & .column-description {
                margin: 8px 0 0 0;
              }
            }
          }
        }
      }
    }
  }
}
