// ##### Transavia Colors

// General colours

$discount-text-background: #56e09b;
$discount-text: black;
$primary-black: #001526;
$icon-black: #003245;
$modal-overlay: #101828;
$primary-colour: #2800A0;
$secondary-colour: #FF3268;
$amenity-checkmark-colour: #00D66C;
$summary-block-gray: #F6F6F6;
$action-block: #f6f6f6;
$price-colour: #202020;
$transavia-footer: #F7F7F7;
$transavia-dark-blue-shade: #0B254E;

// Transavia Primary
$transavia-primary: #2800A0;
$transavia-primary-hover: #5E40B8;
$transavia-primary-drop-shadow: #120145;
$transavia-primary-light: #E9E5F5;

// Transavia Secondary
$transavia-secondary: #FF3268;
$transavia-secondary-hover: #FF658E;
$transavia-secondary-drop-shadow: #CA2B55;
$transavia-secondary-light: #FFEBF0;

// Transavia Info
$transavia-info: #37A6FF;
$transavia-highlighted-info: #00A1DE;
$transavia-info-light: #99DDFF;
$transavia-focus: #015FCC;
$transavia-dark-blue: #003145;

// Transavia Success
$transavia-success: #00D66C;
$transavia-success-bg: #4CE298;
$transavia-success-drop-shadow: #1E9162;

// Transavia Warning
$transavia-warning-danger: #F28C37;
$transavia-warning-danger-light: #F9BF71;

// Transavia Error
$transavia-error: #FF4747;
$transavia-error-light: #FF8484;

// Transavia Background
$transavia-background: #EEDAC8;
$transavia-background-white: #FFFFFF;
$transavia-background-light: #F3E5D8;
$transavia-background-drop-shadow: #BEAEA0;

// Transavia Grayscale
$transavia-grey: #909090;
$transavia-grey-50: #F6F6F6;
$transavia-grey-100: #E6E6E6;
$transavia-grey-200: #CCCCCC;
$transavia-grey-300: #BCBCBC;
$transavia-grey-400: #A6A6A6;
$transavia-grey-500: #8F8F8F;
$transavia-grey-600: #797979;
$transavia-grey-700: #636363;
$transavia-gray-750: #606060;
$transavia-grey-800: #4D4D4D;
$transavia-grey-900: #363636;
$transavia-black: #202020;

// Transavia White
$transavia-white: #FFFFFF;
$transavia-white-hover: #F5F8FA;
$transavia-white-drop-shadow: #C8D1DB;

// Transavia Other
$transavia-alt-gray: #91A3B7;
$transavia-border: #91A3B7;
$transavia-light-hover: #F7F5FB;


:export {

    // Transavia Primary
    Transavia_Primary: {
        Transavia_Primary: $transavia-primary;
        Transavia_Primary_Hover: $transavia-primary-hover;
        Transavia_Primary_Drop_Shadow: $transavia-primary-drop-shadow;
        Transavia_Primary_Light: $transavia-primary-light;
    };

    // Transavia Secondary
    Transavia_Secondary: {
        Transavia_Secondary: $transavia-secondary;
        Transavia_Secondary_Hover: $transavia-secondary-hover;
        Transavia_Secondary_Drop_Shadow: $transavia-secondary-drop-shadow;
        Transavia_Secondary_Light: $transavia-secondary-light;
    };

    // Transavia Info
    Transavia_Info: {
        Transavia_Info: $transavia-info;
        Transavia_Info_Light: $transavia-info-light;
        Transavia_Focus: $transavia-focus;
    };

    // Transavia Success
    Transavia_Success: {
        Transavia_Success: $transavia-success;
        Transavia_Success_Bg: $transavia-success-bg;
        Transavia_Success_Drop_Shadow: $transavia-success-drop-shadow;
    };

    // Transavia Warning
    Transavia_Warning: {
        Transavia_Warning_Danger: $transavia-warning-danger;
        Transavia_Warning_Danger_Light: $transavia-warning-danger-light;
    };

    // Transavia Error
    Transavia_Error: {
        Transavia_Error: $transavia-error;
        Transavia_Error_Light: $transavia-error-light;
    };

    // Transavia Background
    Transavia_Background: {
        Transavia_Background: $transavia-background;
        Transavia_Background_White: $transavia-background-white;
        Transavia_Background_Light: $transavia-background-light;
        Transavia_Background_Drop_Shadow: $transavia-background-drop-shadow;
    };

    // Transavia Grayscale
    Transavia_Grayscale: {
        Transavia_Grey_50: $transavia-grey-50;
        Transavia_Grey_100: $transavia-grey-100;
        Transavia_Grey_200: $transavia-grey-200;
        Transavia_Grey_300: $transavia-grey-300;
        Transavia_Grey_400: $transavia-grey-400;
        Transavia_Grey_500: $transavia-grey-500;
        Transavia_Grey_600: $transavia-grey-600;
        Transavia_Grey_700: $transavia-grey-700;
        Transavia_Grey_800: $transavia-grey-800;
        Transavia_Grey_900: $transavia-grey-900;
        Transavia_Black: $transavia-black;
    };

    // Transavia White
    Transavia_White: {
        Transavia_White: $transavia-white;
        Transavia_White_Hover: $transavia-white-hover;
        Transavia_White_Drop_Shadow: $transavia-white-drop-shadow;
    };

    // Transavia Other
    Transavia_Other: {
        Transavia_Alt_Gray: $transavia-alt-gray;
        Transavia_Border: $transavia-border;
        Transavia_Light_Hover: $transavia-light-hover;
    };
}
