@import "src/assets/scss/klm/klm-colors.scss";

.fail-booking-component {
  width: 100%;
  margin-bottom: 36px;

  & .fail-booking-header {
    font-size: 24px;
    margin-bottom: 20px;

    svg {
      position: relative;
      top: 4px;
      margin-right: 10px;
    }
  }
  & .fail-booking-body {
    padding: 19px 24px 17px 24px;
    background-color: white;
    font-size: 16px;
    line-height: 24px;
    box-shadow: 0 10px 30px 0 rgba(124, 127, 125, 0.25);

    & p{
      margin: 0;
    }
  }
}
