@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/common/breakpoints.scss";

.filter-chip-component {
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  margin: 0 8px 8px 0;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid lightblue;

  & * {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
    line-height: 24px;
    box-sizing: border-box;
  }

  &>.flag-container {
    margin-left: 8px;

    @include responsive(XXS) {}

    @include responsive(XL) {}

    &>img {
      border-radius: 2px;
      width: 18px;
      height: 12px;
    }
  }

  &>.text-container {
    margin-left: 8px;
    padding-top: 2px;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &>.button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    width: 16px;
    height: 16px;

    &>.button-filter-action {
      background-color: lightblue;
      width: 10px;
      height: 10px;
      -webkit-mask: url("../../../../assets/icons/klm/close-S.svg") no-repeat center;
      mask-size:contain;

      &:hover {
        cursor: pointer;
      }
    }
  }
}