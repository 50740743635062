/* MEDIA QUERY MANAGER */
@mixin responsive($breakpoint) {
  @if $breakpoint == XXS {
    @media (min-width: 1px) {
      @content;
    }
  }
  @if $breakpoint == XS {
    @media (min-width: 361px) {
      @content;
    }
  }
  @if $breakpoint == ADYENMOBILE {
    @media (max-width: 480px) {
      @content;
    }
  }
  @if $breakpoint == SM {
    @media (min-width: 576px) {
      @content;
    }
  }
  @if $breakpoint == MD {
    @media (min-width: 769px) {
      @content;
    }
  }
  @if $breakpoint == LG {
    @media (min-width: 993px) {
      @content;
    }
  }
  @if $breakpoint == XL {
    @media (min-width: 1201px) {
      @content;
    }
  }
  @if $breakpoint == XXL {
    @media (min-width: 1441px) {
      @content;
    }
  }
  @if $breakpoint == XXXL {
    @media (min-width: 1921px) {
      @content;
    }
  }
}

@mixin page-container-responsive {
  @include responsive(XXS) {
    display: block;
    height: 100%;
    width: calc(100% - 32px);
  }

  @include responsive(MD) {
    width: 768px;
  }

  @include responsive(LG) {
    width: 944px;
  }

  @include responsive(XL) {
    width: 1152px;
  }
  @include responsive(XXL) {
    width: 1224px;
  }
}
