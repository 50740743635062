@import "../../assets/scss/transavia/transavia-colors.scss";

.price-warranty-container {
    display: flex;
    p {
        padding: 0 0 0 10px;
        margin: 0;
    }

    .open-new-tab-icon {
        fill: $transavia-info;
        width: 16px;
        height: 16px;
        position: relative;
        left: 3px;
        top: 3px;
    }
}