@import "src/assets/scss/common/breakpoints.scss";

.status__selected {
  display: flex;
  align-items: center;
  gap: 8px;

  .selected__icon {
    width: 20px;
    height: 20px;
  }
}

.status__select {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  height: 40px;
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;

  @include responsive(SM) {
    margin-left: 16px;
    margin-top: 0;
    width: 188px;
  }
}