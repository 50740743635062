@import "../../../assets/scss/common/breakpoints.scss";

.cabin-luggage-container {
  background: white;

  @include responsive(XXS) {
    margin-bottom: 32px;
  }
  @include responsive(SM) {
    margin-bottom: 0;
  }

  & > .cabin-luggage-information {
    & > .cabin-luggage-description {
      display: flex;
      flex: 1;
      margin-bottom: 24px;
      border-radius: 12px;
      a {
        text-decoration: underline;
      }

      .cabin-luggage-description-paragraph {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    & > .cabin-baggage-card-component {
      max-width: 560px;
      margin-bottom: 24px;

      & > .cabin-baggage-card-info {
        padding: 20px 16px;
        border-radius: 12px;
        margin-top: 10px;
        & > .cabin-baggage-card-details {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          & > .cabin-baggage-card-description {
            margin-right: auto;
            margin-left: 12px;
            & > .cabin-baggage-card-title {
              line-height: 20px;
              margin-bottom: 7px;
              @include responsive(XXS) {
                font-size: 14px;
              }
              @include responsive(SM) {
                font-size: 16px;
              }
            }
            & > .cabin-baggage-card-condition-details {
              font-size: 12px;
              line-height: 16px;
              @include responsive(SM) {
                font-size: 14px;
                line-height: 18px;
              }
              p {
                margin: 0px;
                padding: 1px;
              }
              .price {
                margin-right: 5px;
                margin-left: 5px;
              }
            }
          }
          & > .cabin-baggage-card-img {
            width: auto;
            margin: 4px 32 50px 0px;
            img {
              max-width: 100%;
              max-height: 100%;
              height: 56px;
            }
          }
        }

        & > .cabin-baggage-card-warning {
          display: flex;
          flex-direction: row;
          border-radius: 12px;
          margin-top: 12px;
          padding: 11px 16px;

          & > .cabin-baggage-card-warning-icon {
            img {
              margin-top: 5px;
            }
          }
          & > .cabin-baggage-card-warning-info {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 20px;
            margin-left: 13px;
            p {
              &:first-child {
                margin-top: 0px;
                margin-bottom: 0px;
              }
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  .cabin-luggage-section-header {
    line-height: 30px;
  }

  .hand-baggage-card-component {
    max-width: 560px;
    margin-top: 10px;

    .hand-baggage-card-info {
      display: flex;
      justify-content: space-between;
      padding: 10px 18px;
      border-radius: 12px;
      border-style: solid;
      border-width: 1px;
      margin-bottom: 24px;

      @include responsive(SM) {
        padding: 18px;
      }

      & > .hand-baggage-card-info-text {
        line-height: 18px;

        & > .info-text-header {
          line-height: 24px;
          font-size: 16px;
        }

        & > .info-text-description {
          margin-top: 5px;
          margin-bottom: 0;
          font-size: 12px;

          @include responsive(SM) {
            font-size: 14px;
          }
        }
      }

      .hand-baggage-card-info-label {
        display: flex;
        flex-direction: column;
        align-items: center;

        .info-label-image {
          width: 38px;

          @include responsive(SM) {
            width: 44px;
          }
        }
      }

      .info-label-text-wrapper {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .info-label-text-check-img {
          width: 10px;

          @include responsive(SM) {
            width: 13px;
          }
        }

        .info-label-text {
          margin-left: 4px;
          font-size: 12px;

          @include responsive(SM) {
            margin-left: 6px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .input-dropdown-select-baggage,
  .cabin-error-container,
  .cabin-trip-baggage-container,
  .no-baggage-container {
    max-width: 560px;
  }

  .cabin-trip-baggage-container {
    margin-bottom: 24px;
  }

  .dropdown-cabin,
  .selected-cabin {
    width: 100%;
  }
  .selected-cabin {
    padding-left: 12px;
  }
  .no-baggage-text-box {
    margin-right: auto;
    margin-left: 12px;
  }
  .button-loading-spinner-cabin {
    margin-right: 20px;
  }
}
