@import "src/assets/scss/common/breakpoints.scss";

.traveler-name-component {
  display: block;

  @include responsive(XXS) {
    display: block;
  }
  @include responsive(MD) {
    display: flex;
    justify-content: space-between;
  }

  & .first-name {
    @include responsive(XXS) {
      margin-bottom: 8px;
    }
    @include responsive(MD) {
      margin-bottom: 0;
    }
  }

  & .first-name, & .last-name {
    @include responsive(XXS) {
      display: block;
      width: calc(100% - 12px);
    }
    @include responsive(SM) {
      width: 392px;
    }
    @include responsive(MD) {
      width: calc(50% - 12px);
    }
  }
}
