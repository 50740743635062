@import "../../common/colours.scss";
@import "../../assets/scss/common/breakpoints.scss";

.holiday-card-component {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 20px;
  height: 300px;
  background-color: white;

  & {
    @include responsive(MD) {
      margin-top: 20px;
      flex-direction: row;
      flex: 0;
      padding-bottom: 0px;
    }
  }
}

.google-map-card-expanded {
  position: relative;
  min-height: 486px;
}

.google-map-card-wrapped {
  position: relative;
  opacity: 0;
  height: 0;
}

.holiday-card-preview-image {
  height: 100%;
}

.holiday-card-primary-content {
  flex: 2;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
}

.holiday-card-hotel-details-container {
  flex: 2;
}

.holiday-card-hotel-location {
  margin-top: 12px;
}
.holiday-card-hotel-location > .hotel-location-component img {
  margin: 2px 8px 2px 0;
}

.holiday-card-hotel-amenities {
  flex: 1;
}

.holiday-card-hotel-amenities .hotel-amenities-component > div {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.holiday-card-lower-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 769px) {
    padding-bottom: 16px;
  }
  @media (min-width: 769px) {
    overflow-x: clip;
  }
}

.holiday-card-themes-container {
  display: flex;
}

.holiday-card-map-view-icon {
  margin-right: 4px;
}

.holiday-card-map-view {
  font-size: 15px;
  align-items: center;
  display: flex;
}

.holiday-card-summary-content {
  flex: 1;
}

.holiday-card-hotel-amenities .icon-box {
  padding: 0 0.2em 0 0;
  font-size: x-large;
}

.holiday-card-amenity {
  display: flex;
}

.shopping-cart-price-warranty {
  font-size: 14px;
  margin-bottom: 48px;
}

.holiday-card-primary-content {
  background-color: white;

  @include responsive(MD) {
    max-width: 220px;
  }
  @include responsive(LG) {
    max-width: 404px;
  }
  @include responsive(XL) {
    max-width: 296px;
  }
  @include responsive(XXL) {
    max-width: 368px;
  }
}

.holiday-card-summary {
  box-sizing: border-box;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  color: $light-text-black;

  @media (max-width: 769px) {
    & .summary-and-icon-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  & {
    @include responsive(MD) {
      padding: 16px;
    }
  }
  @include responsive(MD) {
    width: 208px;
    justify-content: space-between;
  }

  & .content-item-icon {
    position: relative;
    display: inline-block;
    margin-left: 2px;
    top: 2px;
    line-height: 14px;
  }
}

.holiday-card-mobile-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.holiday-summary-text {
  line-height: 16px;
  @include responsive(XXS) {
    padding-top: 16px;
    margin-bottom: 24px;
  }
  @include responsive(MD) {
    padding-top: 12px;
  }
}

.holiday-card-price-box {
  display: flex;
  line-height: 30px;
  @include responsive(XXS) {
    display: flex;
    margin-left: 16px;
    justify-content: space-between;
  }
  @include responsive(SM) {
    margin-left: 0;
    justify-content: left;
  }
  @include responsive(MD) {
    display: flex;
  }
}

.holiday-card-action-block {
  display: flex;
  flex-direction: column;

  @include responsive(MD) {
    margin-top: 16px;
  }

  & .holiday-card-action-block-wrapper {
    display: flex;
    @include responsive(MD) {
      flex-direction: column;
    }

    & .holiday-card-discount-alert {
      @include responsive(SM) {
        margin-right: 0;
      }
    }
  }
  & .one-item {
    @include responsive(XXS) {
      flex-direction: row-reverse;
      justify-content: left;
    }
    @include responsive(MD) {
      flex-direction: column;
      justify-content: normal;
    }
  }
  & .two-items {
    @include responsive(XXS) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    @include responsive(MD) {
      flex-direction: column;
      justify-content: normal;
    }
  }

  & .holiday-card-price-block {
    display: flex;
    align-content: center;
    align-items: flex-end;
    color: $light-text-black;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;

    & {
      padding-left: 20px;
      @include responsive(XXS) {
        display: flex;
      }
      @include responsive(MD) {
        padding-left: 0px;
        padding-right: 0px;
        justify-content: flex-start;
      }
    }
  }

  & .local-fee-description {
    padding-top: 4px;
    padding-bottom: 16px;
    margin: 0;
    font-size: 12px;
    overflow-wrap: break-word;
    word-wrap: break-word;

    @include responsive(XXS) {
      margin-left: 16px;
      margin-right: 16px;
    }
    @include responsive(SM) {
      margin-left: 0;
      margin-right: 0;
    }
    @include responsive(MD) {
      width: 176px;
    }

    & .content-item-icon {
      position: relative;
      display: inline-block;
      margin-left: 2px;
      top: 2px;
      line-height: 14px;
    }
  }
}

.holiday-card-discount-alert {
  padding: 4px;
  padding-left: 4px;
  height: 14px;
  margin-bottom: 3px;
  font-size: 14px;
  text-align: left;
  display: flex;
  max-width: 100%;
  margin-top: 6px;
  min-width: 140px;
  & {
    @include responsive(MD) {
      flex-direction: column;
      margin-bottom: 0px;
      padding: 0 8px;
    }
  }
}

.holiday-card-alert-spacer {
  margin-top: -26px;
}

.holiday-card-discount-alert {
  height: 20px;
  font-size: 14px;
  display: flex;
  line-height: 20px;
  padding: 0 8px;

  @include responsive(XXS) {
    margin: auto 12px 8px 0;
    padding: 4px 8px;
  }

  @include responsive(MD) {
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0 8px;
  }
}

.holiday-card-info-icon {
  font-size: 14px;
  line-height: 1.3;
  padding-left: 4px;
  color: $klm-blue;
}

.holiday-card-hotel-rating {
  display: flex;
}

.holiday-card-hotel-rating > div:nth-child(1) {
  padding-right: 1em;
  border-right: 1px solid $background-grey;
}

.holiday-card-hotel-rating > div:nth-child(2) {
  padding-left: 1em;
}

.tripadvisor-rating {
  padding-left: 0.25em;
  color: $tripadvisor-green !important;
}

.holiday-card-themes .holiday-themes-component .holiday-theme-icon {
  padding: 0.3em;
  margin-right: 0.5em;
  color: $klm-blue;
  border: 1px solid $klm-blue;
  border-radius: 50%;
}
