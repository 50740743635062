.close-cart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 100%;
  background-color: #fff;
  p {
    margin: 0;
  }
}
