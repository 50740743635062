@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors.scss";

.holiday-summary-overview-component {
  margin: 12px auto;
  box-sizing: border-box;
  background-color: white;
  padding-top: 40px;
  @include page-container-responsive();

  @include responsive(XXXL) {
    width: 1440px;
  }

  & .summary-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    & .affiliate-logo-description {
      position: relative;
      top: 10px;
      font-size: 20px;
      text-align: center;
    }
  }

  & .summary-title {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    margin-top: 30px;
    padding-bottom: 6px;

    @include responsive(XXS) {
      font-size: 24px;
      padding-top: 20px;
      padding-bottom: 62px;
      justify-content: center;
      text-align: center;
    }

    @include responsive(XS) {
      padding-bottom: 54px;
    }

    @include responsive(MD) {
      font-size: 32px;
      padding-bottom: 38px;
      padding-top: 0;
    }
    @include responsive(LG) {
      font-size: 60px;
    }
  }

  & .summary-section-container {
    box-sizing: border-box;

    @include responsive(LG) {
      padding-left: 29px;
      padding-right: 29px;
      display: flex;

      & .holiday-summary-overview {
        flex: 1 1;
      }

      & .price-summary-overview {
        width: 312px;
      }
    }
  }
}
