.static-map-component {
    position: relative;
    margin-bottom: 16px;
    margin-top: 12px;

    & .static-map {
        border-radius: 4px;
    }
}

.custom-marker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
}