.accordion-content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px 0;
    width: 100%;

    & > .accordion-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        cursor: pointer;

        & > .accordion-label {
            display: inline-block;
            width: 100%;
        }

        & > .icon {
            width: 12px;
            height: 8px;
        
            & > .icon-drop-down {
                background-color: lightskyblue;
                width: 100%;
                height: 100%;
                -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_more.svg") no-repeat center;
                mask-size: cover;
            }
        
            & > .icon-drop-up {
                background-color: lightskyblue;
                width: 100%;
                height: 100%;
                -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_less.svg") no-repeat center;
                mask-size: cover;
            }
        }
    }  
}

.remove-border {
    border: 1px solid transparent;
}