@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors";
.react-datepicker {
  & .react-datepicker__navigation {
    @include responsive(XXS) {
      margin: 0 32px;
    }
    @include responsive(XS) {
      margin: 0 10%;
    }
    @media (min-width: 380px) {
      margin: 0 12%;
    }
    @media (min-width: 400px) {
      margin: 0 14%;
    }
    @media (min-width: 420px) {
      margin: 0 16%;
    }
    @media (min-width: 450px) {
      margin: 0 18%;
    }
    @media (min-width: 480px) {
      margin: 0 20%;
    }
    @media (min-width: 520px) {
      margin: 0 22%;
    }
    @media (min-width: 550px) {
      margin: 0 24%;
    }
    @include responsive(SM) {
      margin: 0 36px 0 36px;
    }
    @include responsive(LG) {
      margin: 0 24px 0 24px;
    }

    & .react-datepicker__navigation-icon--previous {
      width: 24px;
      height: 24px;
      background-color: $klm-blue-500;
    }

    &.react-datepicker__navigation--previous--disabled {
      position: absolute;
    }

    &.react-datepicker__navigation--next {
      @media (max-width: 993px) {
        position: absolute;
        top: 328px;
      }
    }

    & .react-datepicker__navigation-icon--next {
      width: 24px;
      height: 24px;
      background-color: $klm-blue-500;
      -webkit-mask: url("../../../../assets/icons/klm/chevron-right-S.svg")
        no-repeat center;
    }

    & .react-datepicker__navigation--next--disabled {
      position: absolute;
      right: 8px;
      top: 24px;
      width: 24px;
      height: 24px;
      background-color: $klm-grey-10 !important;
 
      .react-datepicker__navigation-icon--next {
        opacity: 0;
      }

      @media (max-width: 992px) {
        top: 340px;
      }
    }
  }
  & .shift-picker {
    @media (max-width: 576px) {
      margin-top: 100px;
      top: 48px;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 148px);
    }
    @include responsive(SM) {
      margin-top: 100px;
    }
    @include responsive(LG) {
      margin-top: 92px;
    }
  }
}

.regular-datepicker {
  .react-datepicker__month-container {
    height: 300px;
    .react-datepicker__month {
      .react-datepicker__day {
        line-height: 32px;
        width: 40px;
        margin: 0;
      }

      .react-datepicker__week {
        height: 40px;

        .react-datepicker__day--today {
          margin: 0 4px !important;
          width: 32px !important;
          height: 32px !important;
          border-radius: 50%;
          font-weight: unset;
        }

        .react-datepicker__day {
          &:hover {
            margin: 0 4px !important;
            width: 32px !important;
            height: 32px !important;
            border-radius: 50%;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 15px;
              width: 68%;
              height: 100%;
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: -4px;
              width: 68%;
              height: 100%;
            }
          }
        }

        & .react-datepicker__day--selecting-range-start {
          position: relative;
          width: 32px;
          border-radius: 50%;
          padding: 0;
          margin: 0 4px;

          &:hover {
            &:after {
              background-color: transparent;
            }

            &:before {
              background-color: transparent;
            }
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 15px;
            width: 68%;
            height: 100%;
          }
        }

        .react-datepicker__day--selecting-range-end {
          position: relative;
          color: white;
          width: 32px;
          border-radius: 50%;
          margin: 0 4px;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -4px;
            left: -4px;
            width: 68%;
            height: 100%;
          }
        }

        & .react-datepicker__day--range-end,
        .react-datepicker__day--range-start {
          position: relative;
          width: 32px;
          border-radius: 50%;
          padding: 0;
          margin: 0 4px;
        }

        & .react-datepicker__day--range-start {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 15px;
            width: 68%;
            height: 100%;
          }
        }

        & .react-datepicker__day--range-end {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -4px;
            width: 68%;
            height: 100%;
          }
        }

        & .react-datepicker__day--outside-month {
          opacity: 0;
        }
      }
    }

    &
      .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
      position: relative;
      border-radius: 50%;
    }

    & .react-datepicker__day--selected {
      position: relative;
      width: 24px;
      border-radius: 50%;
    }

    & .react-datepicker__day--in-selecting-range {
      border-radius: 0;
      width: 48px;
    }

    & .react-datepicker__day--selected {
      position: relative;
      color: white;
      width: 48px;
      border-radius: 50%;
      padding: 0;
    }

    & .react-datepicker__day--keyboard-selected:not(:first-child) {
      background-color: transparent;
      width: 40px;
    }
  }
  .react-datepicker__children-container {
    display: block;
    position: absolute;
    width: calc(100% - 48px);

    @include responsive(XXS) {
      top: 614px;
      display: block;
    }
    @media (min-width: 576px) and (max-width: 993px) {
      background-color: transparent;
      width: 356px;
      left: -6px;
    }
    @include responsive(LG) {
      top: 320px;
    }

    .regular-datepicker-header {
      background-color: transparent;
      position: absolute;

      @include responsive(SM) {
        box-sizing: content-box;
        width: calc(100% + 2px);
        top: -720px;
      }

      @include responsive(LG) {
        width: calc(100% + 50px);
        top: -418px;
        left: -23px;
      }
    }
    .regular-datepicker-button-container {
      @media (min-width: 576px) and (max-width: 993px) {
        position: absolute;
        top: 186px;
        bottom: 0;
        background-color: transparent;
      }
      @include responsive(LG) {
        top: 120px;
      }
    }
  }
  & .MuiPaper-root {
    @include responsive(SM) {
      position: relative;
      top: 24px;
    }
    @include responsive(LG) {
      top: 0;
    }
  }
}
