@import "../../../common/colours.scss";

.holiday-icon-box {
    display: flex;
    border-radius: 6px;
    align-items: center;
    justify-content: flex-start;
    color: $klm-blue;

    @media (max-width: 769px) {
        margin-top: -16px;
    }
}

.holiday-icon {
    padding: 0.4em 0.4em;
    font-size: large;
}
