@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/common/colors";

.search-bar-component {
  display: flex;
  justify-content: center;

  & {
    @include responsive(XXS) {
      display: block;
      height: 100%;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    @include responsive(XS) {
      height: 100%;
    }

    @include responsive(SM) {
      height: auto;
      margin-bottom: 24px;
    }

    @include responsive(MD) {
      width: 768px;
      margin-top: 32px;
    }

    @include responsive(LG) {
      width: 944px;
    }

    @include responsive(XL) {
      width: 1152px;
      margin-bottom: 48px;
    }
    @include responsive(XXL) {
      width: 1224px;
    }
  }

  .search-bar-container {
    background-color: white;
  }

  .search-bar-section {
    width: 100%;

    & .dropdown-text {
      width: 100%;
      line-height: 48px;

      & span {
        height: 24px;
        width: 100%;
        background-color: white;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.46px;
        line-height: 24px;

        &:hover {
          font-weight: bold;
        }
      }

      .placeholder-bold {
        font-weight: bold;
      }
    }
    .mobile-cancel {
      box-shadow: none;
      border: none;
    }
  }

  .search-bar-section-button {
    box-sizing: border-box;
    display: flex;
    height: 65px;
    align-items: center;

    @include responsive(XXS) {
      justify-content: space-between;

      .button-cancel {
        background-color: white;
        margin-top: 0;
        height: 40px;
        width: 110px;
      }

      .button-search-bar {
        margin-top: 0;
      }
    }

    @include responsive(SM) {
      .button-cancel {
        display: none;
      }
    }
  }

  .dropdown-caret {
    align-self: center;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
    border-top: solid 5px gray;
    height: 0;
    width: 0;
    box-sizing: border-box;

    &.rotate {
      transform: rotate(-180deg);
    }
  }

  .edit-search-mobile {
    font-size: 14px;
    position: relative;
    padding: 16px;
    box-sizing: border-box;

    @include responsive(XXS) {
      height: 100%;
      box-sizing: border-box;
    }

    @media (min-width: 416px) {
      display: flex;
      justify-content: space-between;
    }

    & .travel-description {
      box-sizing: border-box;
      display: block;
      width: 100%;

      & .departure-location-row {
        margin-bottom: 10px;
        margin-bottom: 10px;
      }

      & .travel-details-row {
        display: flex;
        width: 100%;
        box-sizing: border-box;

        .departure-and-return-date {
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          margin-right: 10px;

          div {
            display: inline-block;
            margin: 0;
            padding: 0;
          }

          .dash-space {
            margin: auto 0;
            padding: 0 12px;
          }

          @include responsive(XXS) {
            margin-right: 24px;
            margin-bottom: 10px;
          }
          @include responsive(XS) {
            margin-right: 8px;
            margin-bottom: 0px;
          }
        }
        & .travel-flexibility {
          font-weight: bold;
          width: 50px;
          position: relative;
          left: 6px;
          justify-content: center;
          align-self: center;
        }

        & .rooms-and-persons {
          display: inline-flex;
          margin: auto;
          position: relative;

          & .svg-icon {
            height: 22px;
            width: 16px;
            margin-right: 4px;
          }

          & .number-of-persons {
            @include responsive(XXS) {
              display: flex;
            }

            @include responsive(XS) {
              margin-right: 12px;
            }

            @include responsive(SM) {
              margin-right: 24px;
            }

            img {
              position: relative;
              height: 19.65px;
              width: 16px;
              margin-right: 5px;
            }
          }

          .number-of-rooms {
            @include responsive(XXS) {
              margin-left: 8px;
              display: flex;
            }

            & span {
              position: relative;
              left: 3px;
            }

            @include responsive(XS) {
              margin-left: 8px;
              span {
                position: relative;
                left: 3px;
              }
            }

            @include responsive(SM) {
              margin-right: 24px;
            }

            span {
              position: relative;
              left: 4px;
            }

            img {
              position: relative;
              top: 2px;
              height: 16px;
              width: 22px;
            }
          }
        }
      }
    }
  }

  .mobile-button-img {
    margin-top: -3px;
  }

  .edit-search-mobile-button {
    padding: 0 12px;
    margin: auto 0;
    width: 280px;
    height: 40px;

    @include responsive(XXS) {
      display: flex;
      margin-left: 0;
      position: relative;
    }

    @include responsive(XS) {
      margin-left: 12px;
    }

    @media (max-width: 416px) {
      margin: 10px 0 0 0;
      width: 180px;
    }
  }
}
