@import "src/assets/scss/common/breakpoints.scss";

.accommodation-component {
  @include page-container-responsive();
  margin: 42px auto;
  box-sizing: border-box;
  padding-top: 40px;

  & > .accommodation-component__subheader {
    font-family: UniversalSans-Display-Regular;
  }

  .more-accommodation-button {
    margin: 16px auto 48px auto;
    cursor: pointer;

    & .button-container {
      display: flex;

      & .dropdown-caret {
        margin-right: 10px;
        margin-left: 10px;
        width: 10px;

        &.rotate {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
