.amenities-filter-component-container {

    & .filter-options-container {

        &>.filter-option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            height: fit-content;
            margin-top: 8px;
            font-size: 16px;

            &:hover {
                cursor: pointer;
            }

            &>div {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
            }

            &>.checkbox-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border: 1px solid #7C7F7D;
                border-radius: 4px;
            }

            &>.active {
                border: 1px solid #00A1DE;
                background-color: #00A1DE;

                &>.checkbox {
                    background-color: white;
                    width: 17px;
                    height: 17px;
                    -webkit-mask: url("../../../assets/icons/klm/ic_check.svg") no-repeat center;
                    mask-size: contain;
                }

                &:hover {
                    cursor: pointer;
                    border: 1px solid #3BC9FF;
                    background-color: #3BC9FF;
                }

                &:focus,
                &:active {
                    border: 1px solid white;
                    background-color: #00A1DE;
                    outline: 1px solid #3BC9FF;
                }
            }

            &>.inactive {
                border: 1px solid #7C7F7D;

                &>.checkbox {
                    background-color: transparent;
                }

                &:hover {
                    cursor: pointer;
                    border: 1px solid #C2DEEA;
                }

                &:focus,
                &:active {
                    border: 1px solid #00A1DE;
                }
            }

            &>.text-container {
                height: 100%;
                line-height: 24px;
            }

            &>.counter-container {
                margin-left: auto;
                height: 100%;
                line-height: 24px;
            }
        }
    }

    & .filter-options-view-switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        width: fit-content;
        height: 32px;
        border: 1px solid #C2DEEA;
        border-radius: 4px;

        &:hover {
            background-color: #E8F4FB;
            cursor: pointer;
        }

        &:focus {
            background-color: #E8F4FB;
            border: 2px solid #00A1DE;
        }

        &:active {
            background-color: #D8EDF9;
        }

        &>.text-container {
            padding-left: 12px;
            font-size: 14px;
            color: #003145;
        }

        &>.icon {
            width: 8px;
            height: 5px;
            margin: 0 15px;

            &>.icon-drop-down {
                background-color: lightskyblue;
                width: 100%;
                height: 100%;
                -webkit-mask: url("../../../assets/icons/klm/KLM_ic_expand_more.svg") no-repeat center;
                mask-size: cover;
            }

            &>.icon-drop-up {
                background-color: lightskyblue;
                width: 100%;
                height: 100%;
                -webkit-mask: url("../../../assets/icons/klm/KLM_ic_expand_less.svg") no-repeat center;
                mask-size: cover;
            }
        }
    }
}