@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-buttons.scss";

.summary-price-overview-total-block-component {
  box-sizing: border-box;
  justify-content: flex-end;

  @include responsive(XXS) {
    padding-top: 32px;
  }

  @include responsive(SM) {
    margin: auto 0 32px 0;
    justify-content: center;
  }

  @include responsive(LG) {
    padding-top: 32px;
  }

  & .summary-price-warranty {
    margin: 0 0 48px 0;
    position: relative;
    top: -12px;
    font-size: 14px;
  }

  & .price-block {
    display: flex;
    width: 100%;
    justify-content: space-between;

    & .price-block-total {
      font-size: 16px;
      margin: auto 10px 10px 0;
    }

    & .price-block-amount {
      display: flex;
      width: 100%;
      white-space: nowrap;
      letter-spacing: 0;

      @include responsive(XXS) {
        font-size: 36px;
      }


      @include responsive(SM) {
        padding-top: 32px;
        font-size: 44px;
      }

      @media (min-width: 642px) {
        padding-top: 0;
      }
    }
  }

  & .down-payment-option {
      display: flex;
      justify-content: space-between;
      margin: 4px 0;
      line-height: 24px;

    & .price-overview-row-price {
      position: relative;
      top: 2px;
    }
  }

  & .button-accept {
    border-radius: 4px;
    font-family: UniversalSans-Display-Medium;
    width: 100%;
  }
}
