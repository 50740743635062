@import "src/assets/scss/common/breakpoints.scss";

.accommodation-option-card-container {
  box-sizing: border-box;
  background-color: white;
  display: flex;

  &:not(:first-of-type) {
    margin-top: 16px;
  }

  @media (min-width: 1px) and (max-width: 769px) {
    height: 100% !important;
    min-height: 152px;
  }

  @include responsive(MD) {
    min-height: 192px;
    height: 100%;
  }

  @include responsive(XXS) {
    flex-direction: column;
  }

  @include responsive(MD) {
    flex-direction: row;
  }

  & .hotel-image {
    border-radius: 4px;

    @include responsive(MD) {
      width: 252px;
    }

    & div {
      @media (min-width: 1px) and (max-width: 769px) {
        height: 152px;
        min-height: 152px;
      }

      @include responsive(MD) {
        height: 100% !important;
      }
    }

    & .hotel-flipper-image-parent {
      @media (min-width: 1px) and (max-width: 769px) {
        width: 100%;
        height: 152px;
        min-height: 152px;
      }

      @include responsive(MD) {
        height: 100%;
        width: 252px !important;
        min-width: 252px !important;
      }

      & .holiday-card-preview-image {
        @media (min-width: 1px) and (max-width: 769px) {
          width: 100%;
          height: 152px;
        }

        @include responsive(MD) {
          width: 252px !important;
          height: 100%;
        }
      }
    }
  }

  & .hotel-description {
    box-sizing: border-box;
    margin: 24px;
    display: flex;
    justify-content: space-between;

    @include responsive(XXS) {
      flex-direction: column;
    }

    @include responsive(MD) {
      flex-direction: row;
      width: 100%;
    }

    & .accommodation-info {
      & > .accommodation-option-card__title-description {
        font-size: 18px;
        line-height: 24px;
        text-transform: capitalize;
      }

      & .amenities-options {
        margin-top: 24px;
        font-size: 14px;

        & .amenities-item-meal {
          display: flex;
        }

        & .amenities-item {
          display: flex;
          line-height: 24px;

          & .amenities-rooms {
            display: flex;
          }

          & .rooms-desktop {
            @include responsive(XXS) {
              display: none;
            }

            @include responsive(LG) {
              display: flex;
            }
          }

          & .rooms-mobile {
            @include responsive(XXS) {
              display: flex;
            }

            @include responsive(LG) {
              display: none;
            }
          }

          & .amenities-items-container {
            display: flex;
            flex-direction: row-reverse;

            @include responsive(XXS) {
              flex-direction: column-reverse;
            }

            @include responsive(LG) {
              flex-direction: row-reverse;
            }

            & .accommodation-categories-options {
              @include responsive(XXS) {
                order: 2;
              }
            }

            & .amenities-category-option {
              display: flex;
              align-items: center;
              gap: 10px;
            }

            & .show-more-accommodation-btn-mobile {
              cursor: pointer;
              margin-bottom: 17px;

              @include responsive(XXS) {
                order: 1;
              }

              @include responsive(LG) {
                display: none;
              }
            }
          }

          & .amenities-icon {
            height: 16px;
            width: 16px;
            margin: 4px 8px 0 0;
          }

          & .amenities-icon-not-selected,
          & .amenities-icon-selected {
            height: 16px;
            width: 16px;
            margin: 4px 8px 0 0;
          }
        }
      }
    }

    .amenities-category-option svg {
      height: 12px;
      width: 14px;
    }

    .categories-option-hidden {
      display: none;
    }

    .amenities-item-one {
      flex-direction: row;
    }

    .amenities-item-group {
      flex-direction: row;
    }

    .show-more-accommodation-btn-desktop {
      @include responsive(XXS) {
        display: none;
      }

      @include responsive(LG) {
        cursor: pointer;
        width: fit-content;
        display: block;
      }
    }

    & .price-selection-control {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: space-between;

      @include responsive(XXS) {
        margin-top: 24px;
        flex-direction: row;
      }

      @include responsive(MD) {
        margin-top: 0;
        flex-direction: column;
      }

      & .additional-cost {
        font-size: 24px;

        @include responsive(XXS) {
          margin: 0;
        }

        @include responsive(MD) {
          margin: 0 0 0 auto;
        }

        span {
          font-size: 12px;
        }

        & .price-sign {
          font-size: 24px;
          font-weight: 300;
        }
      }

      & .inclusive {
        font-size: 18px;

        @include responsive(XXS) {
          margin: 0;
        }

        @include responsive(MD) {
          margin: 0 0 0 auto;
        }
      }

      & .select-accommodation-button {
        & .button-selection {
          height: 40px;
          padding: 0 16px;
          font-size: 16px;

          & .spinner-button-wrapper {
            & .MuiCircularProgress-root {
              position: relative;
              top: 6px;
            }
          }

          @include responsive(MD) {
            width: 188px;
          }
        }

        & .selected-accommodation {
          line-height: 24px;
          height: 24px;
          font-size: 18px;
          display: flex;
        }

        & .selected-accommodation-icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }
  }
}
