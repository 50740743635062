@import "src/assets/scss/common/breakpoints.scss";

.home-stayer-component {
  background-color: white;
  padding: 32px;
  margin-bottom: 16px;

  @include responsive(SM) {
    margin: 32px 0 16px 0;
  }
  & .icon-inform {
    position: relative;
    top: 6px;
    margin-left: 12px;
    width: 24px;
    height: 24px;
  }
}
