@import "../../assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors.scss";

.confirmation-page-component {
  @include page-container-responsive();
  margin: 0 auto;

  & .confirmation-page-container {
    display: flex;
    position: relative;
    margin: 0 auto;

    @include responsive(XXS) {
      flex-direction: column;
    }
    @include responsive(MD) {
      flex-direction: row;
      gap: 24px;
    }
  }

  & .fail-booking-blur {
    opacity: 0.5;
    pointer-events: none;
  }

  & .left-side {
    width: 100%;
    margin-top: 105px;
    @include responsive(XXS) {
      @include responsive(XXS) {
        display: flex;
        flex-direction: column;
      }
      @include responsive(MD) {
        display: block;
      }
    }

    & .checkout-component {
      margin-top: 16px;
    }
  }

  & .shopping-cart-container {
    @include responsive(XXS) {
      margin-top: 24px;
    }
    @include responsive(MD) {
      margin-top: 110px;
    }
  }

  .adyen-checkout__card__brands {
    height: 100%;

    .adyen-checkout__card__brands__brand-wrapper {
      height: 100%;
      width: 54px;

      & img {
        height:100%;
        width: 100%;
      }

      &::after {
        display: none;
        border: none;
      }
    }
  }
}
