@import "../../assets/scss/common/breakpoints.scss";

.container {
  .header {
    text-align: left;
    margin-bottom: 20px;

    p {
      margin: 0;
    }
  }

  .split-section {
    display: flex;
    justify-content: space-between;

    @include responsive(XXS) {
      flex-direction: column;
      gap: 0;
      margin-bottom: 7px;
    }

    @include responsive(XL) {
      flex-direction: row;
      gap: 32px;
      margin-bottom: 20px;
    }

    .left-section-body,
    .right-section-body {
      @include responsive(XXS) {
        max-width: 100%;
      }
      @include responsive(XL) {
        max-width: 400px;
      }
    }

    .left-section,
    .right-section {
      &-header {
        margin: 20px 0 8px;
      }

      &-body p {
        margin-top: 0;
      }

      @include responsive(XXS) {
        padding: 0 24px;
      }

      @include responsive(XL) {
        padding-right: 0;
      }
    }

    .right-section {
      @include responsive(XL) {
        padding-left: 0;
        padding-right: 24px;
      }
    }
  }

  .box-information {
    display: grid;
    gap: 20px;
    margin-bottom: 45px;

    &.three-boxes {
      @include responsive(XXS) {
        grid-template-columns: repeat(1, 1fr);
      }

      @include responsive(SM) {
        grid-template-columns: repeat(3, 1fr);
      }

      .box:last-child {
        display: flex;
        justify-content: left;
        align-items: start;
        padding-top: 18px;
        padding-left: 24px;

        @include responsive(XXS) {
          min-height: 90px;
        }
      }
    }

    @include responsive(XXS) {
      grid-template-columns: repeat(1, 1fr);
    }

    @include responsive(SM) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include responsive(XL) {
      grid-template-columns: repeat(4, 1fr);
    }

    .box {
      padding-top: 18px;
      padding-left: 24px;
      box-sizing: border-box;

      @include responsive(XXS) {
        padding-bottom: 24px;
      }

      p {
        margin: 0;
      }

      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        @include responsive(XXS) {
          min-height: 90px;
        }
      }
    }
  }

  .redirect-button {
    display: block;
    margin: auto;

    @include responsive(XXS) {
      width: 100%;
      padding: 0 16px;
    }

    @include responsive(XXL) {
      padding: 0 37px;
    }

    .confirm {
      @include responsive(XXS) {
        width: 100%;
      }
    }
  }
}
