@import "../../../assets/scss/klm/klm-colors.scss";
@import "../../../assets/scss/common/breakpoints.scss";

.baggage-component {
  position: relative;
  & .baggage-container {
    background: white;

    & .baggage-per-person {
      display: flex;
      justify-content: space-between;
      align-self: center;
      box-sizing: border-box;
      flex-direction: column;
      font-size: 18px;

      & .passenger-section {
        display: flex;

        @include responsive(XXS) {
          margin-bottom: 12px;
        }

        @include responsive(SM) {
          margin-bottom: 0px;
        }

        @include responsive(XL) {
          margin-bottom: 0px;
        }

        & .svg-avatar-icon {
          width: 20px;
          margin-right: 16px;
          align-self: center;
        }

        & .traveller-description {
          align-self: center;
        }
      }

      & .bagage-selection-parent {
        display: block;
        margin-bottom: 5px;
        & .baggage-section {
          border-radius: 6px;
          font-size: 16px;

          display: flex;
          justify-content: space-between;
          flex-direction: column;

          & .form-input-dropdown-component {
            @include responsive(XXS) {
              width: 100%;
            }

            @include responsive(SM) {
              width: 100%;
            }

            @include responsive(XL) {
              width: 560px;
            }

            & .form-input-dropdown {
              & .dropdown {
                width: 100%;

                & .dropdown-item-wrapper {
                  flex: 1;
                  & li {
                    list-style-type: none;
                  }

                  & .dropdown-item-selected,
                  & .dropdown-item {
                    width: 100%;
                  }

                  & .dropdown-item {
                    width: calc(100% - 16px);
                  }

                  & .dropdown-item-selected {
                    padding-left: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.extras-spinner {
  @include responsive(XXS) { 
    position: absolute;
    right: 33px;
    top: 33px;
  }
  @include responsive(XL) {
    position: static;
    margin-right: 20px;
  }
}
