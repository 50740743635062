@import "src/common/colours.scss";

.main-booker-details-component {
  & .frequent-flyer {
    & .frequent-flyer-label {
      display: flex;

      .checkbox-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        border: 1px solid $klm-grey-500;
        border-radius: 4px;
      }

      .active {
        border: 1px solid $primary-blue;
        background-color: $primary-blue;

        .checkbox {
          background-color: white;
          width: 12px;
          height: 12px;
          -webkit-mask: url("../../../../assets/icons/klm/ic_check.svg") no-repeat center;
          mask-size: contain;
        }

        &:hover {
          cursor: pointer;
          border: 1px solid $klm-blue-300;
          background-color: $klm-blue-300;
        }

        &:focus,
        &:active {
          border: 1px solid white;
          background-color: $primary-blue;
          outline: 1px solid $klm-blue-300;
        }
      }

      &>.inactive {
        border: 1px solid $klm-grey-500;

        &>.checkbox {
          background-color: transparent;
        }

        &:hover {
          cursor: pointer;
          border: 1px solid $klm-light-blue-500;
        }

        &:focus,
        &:active {
          border: 1px solid $primary-blue;
        }
      }
    }
  }
}
