// ##### KLM Primary Colors

// General colours

$discount-text-background: #e6f2de;
$discount-text: #4d8b21;
$klm-background: #F0F7FA;
$primary-colour: #23a1dc;
$secondary-colour: #db6e21;
$amenity-checkmark-colour: #23a1dc;
$summary-block-blue: #F5FAFC;
$price-colour: #23a1dc;
$klm-footer: #F7F9F9;
$klm-hand-baggage-color: #202020;

// KLM Blue
$klm-blue-50: #AEE9FF;
$klm-blue-100: #97E2FF;
$klm-blue-200: #69D6FF;
$klm-blue-300: #3BC9FF;
$klm-blue-400: #0DBCFF;
$klm-blue-500: #00A1DE;
$klm-blue-600: #007CAB;
$klm-blue-700: #005778;
$klm-blue-800: #003245;
$klm-blue-900: #000D12;

// KLM Dark Blue
$klm-dark-blue: #003145;
$klm-dark-blue-50: #9FD3FE;
$klm-dark-blue-100: #7EC4FE;
$klm-dark-blue-200: #3DA6FD;
$klm-dark-blue-300: #0271CD;
$klm-dark-blue-400: #0158A0;
$klm-dark-blue-500: #003F72;
$klm-dark-blue-600: #012E54;
$klm-dark-blue-700: #01233F;
$klm-dark-blue-800: #001526;
$klm-dark-blue-900: #00070D;

// KLM Dark Blue 2
$klm-dark-blue-2: #003045;
$klm-dark-blue-2-50: #9FD3FE;
$klm-dark-blue-2-100: #7EC4FE;
$klm-dark-blue-2-200: #3DA6FD;
$klm-dark-blue-2-300: #0271CD;
$klm-dark-blue-2-400: #0158A0;
$klm-dark-blue-2-500: #003F72;
$klm-dark-blue-2-600: #012E54;
$klm-dark-blue-2-700: #01233F;
$klm-dark-blue-2-800: #001526;
$klm-dark-blue-2-900: #002230;

// KLM Light Blue
$klm-light-blue-50: #E8F4FB;
$klm-light-blue-100: #D8EDF9;
$klm-light-blue-200: #BAE0F3;
$klm-light-blue-300: #9BD2EE;
$klm-light-blue-400: #7DC4E9;
$klm-light-blue-500: #5EB6E4;
$klm-light-blue-600: #3BA6DE;
$klm-light-blue-700: #2393CE;
$klm-light-blue-800: #1D7AAB;
$klm-light-blue-900: #176188;

// KLM Light Blue 2
$klm-light-blue-2-50: #F5FAFC;
$klm-light-blue-2-100: #F0F7FA;
$klm-light-blue-2-200: #E4F0F6;
$klm-light-blue-2-300: #D9EAF2;
$klm-light-blue-2-400: #CDE4EE;
$klm-light-blue-2-500: #C2DEEA;
$klm-light-blue-2-600: #B3D6E5;
$klm-light-blue-2-700: #A4CEE0;
$klm-light-blue-2-800: #94C5DA;
$klm-light-blue-2-900: #85BDD5;

// KLM Orange
$klm-orange-50: #FDF4EE;
$klm-orange-100: #FAE6D7;
$klm-orange-200: #F4C9AA;
$klm-orange-300: #EEAC7D;
$klm-orange-400: #E98F4F;
$klm-orange-500: #E37222;
$klm-orange-600: #B65917;
$klm-orange-700: #844111;
$klm-orange-800: #52280A;
$klm-orange-900: #201004;

// ##### KLM Seconary Colors

// KLM Green
$klm-green: #58A618;
$klm-green-light: #E6F2DD;
$klm-green-light_2: #EEF6E7;
$klm-green-dark: #4B8D14;
$klm-green-hover: #81BC51;

// KLM Yellow
$klm-yellow: #FECB00;
$klm-yellow-light: #FEF9E5;
$klm-yellow-warning: #E9B500;
$klm-yellow-dark: #C69A00;

// KLM Orange
$klm-orange-warning: #F28C37;

// KLM Red
$klm-red: #E00034;
$klm-error-label: #FF0000;
$klm-red-light: #FBCCD6;
$klm-red-lighter: #FBE5EA;
$klm-red-dark: #9D0024;

// ##### KLM Grayscale Colors

// KLM Grey
$klm-grey-10: #E3EDF5;
$klm-grey-50: #FCFCFC;
$klm-grey-100: #EDEEEE;
$klm-grey-200: #D1D2D1;
$klm-grey-300: #B5B6B5;
$klm-grey-350: #91A3B7;
$klm-grey-400: #989B99;
$klm-grey-500: #7C7F7D;
$klm-grey-600: #5E605F;
$klm-grey-700: #404140;
$klm-grey-800: #212222;
$klm-grey-900: #030303;

// KLM White
$klm-white-50: #FFFFFF;

// KLM Black
$klm-black: #003145;
$klm-black-dark: #000000;
$klm-black-graphite: #202020;

// KLM Header Outer Shadow
$klm-header-outer-shadow: rgba(124,127,125,0.25);

:export {

  // ##### KLM Primary Colors

  KLM_Blue: {
    KLM_Blue_50: $klm-blue-50;
    KLM_Blue_100: $klm-blue-100;
    KLM_Blue_200: $klm-blue-200;
    KLM_Blue_300: $klm-blue-300;
    KLM_Blue_400: $klm-blue-400;
    KLM_Blue_500: $klm-blue-500;
    KLM_Blue_600: $klm-blue-600;
    KLM_Blue_700: $klm-blue-700;
    KLM_Blue_800: $klm-blue-800;
    KLM_Blue_900: $klm-blue-900;
  };

  KLM_Dark_Blue: {
    KLM_Dark_Blue_50: $klm-dark-blue-50;
    KLM_Dark_Blue_100: $klm-dark-blue-100;
    KLM_Dark_Blue_200: $klm-dark-blue-200;
    KLM_Dark_Blue_300: $klm-dark-blue-300;
    KLM_Dark_Blue_400: $klm-dark-blue-400;
    KLM_Dark_Blue_500: $klm-dark-blue-500;
    KLM_Dark_Blue_600: $klm-dark-blue-600;
    KLM_Dark_Blue_700: $klm-dark-blue-700;
    KLM_Dark_Blue_800: $klm-dark-blue-800;
    KLM_Dark_Blue_900: $klm-dark-blue-900;
  };

  KLM_Dark_Blue_2: {
    KLM_Dark_Blue_2_50: $klm-dark-blue-2-50;
    KLM_Dark_Blue_2_100: $klm-dark-blue-2-100;
    KLM_Dark_Blue_2_200: $klm-dark-blue-2-200;
    KLM_Dark_Blue_2_300: $klm-dark-blue-2-300;
    KLM_Dark_Blue_2_400: $klm-dark-blue-2-400;
    KLM_Dark_Blue_2_500: $klm-dark-blue-2-500;
    KLM_Dark_Blue_2_600: $klm-dark-blue-2-600;
    KLM_Dark_Blue_2_700: $klm-dark-blue-2-700;
    KLM_Dark_Blue_2_800: $klm-dark-blue-2-800;
    KLM_Dark_Blue_2_900: $klm-dark-blue-2-900;
  };
  
  KLM_Light_Blue: {
    KLM_Light_Blue_50: $klm-light-blue-50;
    KLM_Light_Blue_100: $klm-light-blue-100;
    KLM_Light_Blue_200: $klm-light-blue-200;
    KLM_Light_Blue_300: $klm-light-blue-300;
    KLM_Light_Blue_400: $klm-light-blue-400;
    KLM_Light_Blue_500: $klm-light-blue-500;
    KLM_Light_Blue_600: $klm-light-blue-600;
    KLM_Light_Blue_700: $klm-light-blue-700;
    KLM_Light_Blue_800: $klm-light-blue-800;
    KLM_Light_Blue_900: $klm-light-blue-900;
  };

  KLM_Light_Blue_2: {
    KLM_Light_Blue_2_50: $klm-light-blue-2-50;
    KLM_Light_Blue_2_100: $klm-light-blue-2-100;
    KLM_Light_Blue_2_200: $klm-light-blue-2-200;
    KLM_Light_Blue_2_300: $klm-light-blue-2-300;
    KLM_Light_Blue_2_400: $klm-light-blue-2-400;
    KLM_Light_Blue_2_500: $klm-light-blue-2-500;
    KLM_Light_Blue_2_600: $klm-light-blue-2-600;
    KLM_Light_Blue_2_700: $klm-light-blue-2-700;
    KLM_Light_Blue_2_800: $klm-light-blue-2-800;
    KLM_Light_Blue_2_900: $klm-light-blue-2-900;
  };

  // ##### KLM Secondary Colors

  KLM_Orange: {
    KLM_Orange_50: $klm-orange-50;
    KLM_Orange_100: $klm-orange-100;
    KLM_Orange_200: $klm-orange-200;
    KLM_Orange_300: $klm-orange-300;
    KLM_Orange_400: $klm-orange-400;
    KLM_Orange_500: $klm-orange-500;
    KLM_Orange_600: $klm-orange-600;
    KLM_Orange_700: $klm-orange-700;
    KLM_Orange_800: $klm-orange-800;
    KLM_Orange_900: $klm-orange-900;
  };

  KLM_Green: {
    KLM_Green: $klm-green;
    KLM_Green_Light: $klm-green-light;
    KLM_Green_Light_2: $klm-green-light_2;
    KLM_Green_Dark: $klm-green-dark;
    KLM_Green_Hover: $klm-green-hover;
  };

  KLM_Yellow: {
    KLM_Yellow: $klm-yellow;
    KLM_Yellow_Light: $klm-yellow-light;
    KLM_Yellow_Warning: $klm-yellow-warning;
    KLM_Yellow_Dark: $klm-yellow-dark;
  };

  // ##### KLM Grayscale Colors

  KLM_Grey: {
    KLM_Grey_50: $klm-grey-50;
    KLM_Grey_100: $klm-grey-100;
    KLM_Grey_200: $klm-grey-200;
    KLM_Grey_300: $klm-grey-300;
    KLM_Grey_400: $klm-grey-400;
    KLM_Grey_500: $klm-grey-500;
    KLM_Grey_600: $klm-grey-600;
    KLM_Grey_700: $klm-grey-700;
    KLM_Grey_800: $klm-grey-800;
    KLM_Grey_900: $klm-grey-900;
  };

  KLM_White: {
    KLM_White_50: $klm-white-50; 
  };

  KLM_Header_Shadow: $klm-header-outer-shadow;
}
