@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/common/breakpoints.scss";

.pages-stepper-bar-wrapper {
  width: 100%;
  position: absolute;
  left: 0;
  background-color: white;

  & .pages-stepper-bar-component {
    margin: auto;
    width: 100%;
    font-size: 16px;
    position: relative;
    background-color:white;

    @include responsive(XXS) {
      height: 56px;
      text-align: center;
    }
    @include responsive(SM) {
      height: 64px;
      line-height: 64px;
    }

    & .pages-stepper-bar-container {
      margin: auto;
      @include responsive(XXS) {
        width: 308px;
        font-size: 12px;
      }

      @include responsive(SM) {
        width: calc(100% - 32px);
        font-size: 16px;
      }

      @include responsive(MD) {
        width: 720px;
        font-size: 16px;
      }

      & .pages-stepper-bar-icon {
        height: 20px;
        width: 20px;

        @include responsive(XXS) {
          margin: auto;
        }
        @include responsive(SM) {
          margin-right: 8px;
        }
      }
    }

    & .step-description {
      box-sizing: border-box;

      @media (max-width: 576px) {
        position: absolute;
        top: 24px;
      }
    }
  }
}
