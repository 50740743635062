@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors.scss";

.holiday-summary-hotel-overview-component {
  padding: 0 32px 0 32px;
  margin: 0 0 32px 0;

  & .sub-title {
    font-size: 18px;

    @include responsive(SM) {
      border-top: none;
      margin-top: 0;
    }
  }

  & .overview-hotel-information {
    padding-bottom: 32px;

    @include responsive(XXS) {
      column-count: 1;
      margin-bottom: 8px;
    }
    @include responsive(SM) {
      column-count: 2;
    }

    & .overview-hotel-information-item {
      padding-top: 24px;
      display: inline-flex;
      width: 100%;

      & .svg-color {
        height: 24px;
        width: 24px;
        margin-right: 12px;
      }
      .svg-color path {
        width: 10px;
      }

      & .overview-hotel-information-item-description {
          display: block;
          padding-top: 4px;
          width: 90%;
      }
    }
  }
}
