.all-inclusive-filter-component-container {
    & .filter-options-container {

        &>.filter-option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            height: fit-content;
            margin-top: 8px;
            font-size: 16px;

            &:hover {
                cursor: pointer;
            }

            &>div {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
            }

            &>.checkbox-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border-radius: 4px;
            }

            &>.active {
                &>.checkbox {
                    background-color: white;
                    width: 17px;
                    height: 17px;
                    mask-size: contain;
                }

                &:hover {
                    cursor: pointer;
                }

                &:focus,
                &:active {
                    border: 1px solid white;
                }
            }

            &>.inactive {
                &>.checkbox {
                    background-color: transparent;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            &>.text-container {
                height: 100%;
                line-height: 24px;
            }

            &>.counter-container {
                margin-left: auto;
                height: 100%;
                line-height: 24px;
            }
        }
    }

    & .filter-options-view-switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        width: fit-content;
        height: 32px;
        border-radius: 4px;

        &:hover {
            cursor: pointer;
        }

        &>.text-container {
            padding-left: 12px;
            font-size: 14px;
        }

        &>.icon {
            width: 8px;
            height: 5px;
            margin: 0 15px;

            &>.icon-drop-down {
                width: 100%;
                height: 100%;
                mask-size: cover;
            }

            &>.icon-drop-up {
                width: 100%;
                height: 100%;
                mask-size: cover;
            }
        }
    }
}