@import "../../assets/scss/common/breakpoints.scss";

@include responsive(XXS) {
    & .insurance-component {
        margin-bottom: 36px;

        & .content {
            & .header {
                height: 50px;
                line-height: 50px;
                font-size: 24px;
                color: #003145;
            }

            & .insurance-intro {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.46px;
                color: #002230;
            }

            & .insurance-options {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                & .insurance-option {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-width: 350px;
                    min-height: 275px;
                    margin: 16px auto 0 auto;
                    padding: 12px;
                    background-color: #FFFFFF;
                    border: 2px solid #CDE4EE;
                    border-radius: 2px;
                    box-sizing: border-box;

                    &.option-selected {
                        background-color: #D9EAF2;
                        border: 2px solid #003F72;
                        border-radius: 2px;
                        box-sizing: border-box;
                        box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.08), 0 3px 6px 0 rgba(124, 127, 125, 0.08);
                    }

                    &.option-disabled {
                        opacity: 50%;
                    }

                    & .insurance-option-header {
                        height: 55px;
                        font-size: 18px;
                        color: #003145;
                    }

                    & .insurance-option-details {
                        display: flex;
                        flex-flow: column nowrap;
                        flex: 1;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.2px;
                        color: #003045;

                        & .entry {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            & .icon-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 20px;
                                height: 20px;

                                & .icon {
                                    width: 14px;
                                    height: 14px;
                                    margin-bottom: 2px;
                                    background-color: #00A1DE;
                                    -webkit-mask: url("../../assets/icons/klm/ic_check.svg") no-repeat center;
                                    mask-size: contain;
                                }
                            }

                            & .text-container {
                                margin-left: 3px;
                                font-size: 14px;
                                letter-spacing: 0.2px;
                                color: #003045;
                            }
                        }
                    }

                    & .insurance-option-disclaimer {
                        margin-top: auto;
                        padding-top: 10px;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.2px;
                        color: #003245;
                        margin-bottom: 20px;

                        & a {
                            color: #00A1DE;
                            text-decoration: none;
                        }
                    }

                    & .insurance-option-selectable {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: auto;
                        flex-wrap: wrap;
                        width: 100%;
                        height: 40px;
                        font-size: 24px;
                        color: #003045;

                        & .insurance-option-price {
                            width: 50%;
                        }

                        & .insurance-option-selector {
                            width: 50%;
                            text-align: right;
                        }

                        & button {
                            height: 40px;
                            padding: 0 16px;
                        }
                    }

                    & .insurance-option-selected {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: auto;
                        height: 40px;
                        font-size: 24px;

                        &>div:nth-child(1) {
                            font-size: 18px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 140px;

                            & .icon-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 16px;
                                height: 16px;
                                background-color: #58A618;
                                border-radius: 50%;
                                margin-right: 6px;
                                margin-bottom: 3px;

                                & .icon {
                                    width: 12px;
                                    height: 12px;
                                    margin-top: 2px;
                                    background-color: #FFFFFF;
                                    -webkit-mask: url("../../assets/icons/klm/ic_check.svg") no-repeat center;
                                    mask-size: contain;
                                }
                            }

                            & .text {
                                margin-left: 2px;
                            }
                        }

                        &>div:nth-child(2) {
                            font-size: 16px;
                            line-height: 17px;
                            letter-spacing: 0.23px;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    margin-bottom: 16px
                }
            }

            & .insurance-resignation {
                display: flex;
                flex-direction: row;
                padding: 0;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.23px;
                color: #003045;
            }

            & .insurance-agreement {
                background-color: #FFFFFF;
                margin-top: 22px;
                padding: 10px 14px;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.46px;
                box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.08), 0 3px 6px 0 rgba(124, 127, 125, 0.08);

                & .agreement-text {
                    color: #003145;
                }

                & .agreement-checkbox {
                    display: flex;
                    flex-direction: row;
                    margin-top: 16px;

                    & .text-container {
                        font-size: 16px;
                        color: #003145;
                    }
                }
            }

            & .checkbox-container {
                display: flex;
                flex-shrink: 0;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border: 1px solid #7C7F7D;
                border-radius: 4px;
            }

            & .disable-checkbox {
                width: 24px;
                height: 24px;
            }

            & .active {
                border: 1px solid #00A1DE;
                background-color: #00A1DE;

                &>.checkbox {
                    background-color: #FFFFFF;
                    width: 17px;
                    height: 17px;
                    -webkit-mask: url("../../assets/icons/klm/ic_check.svg") no-repeat center;
                    mask-size: contain;
                }

                &:hover {
                    cursor: pointer;
                    border: 1px solid #3BC9FF;
                    background-color: #3BC9FF;
                }

                &:focus,
                &:active {
                    border: 1px solid #FFFFFF;
                    background-color: #00A1DE;
                    outline: 1px solid #3BC9FF;
                }
            }

            & .inactive {
                border: 1px solid #7C7F7D;

                &>.checkbox {
                    background-color: transparent;
                }

                &:hover {
                    cursor: pointer;
                    border: 1px solid #C2DEEA;
                }

                &:focus,
                &:active {
                    border: 1px solid #00A1DE;
                }
            }
        }
        & .progress-circle-payment {
            text-align: center;
            position: relative;
        }
        & .spinner-circle {
             padding: 200px calc(50% - 20px);
        }

        & .circle-title-bottom {
            position: absolute;
            bottom: 120px;
            justify-content: center;
            text-align: center;
        }

        & .circle-bottom-text{
            justify-content: center;
            display: flex;
        }
    }
}

@include responsive(SM) {
    & .insurance-component {
        & .content {
            & .header {
                padding-top: 20px;
                font-size: 48px;
                color: #001526;
            }

            & .insurance-intro {
                margin-top: 10px;
            }

            & .insurance-options {
                justify-content: space-between;

                & .insurance-option {
                    width: calc((100% / 2) - 8px);
                    margin: 16px 0 0 0;

                    & .insurance-option-selectable {
                        height: 80px;

                        & .insurance-option-price {
                            width: 100%;
                        }

                        & .insurance-option-selector {
                            width: 100%;
                        }

                        & button {
                            width: 100%;
                        }
                    }
                }
            }

            & .insurance-resignation {
                padding: 8px 0;

                & .spinner-checkbox {
                    display: inline-flex;
                    position: relative;
                    left: 16px;
                    bottom: 4px;
                }
            }

            & .insurance-agreement {
                padding: 24px;
            }
        }
    }
}

@include responsive(MD) {
    & .insurance-component {
        & .content {
            & .header {
                padding-top: 0;
            }
        }
    }
}

@include responsive(LG) {
    & .insurance-component {
        & .content {
            & .insurance-options {
                & .insurance-option {
                    & .insurance-option-selectable {
                        height: 40px;

                        & .insurance-option-price {
                            width: 50%;
                        }

                        & .insurance-option-selector {
                            width: 50%;
                            text-align: right;
                        }

                        & button {
                            width: fit-content;
                        }
                    }
                }
            }
        }
        & .progress-circle-payment {
            text-align: center;
            position: relative;
        }
        & .spinner-circle {
             padding: 200px calc(50% - 20px);
        }

        & .circle-title-bottom {
            position: absolute;
            bottom: 100px;
            justify-content: center;
            text-align: center;
        }

        & .circle-bottom-text{
            justify-content: center;
            display: flex;
        }
    }
}

@include responsive(XL) {
    & .insurance-component {
        & .content {
            & .insurance-options {
                & .insurance-option {
                    width: calc((100% / 3) - 16px);

                    & .insurance-option-selectable {
                        height: 80px;

                        & .insurance-option-price {
                            width: 100%;
                        }

                        & .insurance-option-selector {
                            width: 100%;
                        }

                        & button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.spinner-button-wrapper {
    width: 100%;
    justify-content: center;
    .spinner-button {
        display: flex;
        justify-content: center;
        & .button-loading-spinner {
            position: relative;
            top: 4px;
        }
        & .loading-text {
            padding-left: 16px;
        }
    }
}

.checkbox-option-disabled {
    opacity: 0.5;
    pointer-events: none;
}