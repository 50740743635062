@import "src/assets/scss/klm/klm-colors";
@import "src/assets/scss/common/breakpoints.scss";

.form-input-dropdown-component {
  display: inline-block;
  width: 100%;
  padding: 8px 0;
  font-size: 16px;
  position: relative;

  & .input-placeholder-text {
    position: absolute;
    box-sizing: border-box;
    z-index: 11;
    top: 32px;
    height: 44px;
    line-height: 40px;
    width: calc(100% - 8px);
    outline: none;
    border: none;
    background-color: transparent;
    padding-left: 16px;
    font-size: 16px;
    font-family: UniversalSans-Text-Light;
  }

  & label {
    display: block;
    flex-wrap: nowrap;
    flex-direction: column;
    line-height: 24px;
  }

  & .label-description {
    position: relative;
    cursor: pointer;

    & .form-input-dropdown {
      position: relative;
      display: flex;
      box-sizing: border-box;
      line-height: 48px;
      height: 48px;
      border-radius: 4px;
      

      & .input-placeholder {
        position: relative;
        margin-left: 20px;
        border: none;
        white-space: nowrap;
        overflow: hidden;
        left: -2px;
        outline: none;
      }

      & .icon-arrow {
        position: absolute;
        width: 10px;
        height: 5px;
        bottom: 22px;

        @include responsive(XXS) {
          right: 8px;
        }

        @media (min-width: 448px) {
          right: 20px;
        }

        & > .icon-drop-down {
          width: 100%;
          height: 100%;
          mask-size: auto;
        }

        & > .icon-drop-up {
          width: 100%;
          height: 100%;
          mask-size: auto;
        }
      }

      & ul {
        padding: 0;
      }

      & .dropdown {
        position: absolute;
        box-sizing: border-box;
        top: 34px;
        z-index: 10;
        min-width: 200px;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: white;
        padding: 10px;

        & .dropdown-item-wrapper {
          display: flex;

          & .dropdown-item-spacer {
            display: flex;
            width: 4px;
            height: 48px;
          }

          & .dropdown-item {
            display: flex;
            padding-left: 16px;
            list-style-type: none;
            flex-shrink: 0;
            flex-grow: 1;
          }
        }
      }
    }
  }
}
