@import "../../assets/scss/common/breakpoints.scss";

.holiday-package-options-summary-component {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px 20px 20px -20px rgba(124, 127, 125, 0.25);

    @include responsive(XXS) {
        width: 100%;
    }

    & * {
        box-sizing: border-box;
    }

    &>.content {
        display: flex;
        margin: 0 auto;
        color: #003145;

        @include responsive(XXS) {
            width: calc(100% - 32px);
        }

        @include responsive(MD) {
            width: 768px;
        }

        @include responsive(LG) {
            width: 944px;
        }

        @include responsive(XL) {
            width: 1152px;
        }

        @include responsive(XXL) {
            width: 1224px;
        }

        @include responsive(XXXL) {
            width: 1224px;
        }

        &>.column {
            position: relative;

            &:nth-child(1) {
                &>.item:nth-child(2)>.icon {
                    -webkit-mask: url("../../assets/icons/klm/location-marker-S.svg") no-repeat left;
                    mask-size: contain;
                }

                &>.item:nth-child(3)>.icon {
                    -webkit-mask: url("../../assets/icons/klm/meals-S.svg") no-repeat left;
                    mask-size: contain;
                }

                &>.item:nth-child(4)>.icon {
                    -webkit-mask: url("../../assets/icons/klm/passengers-S.svg") no-repeat left;
                    mask-size: contain;
                }
            }

            &:nth-child(2) {
                &>.item:nth-child(1)>.icon {
                    -webkit-mask: url("../../assets/icons/klm/origin-S.svg") no-repeat left;
                    mask-size: contain;
                }

                &>.item:nth-child(2)>.icon {
                    -webkit-mask: url("../../assets/icons/klm/icon_returnflight_KLM.svg") no-repeat left;
                    mask-size: contain;
                }

                &>.item:nth-child(3)>.icon {
                    -webkit-mask: url("../../assets/icons/klm/calendar-S.svg") no-repeat left;
                    mask-size: contain;
                }
            }

            &:last-child {
                display: flex;
                flex-direction: column;

                &>.section {
                    &:nth-child(1) {
                        display: flex;
                        flex-direction: row;
                        align-content: stretch;
                        justify-content: space-between;
                        height: 40px;

                        &>.button-edit {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            height: 40px;
                            padding: 0 14px;
                            margin-left: 10px;
                            color: #FFFFFF;
                            background-color: #E37222;
                            border-radius: 4px;

                            &>div:nth-child(1) {
                                background-color: #FFFFFF;
                                width: 12px;
                                height: 12px;
                                margin-right: 8px;
                                -webkit-mask: url("../../assets/icons/klm/edit-data-S.svg") no-repeat center;
                                mask-size: contain;
                            }
                        }
                    }
                }
            }

            & .view-switch {
                display: flex;
                flex-direction: row;
                align-items: center;

                &:hover {
                    cursor: pointer;
                }

                &>.label {
                    &.size-small {
                        font-size: 12px;
                    }

                    &.size-large {
                        font-size: 14px;
                    }
                }

                &>.icon {
                    width: 8px;
                    height: 8px;
                    margin-left: 8px;

                    &.size-small {
                        width: 8px;
                        height: 8px;
                    }

                    &.size-large {
                        width: 16px;
                        height: 16px;
                    }

                    &>.icon-drop-down {
                        background-color: #00A1DE;
                        width: 100%;
                        height: 100%;
                        -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_more.svg") no-repeat center;
                        mask-size: contain;
                    }

                    &>.icon-drop-up {
                        background-color: #00A1DE;
                        width: 100%;
                        height: 100%;
                        -webkit-mask: url("../../assets/icons/klm/KLM_ic_expand_less.svg") no-repeat center;
                        mask-size: contain;
                    }
                }
            }

            &>.item {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 24px;
                font-size: 16px;
                overflow: hidden;

                &>.icon {
                    width: 25px;
                    height: 20px;
                    padding-right: 25px;
                    background-color: #00A1DE;
                }

                &>.text {
                    padding-right: 8px;
                    white-space: nowrap;

                    &:last-child {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &>.bold {
                    font-weight: bolder;
                }

                &>.mobile {
                    padding: 20px 0;
                }

                &>.mobile-content {
                    font-size: 14px;
                    letter-spacing: 0.46px;
                    color: #5E605F;
                }

                &.center-content {
                    justify-content: center;
                }
            }
        }
    }

    @include responsive(XXS) {
        &>.content {
            flex-direction: column;

            &>.column {
                width: auto;

                &:nth-child(1) {
                    order: 2;
                    border: none;
                    padding: 0;
                }

                &:nth-child(2) {
                    order: 3;
                    border: none;
                    padding: 0;

                    &>.item {
                        &:last-child {
                            margin: 20px 0;
                        }
                    }
                }

                &:last-child {
                    order: 1;
                    display: flex;
                    flex-direction: column;
                    padding: 8px 0;

                    &>.section {
                        margin: 6px 0 0 0;

                        &:nth-child(1) {
                            &>.price-info {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-end;
                                align-items: center;
                                flex-grow: 1;
                                margin: 0 8px;
                                text-align: right;
                                font-size: 16px;
                                letter-spacing: 0.23px;

                                & span:nth-child(2) {
                                    font-weight: bolder;
                                    padding: 0 5px;
                                }
                            }

                            &>.button-edit {
                                &>div:nth-child(3) {
                                    display: none;
                                }
                            }
                        }

                        &:nth-child(2) {
                            padding-left: 5%;
                            font-size: 12px;
                            letter-spacing: 0.17px;
                            text-align: right;
                        }
                    }
                }

                &>.item {
                    margin-top: 10px;
                }
            }
        }
    }

    @include responsive(XS) {
        &>.content {
            &>.column {
                &:last-child {
                    &>.section {
                        &:nth-child(2) {
                            padding-left: 10%;
                        }
                    }
                }
            }
        }
    }

    @include responsive(SM) {
        &>.content {
            flex-direction: row;

            &>.column {
                &:nth-child(1) {
                    order: 1;
                    width: calc(100% / 3.7);
                    padding: 0 8px 0 0;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 2px;
                        height: calc(100% - 16px);
                        transform: translate(0, -50%);
                        background: #CDE4EE;
                    }
                }

                &:nth-child(2) {
                    order: 2;
                    width: calc(100% / 3.7);
                    padding: 0 8px;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 2px;
                        height: calc(100% - 16px);
                        transform: translate(0, -50%);
                        background: #CDE4EE;
                    }
                }

                &:last-child {
                    order: 3;
                    width: calc(100% / 2);
                    padding: 0 0 0 8px;

                    &>.section {
                        &:nth-child(1) {
                            &>.price-info {
                                justify-content: flex-start;
                                margin: 0;
                                text-align: left;

                                & span:nth-child(2) {
                                    font-weight: bolder;
                                    padding: 0 5px;
                                }
                            }
                        }

                        &:nth-child(2) {
                            padding: 0;
                            font-size: 12px;
                            text-align: left;
                        }
                    }
                }

                & .mobile {
                    display: none;
                }

                &>.item {
                    margin: 8px 0;
                }
            }
        }
    }

    @include responsive(MD) {
        &>.content {
            &>.column {
                &:nth-child(1) {
                    padding: 8px 8px 8px 0;
                }

                &:nth-child(2) {
                    padding: 8px;
                }

                &:last-child {
                    padding: 8px 0 8px 8px;

                    &>.section {
                        &:nth-child(1) {
                            & .price-info {
                                align-items: flex-end;
                                padding-bottom: 6px;

                                &>span:nth-child(2) {
                                    font-size: 24px;
                                    line-height: 24px;
                                }
                            }
                        }

                        &:nth-child(2) {
                            padding-left: 0;
                            font-size: 16px;
                            letter-spacing: 0.23px;
                        }
                    }
                }
            }
        }
    }

    @include responsive(LG) {
        &>.content {
            &>.column {
                &:nth-child(1) {
                    width: calc(100% / 3.2);
                    padding: 10px 20px 10px 0;
                }

                &:nth-child(2) {
                    width: calc(100% / 3.2);
                    padding: 10px 20px;
                }

                &:last-child {
                    width: calc(100% / 2);
                    padding: 10px 0 10px 20px;

                    &>.section {
                        &:nth-child(1) {
                            &>.button-edit {
                                &>div:nth-child(3) {
                                    display: flex;
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include responsive(XL) {
        &>.content {
            &>.column {
                &:nth-child(1) {
                    width: calc(100% / 3);
                }

                &:nth-child(2) {
                    width: calc(100% / 3);
                }

                &:last-child {
                    width: calc(100% / 3);
                }
            }
        }
    }
}
