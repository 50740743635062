@import "src/assets/scss/common/breakpoints";

.holiday-page-component {
  margin: 0 auto;
  box-sizing: border-box;

  & .hotel-information-section-wrapper {
    background-color: white;
  }

  & .hotel-information-overview {
    & .hotel-information-subtitle {
      margin: 16px 0;
    }
  }

  & .hotel-information-section {
    padding: 0 50px 50px 0;
    margin: 0 auto;
    box-sizing: border-box;

    @include page-container-responsive();

    @include responsive(XXS) {
      padding: 0 0 50px 0;
    }

    @include responsive(LG) {
      padding-left: 29px;
    }

    @include responsive(XXL) {
      padding: 0 0 50px 0;
    }

    & .hotel-information-description {
      @include responsive(LG) {
        display: flex;
      }
    }
  }

  & .hotel-information-title {
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    padding-bottom: 6px;

    @include responsive(XXS) {
      font-size: 24px;
      padding-top: 30px;
    }

    @include responsive(XS) {
      padding-top: 38px;
    }

    @include responsive(MD) {
      font-size: 32px;
    }
    @include responsive(LG) {
      font-size: 48px;
      padding-top: 50px;
    }
  }

  .summary-section {
    width: 100%;
  }
}

.loading-wrapper {
  text-align: center;
  margin-top: 120px;
}

@include responsive(XXS) {
  section.loading {
    height: 120vh;
  }
  .loading-wrapper {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 120px;
  }
  .MuiCircularProgress-svg {
    transform: scale(2);
  }
}

@include responsive(MD) {
  section.loading {
    height: 120vh;
  }
  .loading-wrapper {
    text-align: center;
    margin-top: 120px;
  }
  .MuiCircularProgress-svg {
    transform: scale(3);
  }
}
