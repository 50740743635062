input[type="radio"] {
  opacity: 0;
  margin: 0;
  width: 0;
}

.custom-radiobox-input {
  opacity: 1;
  height: 18px;
  width: 18px;
}

.gender-radios-container {
  padding: 8px 0;

  & .gender-radio-buttons {
    display: flex;
    line-height: 24px;

    & .gender-radio-label {
      display: flex;
      margin: 0 12px 0 0;
      height: 24px;

      & .radio-box-container {
        display: flex;
        justify-content: center;
        margin: auto 8px auto 0;
        align-items: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;

        & .checkbox {
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
      }
    }
  }
}



